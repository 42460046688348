<template>
  <div>
    <hoots-charts-control :cycle-charts-active="cycleChartsActive" @toggle-loop="toggleLoop" />
    <v-card flat color="rgba(0,0,0,0)">
      <charts :cycle-charts-active="cycleChartsActive" />
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import HootsChartsControl from "@/components/dashboard/charts/HootsChartsControl.vue";
import Charts from "@/components/HootsCharts.vue";

export default Vue.extend({
  name: "dashboard-charts",
  components: { HootsChartsControl, Charts },
  data() {
    return {
      cycleChartsActive: true,
    };
  },
  methods: {
    toggleLoop(): void {
      this.cycleChartsActive = !this.cycleChartsActive;
    },
  },
});
</script>
