<template>
  <l-map
    :zoom="zoom"
    :minZoom="mapConfig.minZoom"
    :maxZoom="mapConfig.maxZoom"
    :center="center"
    :style="mapstyle"
    :options="mapOptions"
    @focus="enableScrollWheelZoom"
    @blur="disableScrollWheelZoom"
    @dblclick="updateBounds"
    ref="hootsmap"
  >
    <l-tile-layer
      v-for="tileProvider in tileProviders"
      :key="tileProvider.name"
      :name="tileProvider.name"
      :visible="tileProvider.visible"
      :url="tileProvider.url"
      :attribution="tileProvider.attribution"
      layer-type="base"
    ></l-tile-layer>

    <leaflet-map-route-layer v-if="trip && trip.route" :trip="trip" />
    <leaflet-map-last-location-layer v-if="lastLocation" :last-location="lastLocation" />

    <l-control-scale position="bottomleft" :imperial="false" :metric="true" />
  </l-map>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { mapConfig, tileProviders } from "@/maps/leaflet/leaflet.config";

import type { MapOptions } from "leaflet";
import { LControlScale, LMap, LTileLayer } from "vue2-leaflet";
import { geoJSON, latLng } from "leaflet";
import "leaflet/dist/leaflet.css";

import LeafletMapRouteLayer from "@/components/base/map/LeafletMapRouteLayer.vue";
import LeafletMapLastLocationLayer from "@/components/base/map/LeafletMapLastLocationLayer.vue";
import { LeafletMap } from "@/@types/ui/map/leafletmap.types";
import {
  DashboardCommandID,
  dashboardEventBus,
  DashboardEventID,
} from "@/events/dashboard.channel";

export default Vue.extend({
  name: "leaflet-map",
  components: {
    LMap,
    LTileLayer,
    LControlScale,
    LeafletMapRouteLayer,
    LeafletMapLastLocationLayer,
  },

  data() {
    return {
      center: latLng(51.0522, 13.7282),
      tileProviders: tileProviders,
      mapConfig: mapConfig,
      mapOptions: {
        zoomControl: this.zoomControls,
        doubleClickZoom: false,
        scrollWheelZoom: false,
      } as MapOptions,
    };
  },

  props: {
    zoom: {
      type: Number,
      default: 12,
    },
    zoomControls: {
      type: Boolean,
      default: true,
      required: false,
    },
    mapstyle: {
      type: String,
      default: "height: 100%; width: 100%; background-color: black",
      required: false,
    },

    trip: {
      type: Object as PropType<LeafletMap.Trip> | undefined,
      required: false,
    },
    lastLocation: {
      type: Object as PropType<LeafletMap.LastLocation> | undefined,
      required: false,
    },
  },

  methods: {
    updateBounds() {
      if (this.trip?.route) {
        const bounds = geoJSON(this.trip.route).getBounds();
        (this.$refs.hootsmap as LMap).mapObject.fitBounds(bounds.pad(0.1));
      }
    },

    enableScrollWheelZoom() {
      (this.$refs.hootsmap as LMap).mapObject.scrollWheelZoom.enable();
    },

    disableScrollWheelZoom() {
      (this.$refs.hootsmap as LMap).mapObject.scrollWheelZoom.disable();
    },

    flyToLastLocation() {
      this.$nextTick(() => {
        if (this.lastLocation?.coordinate !== undefined) {
          const map = (this.$refs.hootsmap as LMap).mapObject;
          map.flyTo(this.lastLocation.coordinate, 14);
          // @ts-expect-error component methods are not typed yet
          this.$refs.lastMarkerRef?.openPopup();
        }
      });
    },

    handleLayoutChange() {
      this.$nextTick(() => {
        (this.$refs.hootsmap as LMap).mapObject.invalidateSize();
      });
    },


    addLeafletEventHandlers() {
      this.$nextTick(() => {
        const mapObject = (this.$refs.hootsmap as LMap).mapObject;
        mapObject.on("moveend", () => {
          const mapCenter = mapObject.getCenter();
          dashboardEventBus.dispatch(DashboardEventID.MAP_CENTER_MOVED, {
            to: { lat: mapCenter.lat, lng: mapCenter.lng },
          });
        });
      });
    },

    subscribeToEvents() {
      dashboardEventBus.subscribe(
        DashboardCommandID.CENTER_MAP_TO_LAST_LOCATION,
        this.flyToLastLocation
      );
      dashboardEventBus.subscribe(DashboardEventID.LAYOUT_CHANGED, this.handleLayoutChange);
    },

    unsubscribeFromEvents() {
      dashboardEventBus.unsubscribe(
        DashboardCommandID.CENTER_MAP_TO_LAST_LOCATION,
        this.flyToLastLocation
      );
      dashboardEventBus.unsubscribe(DashboardEventID.LAYOUT_CHANGED, this.handleLayoutChange);
    },
  },

  mounted() {
    this.subscribeToEvents();
    this.addLeafletEventHandlers();
    this.updateBounds();
  },

  beforeDestroy() {
    this.unsubscribeFromEvents();
  },

  watch: {
    trip: {
      handler: function () {
        this.updateBounds();
      },
    },
  },
});
</script>

<style>
/* Workaround for lines between tiles:
https://github.com/Leaflet/Leaflet/issues/3575 */
.leaflet-container {
  background: black;
}
</style>
