<template>
  <v-toolbar dense flat>
    <v-spacer></v-spacer>
    <v-switch
      prepend-icon="mdi-chart-line"
      v-model="loopActive"
      color="primary"
      hide-details
      :label="$t('ui.dashboard.loopMode')"
    ></v-switch>
  </v-toolbar>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "hoots-charts-control",
  props: {
    cycleChartsActive: {
      type: Boolean,
    },
  },
  computed: {
    loopActive: {
      get(): boolean {
        return this.cycleChartsActive;
      },
      set(): void {
        this.$emit("toggle-loop");
      },
    },
  },
});
</script>
