<template>
  <v-dialog
    :value="showDialog"
    fullscreen
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <!-- persistent max-width="700px"> -->

    <v-toolbar dark color="primary darken-2">
      <v-toolbar-title
        >{{ $t("ui.picker.vehicle.availableVehicles") }}:
        {{ this.listOfVehicleIDsWithSessions.length }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn icon dark @click="close" :disabled="noSelection">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card color="rgba(0, 0, 0, 0.85)">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="vehicle in allVehicles"
            :key="vehicle.id"
            cols="12"
            xs="12"
            sm="6"
            md="6"
            lg="4"
          >
            <v-card color="grey darken-3">
              <v-img
                :src="vehicle.image"
                aspect-ratio="2.0"
                gradient="to top, rgba(0,0,0,0.5), rgba(0,0,0,1.0)"
                class="white--text align-start"
              >
                <v-card-title>{{ vehicle.make }} {{ vehicle.model }}</v-card-title>
                <v-card-subtitle white--text v-if="vehicle.name !== ''"
                  >"{{ vehicle.name }}"</v-card-subtitle
                >
              </v-img>
              <v-fade-transition>
                <v-overlay absolute :color="selectColor(vehicle)">
                  <v-btn
                    color="primary darken-4"
                    @click="select(vehicle)"
                    v-if="hasSessions(vehicle.id) && !isSelected(vehicle)"
                    >{{ $t("ui.action.select") }}</v-btn
                  >
                  <h2 v-if="isSelected(vehicle)">
                    {{ $t("ui.picker.vehicle.currentSelection") }}
                  </h2>
                  <h2 v-if="!hasSessions(vehicle.id)">
                    {{ $t("ui.picker.vehicle.notSelectable") }}
                  </h2>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
import { Vehicle } from "@/@types/data/vehicle.types";

export default Vue.extend({
  name: "VehiclePicker",
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    activeVehicleID: {
      type: Number as PropType<Vehicle["id"] | null>,
      default: null,
    },
  },

  computed: {
    ...mapGetters("device", ["getAllVehicleIDsWithSessions"]),
    ...mapGetters("vehicle", ["getAllVehicles"]),

    noSelection(): boolean {
      return this.activeVehicleID === null && this.getAllVehicleIDsWithSessions.length > 0;
    },

    listOfVehicleIDsWithSessions(): Vehicle["id"][] {
      return this.getAllVehicleIDsWithSessions;
    },

    allVehicles(): Vehicle[] {
      return this.getAllVehicles;
    },
  },

  created() {
    if (this.listOfVehicleIDsWithSessions.length === 0) {
      this.$emit("close");
    }
  },

  methods: {
    hasSessions(vehicleID: Vehicle["id"]) {
      return this.listOfVehicleIDsWithSessions.indexOf(vehicleID) !== -1;
    },

    selectColor(vehicle: Vehicle) {
      if (!this.isSelected(vehicle)) {
        if (this.hasSessions(vehicle.id)) {
          // ready for selection
          return "rgba(255, 255, 255, 0.25)";
        } else {
          // cannot be selected -> no hoots|one attached
          return "rgba(0, 0, 0, 0.9)";
        }
      } else {
        // already selected
        return "primary darken-3";
      }
    },

    isSelected(vehicle: Vehicle) {
      return vehicle.id === this.activeVehicleID;
    },

    async select(vehicle: Vehicle) {
      this.$emit("select-vehicle", vehicle.id);
    },

    close() {
      this.$emit("close");
    },
  },
});
</script>
