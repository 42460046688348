import { FetchProvider } from "@/service/fetch/fetch.interface";
import { Response } from "@/@types/helper/api.types";
import { Device, DeviceLastKnownLocation } from "@/@types/data/device.types";

const assignImageToDevice = (device: Device) => (device.image = "/hoots-one-black-front.png");

export class DevicesProvider {
  fetch: FetchProvider;
  baseURL: string;

  constructor(fetch: FetchProvider, baseURL: string) {
    this.fetch = fetch;
    this.baseURL = baseURL;
  }

  async fetchAllDevices(): Promise<Response<Device[]>> {
    const fetchDevicesRequest = await this.fetch.get<{ devices: Device[] }>(
      this.baseURL + "/device"
    );
    if (fetchDevicesRequest.wasSuccessful()) {
      const devices = fetchDevicesRequest.getData()?.devices || [];
      devices.forEach(assignImageToDevice);
      return new Response(true, fetchDevicesRequest.getMessage(), devices);
    } else {
      return new Response(false, fetchDevicesRequest.getMessage());
    }
  }

  async updateDevice(device: Device): Promise<Response> {
    return await this.fetch.put<Device, undefined>(this.baseURL + "/device", device);
  }

  async registerDevice(device: { serial: string; password: string }): Promise<Response> {
    return await this.fetch.post(this.baseURL + "/device/register", device);
  }

  async unregisterDevice(device: { serial: string }): Promise<Response> {
    return await this.fetch.put<{ serial: string }, undefined>(
      this.baseURL + "/device/unregister",
      device
    );
  }

  async fetchDeviceLastKnownLocation(
    deviceID: Device["id"]
  ): Promise<Response<DeviceLastKnownLocation>> {
    return await this.fetch.get<DeviceLastKnownLocation>(this.baseURL + `/device/${deviceID}/last`);
  }
}
