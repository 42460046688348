import axios from "axios";

const state = {
  loading: false,
  sensor: {},

  apiUrl: process.env.VUE_APP_SENSOR_API_URL,
};

const getters = {};

const actions = {
  async get({ state, commit }, { sensor_id }) {
    try {
      commit("LOADING_STATE", true);

      const response = await axios.get(`${state.apiUrl}/sensor/${sensor_id}`);
      const sensor = response.data;

      commit("SENSOR", sensor);
      commit("LOADING_STATE", false);
    } catch (error) {
      commit("SENSOR", {});
      commit("LOADING_STATE", false);
    }
  },

  async update({ state }, { sensor_id, update }) {
    try {
      await axios.put(`${state.apiUrl}/sensor/${sensor_id}`, update);
    } catch (error) {
      console.error(error);
    }
  },

  clearData({ commit }) {
    commit("CLEAR_DATA");
  },
};

const mutations = {
  CLEAR_DATA(state) {
    state.sensor = {};
  },
  SENSOR(state, payload) {
    state.sensor = payload;
  },
  LOADING_STATE(state, loading) {
    state.loading = loading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
