<template>
  <l-layer-group layer-type="overlay">
    <marker-popup
      v-if="lastLocationMarkerPosition !== null"
      :position="lastLocationMarkerPosition"
      :text="lastLocationPopupHTML"
      :icon="lastLiveIcon"
      ref="lastMarkerRef"
    />
  </l-layer-group>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

import type { LeafletMap } from "@/@types/ui/map/leafletmap.types";
import type { ReverseGeo } from "@/@types/data/geo.types";
import type { Position } from "geojson";
import type { UnixTimeStamp } from "@/@types/generic/datetime.types";

import { LLayerGroup } from "vue2-leaflet";
import MarkerPopup from "@/components/base/map/LeafletMapMarkerWithPopup.vue";

import i18n from "@/plugins/i18n.plugin";
import format from "date-fns/format";
import fromUnixTime from "date-fns/fromUnixTime";
import { generateLastPositionCustomHTML } from "@/helpers/ui/maps/generateMarkerCustomHTML";
import parseISO from "date-fns/parseISO";
import { latLng, LatLng } from "leaflet";
import { mapIcons } from "@/maps/leaflet/leaflet.config";

export default Vue.extend({
  name: "leaflet-map-last-location-layer",
  components: { LLayerGroup, MarkerPopup },
  props: {
    lastLocation: {
      type: Object as PropType<LeafletMap.LastLocation> | undefined,
      required: false,
    },
  },
  data() {
    return {
      lastLiveIcon: mapIcons.lastLiveIcon,
    };
  },
  computed: {
    lastLocationMarkerPosition(): LatLng | null {
      const coords = this.lastLocation?.coordinate;
      return coords !== undefined ? latLng(coords) : null;
    },

    lastLocationPopupHTML(): string {
      const title = this.$t("ui.map.lastLocation") as string;

      const addressString =
        this.lastLocation?.address !== undefined
          ? this.generateAddressStringFrom(this.lastLocation?.address)
          : "Unknown Address";

      const vehicleString = this.lastLocation?.vehicle
        ? `${this.lastLocation?.vehicle.make} ${this.lastLocation?.vehicle.model}`
        : "Unknown Vehicle";

      const deviceIDString = this.lastLocation?.vehicle
        ? String(this.lastLocation.vehicle.device_id)
        : "Unknown Device ID";

      const timeString = this.lastLocation?.time
        ? format(
            parseISO(this.lastLocation.time),
            i18n.locale === "de" ? "dd.MM.yyyy HH:mm:ss" : "MM/dd/yyyy HH:mm:ss"
          )
        : "Unknown Time";

      return generateLastPositionCustomHTML(
        title,
        addressString,
        vehicleString,
        deviceIDString,
        timeString
      );
    },
  },
  methods: {
    generateAddressStringFrom(reverseGeoItem: ReverseGeo.Item): string {
      const street = reverseGeoItem?.address.street;
      const city = reverseGeoItem?.address.city;
      return `${street}, ${city}`;
    },

    generateCoordinateStringFrom(position: Position): string {
      return `${position[1]}, ${position[0]}`;
    },

    generateTimeStringFrom(unixTimeStamp: UnixTimeStamp): string {
      return format(
        fromUnixTime(unixTimeStamp),
        i18n.locale == "de" ? "dd.MM.yyyy HH:mm:ss" : "MM/dd/yyyy HH:mm:ss"
      );
    },
  },
});
</script>
