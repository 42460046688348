import axios from "axios";

const state = {
  loading: false,
  alerts: [],
  notifications: [],

  apiUrl: process.env.VUE_APP_MESSAGECENTER_API_URL,
};

const getters = {
  alertsUnread: (state) => {
    return parseInt(state.alerts.filter((a) => a.read === false).length);
  },
  notificationsRead: (state) => {
    return parseInt(state.notifications.filter((a) => a.read === true).length);
  },
  notificationsUnread: (state) => {
    return parseInt(state.notifications.filter((a) => a.read === false).length);
  },
  unread: (state) => {
    return (
      parseInt(state.alerts.filter((a) => a.read === false).length) +
      parseInt(state.notifications.filter((a) => a.read === false).length)
    );
  },
  readAlerts: (state) => state.alerts.filter((a) => a.read === true),
  unreadAlerts: (state) => state.alerts.filter((a) => a.read === false),
  readNotifications: (state) => state.notifications.filter((a) => a.read === true),
  unreadNotifications: (state) => state.notifications.filter((a) => a.read === false),
};

const actions = {
  async get({ state, commit }) {
    try {
      commit("LOADING_STATE", true);
      const response = await axios.get(`${state.apiUrl}/messages`);
      commit("ALERTS", response.data.alerts);
      commit("NOTIFICATIONS", response.data.notifications);
      commit("LOADING_STATE", false);
    } catch (error) {
      commit("LOADING_STATE", false);
      commit("ALERTS", []);
      commit("NOTIFICATIONS", []);
    }
  },

  async alert_confirm({ state, commit }, alert_id) {
    try {
      commit("LOADING_STATE", true);
      await axios.put(`${state.apiUrl}/messages/alert/confirm/${alert_id}`);
      commit("LOADING_STATE", false);
    } catch (error) {
    } finally {
      commit("LOADING_STATE", false);
    }
  },

  async notification_confirm({ state, commit }, notification_id) {
    try {
      commit("LOADING_STATE", true);
      await axios.put(`${state.apiUrl}/messages/notification/confirm/${notification_id}`);
      commit("LOADING_STATE", false);
    } catch (error) {
      //
    } finally {
      commit("LOADING_STATE", false);
    }
  },

  clearData({ commit }) {
    commit("CLEAR_DATA");
  },
};

const mutations = {
  CLEAR_DATA(state) {
    state.alerts = [];
    state.notifications = [];
  },
  LOADING_STATE(state, loading) {
    state.loading = loading;
  },
  ALERTS(state, payload) {
    state.alerts = payload;
  },
  NOTIFICATIONS(state, payload) {
    state.notifications = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
