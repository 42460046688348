import Vue from "vue";
import { AuthProvider } from "@/service/auth/auth.interface";

export function getVueAuthPlugin(
  authProviderInstance: AuthProvider
): Vue.PluginObject<AuthProvider> {
  return {
    install() {
      Object.defineProperty(Vue.prototype, "$auth", {
        get() {
          return authProviderInstance;
        },
      });
    },
  };
}
