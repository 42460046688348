<template>
  <v-col cols="12" xs="12" sm="12" md="12" lg="12">
    <v-avatar class="mx-auto my-auto d-block" size="66">
      <img src="/hoots-logo.svg" :alt="$t('ui.user.avatar.alt')" />
    </v-avatar>
  </v-col>
</template>
<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "user-profile-header",
});
</script>
