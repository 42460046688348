eslint-disable

<template>
  <v-row justify="center">
    <v-dialog :value="showDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-toolbar dark color="primary darken-2">
        <v-btn icon dark @click="hasPickedSession = false" :disabled="!hasPickedSession">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ sessionSelectionTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card color="rgba(0, 0, 0, 0.85)" v-if="!hasPickedSession">
        <v-card-title>
          <v-date-picker
            id="sessionDatePicker"
            v-model="selectedDate"
            :locale="locale"
            :first-day-of-week="firstDayOfWeek"
            :allowed-dates="allowedDates"
            :show-week="true"
            :min="minSelectableDate"
            :max="maxSelectableDate"
            color="gray"
            :landscape="true"
            no-title
            @input="selectDate(selectedDate)"
          ></v-date-picker>
        </v-card-title>
      </v-card>
      <v-card color="rgba(0, 0, 0, 0.85)" v-if="hasPickedSession">
        <v-data-table
          :headers="headers"
          :items="sessionsOnSelectedDate"
          hide-default-footer
          item-key="sid"
          @click:row="selectSession"
          style="cursor: pointer"
        >
          <template v-slot:item.started_at_date="{ item }">
            <span>{{ item.started_at_date | date("HH:mm") }}</span>
          </template>
          <template v-slot:item.finished_at_date="{ item }">
            <span>{{ item.finished_at_date | date("HH:mm") }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";

type Header = {
  text: string;
  value: "started_at_date" | "finished_at_date" | "duration" | "distance";
  filterable: boolean;
  sortable: boolean;
};

type Year = number;
type Month = number;
type Day = number;
type CalendarSessionDate = `${Year}-${Month}-${Day}`;

type SessionSelectRowItem = Session & {
  duration: string;
  started_at_date: Date;
  finished_at_date: Date;
};

// date formatting
import format from "date-fns/format";
import fromUnixTime from "date-fns/fromUnixTime";
import formatDistance from "date-fns/formatDistance";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";

import i18n from "@/plugins/i18n.plugin";
import { Session } from "@/@types/data/session.types";
import { UnixTimeStamp } from "@/@types/generic/datetime.types";

export default Vue.extend({
  name: "SessionPicker",
  props: ["showDialog"],
  data: () => ({
    hasPickedSession: false,

    selectedDate: null as CalendarSessionDate | null,
    minSelectableDate: "2019-01-01" as CalendarSessionDate,
    maxSelectableDate: undefined as CalendarSessionDate | undefined,
  }),
  created() {
    this.computeDateRangeAndSelectLastSession();
    this.setLastSelectableDate();
  },
  computed: {
    ...mapGetters("device", ["calendarSessionEvents", "getAllSessionsByActiveVehicle"]),
    locale() {
      return i18n.locale;
    },

    firstDayOfWeek(): "1" | "7" {
      return i18n.locale === "de" ? "1" : "7";
    },

    headers(): Header[] {
      return [
        {
          text: this.$t("ui.picker.session.start") as string,
          value: "started_at_date",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("ui.picker.session.end") as string,
          value: "finished_at_date",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("ui.picker.session.duration") as string,
          value: "duration",
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t("ui.picker.session.distanceKm") as string,
          value: "distance",
          filterable: false,
          sortable: false,
        },
      ];
    },

    sessionSelectionTitle(): string {
      return this.selectedDate == null
        ? (this.$t("ui.picker.session.tripSelection") as string)
        : this.$t("ui.picker.session.tripsFrom") +
            " " +
            format(new Date(this.selectedDate), i18n.locale === "de" ? "dd.MM.yyyy" : "MM/dd/yyyy");
    },

    allowedDates(): (calendarSessionDate: CalendarSessionDate) => boolean {
      return (calendarSessionDate: CalendarSessionDate) => {
        return this.calendarSessionEvents.indexOf(calendarSessionDate) !== -1;
      };
    },

    sessionsOnSelectedDate(): SessionSelectRowItem[] {
      if (this.selectedDate === null) return [];
      else {
        const vehicleSessions = this.getAllSessionsByActiveVehicle as Session[];
        const filteredByDate = vehicleSessions.filter(
          (session) => format(fromUnixTime(session.started_at), "yyyy-MM-dd") === this.selectedDate
        );
        return filteredByDate.map((session) => this.addRowFields(session));
      }
    },

    daysWithSessions(): CalendarSessionDate[] {
      const vehicleSessions = this.getAllSessionsByActiveVehicle as Session[];
      return vehicleSessions.map((session) => this.timestampToCalendarDate(session.started_at));
    },
  },
  methods: {
    close() {
      this.hasPickedSession = false;
      this.selectedDate = null;
      this.$emit("close");
    },

    selectDate(sessionDate: CalendarSessionDate) {
      this.selectedDate = sessionDate;
      this.hasPickedSession = true;
    },

    computeDuration(startedAt: UnixTimeStamp, finishedAt: UnixTimeStamp): string {
      return formatDistance(0, (finishedAt - startedAt) * 1000, {
        includeSeconds: true,
        addSuffix: false,
        locale: i18n.locale === "de" ? deLocale : enLocale,
      });
    },

    addRowFields(session: Session): SessionSelectRowItem {
      return {
        ...session,
        started_at_date: fromUnixTime(session.started_at),
        finished_at_date: fromUnixTime(session.finished_at),
        duration: this.computeDuration(session.started_at, session.finished_at),
      };
    },

    async selectSession(session: SessionSelectRowItem) {
      this.$emit("select-session", session.id);
    },

    timestampToCalendarDate(timestamp: UnixTimeStamp): CalendarSessionDate {
      return format(fromUnixTime(timestamp), "yyyy-MM-dd") as CalendarSessionDate;
    },

    computeDateRangeAndSelectLastSession(): void {
      const vehicleSessions = this.getAllSessionsByActiveVehicle as Session[];
      if (vehicleSessions.length > 0) {
        const firstSession = vehicleSessions[0];
        const lastSession = vehicleSessions[vehicleSessions.length - 1];
        this.minSelectableDate = this.timestampToCalendarDate(firstSession.started_at);
        this.maxSelectableDate = this.timestampToCalendarDate(lastSession.started_at);
        this.selectedDate = this.timestampToCalendarDate(lastSession.started_at);
      }
    },
    setLastSelectableDate(): void {
      this.maxSelectableDate = this.daysWithSessions[this.daysWithSessions.length - 1];
    },
  },
});
</script>

<style scoped>
#sessionDatePicker {
  height: 330px;
}
</style>
