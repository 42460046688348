"use strict";

import Vue from "vue";
import App from "./App.vue";

import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n.plugin";
import FlagIcon from "vue-flag-icon";

import "@/plugins";

import store from "./store";
import "./style/index.scss";

Vue.use(FlagIcon);

import { LocalStorageProvider } from "@/service/storage/localstorage.provider";
import { AxiosProvider } from "@/service/fetch/axios.provider";

import { HootsAuthProvider } from "@/service/auth/hootsauth/hootsauth.provider";
import { HootsUserProvider } from "@/service/user/hootsuser/hootsuser.provider";

import { getVueAuthPlugin } from "@/plugins/auth.plugin";
import { getVueUserPlugin } from "@/plugins/user.plugin";

import { initialiseRouterWithAuth } from "@/router";
import { AuthProvider } from "@/service/auth/auth.interface";

const localStorageProviderInstance = new LocalStorageProvider();
const fetchProviderInstance = new AxiosProvider();

const authconfig = {
  endpoint: process.env.VUE_APP_AUTH_API_URL || "",
  hooks: { onLogin: [], onLogout: [] },
};
const userconfig = { endpoint: process.env.VUE_APP_USERS_API_URL || "" };

const authProviderInstance = new HootsAuthProvider(
  authconfig,
  localStorageProviderInstance,
  fetchProviderInstance
);

const userProviderInstance = new HootsUserProvider(
  userconfig,
  authProviderInstance,
  fetchProviderInstance
);

const authPlugin = getVueAuthPlugin(authProviderInstance);
const userPlugin = getVueUserPlugin(userProviderInstance);

Vue.use(authPlugin);
Vue.use(userPlugin);

const router = initialiseRouterWithAuth(authProviderInstance);

authProviderInstance.initialize().then();

Vue.config.productionTip = false;

import * as Sentry from "@sentry/vue";

if (process.env.NODE_ENV == "production") {
  Sentry.init({
    Vue,
    dsn: "https://b2551155c8074b668412d90eb8d3f62a@o1089532.ingest.sentry.io/6104726",
    integrations: [],
    logErrors: true,
    release: process.env.SENTRY_RELEASE,
    tracesSampleRate: 1.0,
  });
}

new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// Workaround, till I can find out wy custom properties are not typed in
// components extending vue
declare module "vue/types/vue" {
  interface Vue {
    $auth: AuthProvider;
    $user: HootsUserProvider;
    $t: typeof i18n.t;
  }
}
