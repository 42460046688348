import Vue from "vue";
import Router from "vue-router";

import { routes } from "@/router/routes";
import { AuthProvider } from "@/service/auth/auth.interface";

Vue.use(Router);

async function navigateToLoginIfOnProtectedRoute(router: Router, authProvider: AuthProvider) {
  if (router.currentRoute.meta?.requiresAuth && !authProvider.isLoggedIn()) {
    await router.push("/login");
  }
}

export function initialiseRouterWithAuth(authProvider: AuthProvider): Router {
  const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });

  authProvider.registerOnLogoutHook(() => navigateToLoginIfOnProtectedRoute(router, authProvider));

  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (authProvider.isLoggedIn()) {
        next();
        return;
      }
      next("/login");
    } else {
      next();
    }
  });

  return router;
}
