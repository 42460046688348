<template>
  <v-overlay :value="show">
    <v-row justify="center" align="center">{{ title }}</v-row>
    <v-row justify="center" align="center">
      <v-progress-circular
        :rotate="270"
        :value="secondsLeft100"
        color="white"
        size="64"
      >
        <v-icon>mdi-exit-to-app</v-icon>
      </v-progress-circular>
    </v-row>
  </v-overlay>
</template>

<script>
export default {
  name: "AutoLogout",
  props: {
    // in minutes
    timeout: {
      type: Number,
      default: 60,
    },
  },

  data: () => ({
    show: false,
    // in seconds
    showTimeout: 30,
    secondsUntil: 0,
  }),

  computed: {
    secondsLeft100() {
      return ((this.secondsUntil / this.showTimeout) * 100).toFixed(0);
    },

    title() {
      return (
        this.secondsUntil +
        " " +
        (this.secondsUntil == 1
          ? this.$t("ui.autologout.second")
          : this.$t("ui.autologout.seconds")) +
        " " +
        this.$t("ui.autologout.untilLogout")
      );
    },

    token() {
      return this.$auth.getSessionToken();
    },
  },

  mounted() {
    this.start();
  },

  methods: {
    initListener() {
      document.body.addEventListener("click", () => this.reset());
    },

    start() {
      this.reset();
      this.initListener();
      this.initInterval();
    },

    stop() {
      clearInterval(this.timer);
      this.reset();
    },

    reset() {
      this.autologout = Date.now();
      this.show = false;
    },

    initInterval() {
      this.timer = setInterval(() => {
        this.check();
      }, 1000);
    },

    check() {
      const now = Date.now();
      const timeleft = this.autologout + this.timeout * 60 * 1000;
      const diff = timeleft - now;
      this.secondsUntil = (diff / 1000).toFixed(0);
      const isTimeout = diff < 0;

      if (diff < this.showTimeout * 1000) {
        this.show = true;
      }

      if (isTimeout && this.token) {
        this.stop();
        this.show = false;
        this.logoutUser();
      }
    },

    async logoutUser() {
      await this.$auth.logout();
      // TODO: Is vehicle unselect really required
      await this.$store.commit("vehicle/SELECT", {});
      await this.$router.push("/login");
    },
  },
};
</script>
