<template>
  <v-hover v-slot:default="{ hover }">
    <v-card :elevation="6" :data-v-step="step" @click="onClick">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-sheet class="white--text align-center" v-on="on">
            <v-card-title>
              <v-icon large>{{ icon }}</v-icon>
              <v-spacer></v-spacer>
              <div class="display-1 white--text">{{ value }}</div>
              <div class="body-2 font-weight-regular grey--text">
                {{ unit }}
              </div>
            </v-card-title>
            <v-card-subtitle>
              <div class="subtitle-2 font-weight-bold white--text">
                {{ title }}
              </div>
            </v-card-subtitle>
          </v-sheet>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
      <v-progress-linear
        :active="true"
        :indeterminate="ready"
        :value="hover ? 100 : 0"
        color="blue"
        bottom
      ></v-progress-linear>
    </v-card>
  </v-hover>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  props: {
    step: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "mdi-car",
    },
    value: {
      type: String,
      default: "",
    },
    ready: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    progress: {
      type: Number,
      default: 0,
    },
    tooltip: {
      type: String,
      default: "",
    },
  },
  methods: {
    onClick() {
      this.$emit("triggered");
    },
  },
});
</script>
