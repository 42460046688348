import axios from "axios";

import { EntityProvider } from "@/provider/data/entity.provider";
import { AxiosProvider } from "@/service/fetch/axios.provider";

const vehicleApiUrl = process.env.VUE_APP_VEHICLE_API_URL;

const fetchProvider = new AxiosProvider();
const VehicleProvider = new EntityProvider(
  {
    entitySlug: "vehicle",
    multiEntitySlug: "vehicles",
    baseUrl: vehicleApiUrl,
  },
  fetchProvider
);

function removeBatterySensor(vehicle) {
  if (vehicle.sensor_map) {
    vehicle.sensor_map = vehicle.sensor_map.filter((sensor) => sensor.id > vehicle.id);
  }
  return vehicle;
}

function addBatterySensor(vehicle) {
  vehicle.sensor_map.unshift({
    id: vehicle.id,
    sensor_type_key: null,
    counter: 1,
  });
  return vehicle;
}

function addDefaultImage(vehicle) {
  vehicle.image = vehicle.image || "/vehicle-template.png";
  return vehicle;
}

function removeDeviceIdIfZero(vehicle) {
  if (vehicle.device_id === "000-00000") {
    vehicle.device_id = null;
  }
  return vehicle;
}

const state = {
  loading: false,
  vehicles: [],
  shares: [],
};

const getters = {
  getVehicleByID: (state) => (vehicleID) =>
    state.vehicles.find((vehicle) => vehicle.id === vehicleID) || null,
  getAllVehicles: (state) => state.vehicles,
};

const actions = {
  async fetchAll({ commit }) {
    try {
      commit("LOADING_STATE", true);
      const getVehiclesRequest = await VehicleProvider.getAll();

      if (getVehiclesRequest.wasSuccessful()) {
        const { vehicles } = getVehiclesRequest.getData();
        vehicles.forEach(addBatterySensor);
        vehicles.forEach(addDefaultImage);
        vehicles.forEach(removeDeviceIdIfZero);
        commit("VEHICLES", vehicles);
      }
    } finally {
      commit("LOADING_STATE", false);
    }
  },

  async create({ dispatch }, vehicle) {
    const preparedVehicle = removeBatterySensor({ ...vehicle });
    const createRequest = await VehicleProvider.create(preparedVehicle);
    dispatch("fetchAll");
    return createRequest;
  },

  async update({ dispatch }, vehicle) {
    const preparedVehicle = removeBatterySensor({ ...vehicle });
    const updateRequest = await VehicleProvider.update(preparedVehicle);
    await dispatch("fetchAll");
    return updateRequest;
  },

  async delete({ dispatch }, vehicleID) {
    const deleteRequest = await VehicleProvider.delete(vehicleID);
    await dispatch("fetchAll");
    return deleteRequest;
  },

  async shares({ commit }, vehicle_id) {
    try {
      const response = await axios.get(`${vehicleApiUrl}/vehicle/${vehicle_id}/shares`);
      commit("SHARES", response.data.shares);
    } catch (error) {
      commit("SHARES", {});
    }
  },

  async createShare({ commit }, payload) {
    const share = payload.share;
    const vehicleItemIndex = payload.vehicleItemIndex;
    try {
      const response = await axios.post(`${vehicleApiUrl}/vehicle/share`, share);
      if (response.status === 200) {
        commit("CREATE_SHARE", {
          share: response.data.share_me,
          vehicleItemIndex,
        });
      }
    } catch (error) {}
  },

  async deleteShare({ commit }, payload) {
    const share = payload.share;
    const vehicleItemIndex = payload.vehicleItemIndex;
    try {
      const response = await axios.delete(`${vehicleApiUrl}/vehicle/share/${share.id}`);
      if (response.status === 200) {
        commit("DELETE_SHARE", {
          share,
          vehicleItemIndex,
        });
      }
    } catch (error) {}
  },

  clearData({ commit }) {
    commit("CLEAR_DATA");
  },
};

const mutations = {
  VEHICLES(state, vehicles) {
    state.vehicles = vehicles;
  },
  LOADING_STATE(state, loading) {
    state.loading = loading;
  },
  SELECT(state, vehicle) {
    state.vehicle = vehicle;
  },
  SHARES(state, shares) {
    state.shares = shares;
  },
  CREATE_SHARE(state, payload) {
    const share = payload.share;
    const vehicleItemIndex = payload.vehicleItemIndex;
    state.shares.push(share);
    state.vehicles[vehicleItemIndex].share_me_count =
      state.vehicles[vehicleItemIndex].share_me_count + 1;
  },
  DELETE_SHARE(state, payload) {
    const share = payload.share;
    const vehicleItemIndex = payload.vehicleItemIndex;
    state.shares.splice(state.shares.indexOf(share), 1);
    state.vehicles[vehicleItemIndex].share_me_count =
      state.vehicles[vehicleItemIndex].share_me_count - 1;
  },
  CLEAR_DATA(state) {
    state.vehicles = [];
    state.shares = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
