import type { HootsChart } from "@/charts/processor.plotly";
import getUnixTime from "date-fns/getUnixTime";
import type { Datum, PlotRelayoutEvent, Layout } from "plotly.js";
import type { LayoutEvent } from "@/events/chart.channel";

export type YRange = [number, number];
export type DefinedChartBounds = [number, number];
export type UndefinedChartBounds = [undefined, undefined];
export type ChartBounds = DefinedChartBounds | UndefinedChartBounds;

export type YAxis = `yaxis${"" | 2 | 3 | 4}`;
export type YAxisRanges = {
  [axis in YAxis]: YRange;
};

const elementLargerThan = (val: number) => (element: Datum | Datum[] | undefined) =>
  !!element && element > val;

function getUnixTimestampsFromRelayoutEvent(event: PlotRelayoutEvent): ChartBounds {
  if (event["xaxis.range[0]"] && event["xaxis.range[1]"]) {
    const startTimeStamp = getUnixTime(new Date(event["xaxis.range[0]"]));
    const endTimeStamp = getUnixTime(new Date(event["xaxis.range[1]"]));
    return [startTimeStamp, endTimeStamp];
  } else {
    return [undefined, undefined];
  }
}

function checkIfBoundsChanged(bounds1: ChartBounds, bounds2: ChartBounds): boolean {
  const xMinChanged = bounds1[0] !== bounds2[0];
  const xMaxChanged = bounds1[1] !== bounds2[1];
  return xMinChanged || xMaxChanged;
}

function convertPlotDataAxisToRangeAxis(plotAxis: string) {
  const plotAxisNumber = plotAxis[1] || "";
  return `yaxis${plotAxisNumber}` as YAxis;
}

function getUniqueYAxis(chart: HootsChart): YAxis[] {
  const yAxisSet = chart.data.reduce((prev, subPlot) => {
    subPlot.yaxis && prev.add(convertPlotDataAxisToRangeAxis(subPlot.yaxis));
    return prev;
  }, new Set<YAxis>());
  return Array.from(yAxisSet);
}

function getInitialYAxisRangeObject(chart: HootsChart): YAxisRanges {
  return getUniqueYAxis(chart).reduce((prev, yAxis) => {
    prev[yAxis] = [+Infinity, -Infinity];
    return prev;
  }, {} as YAxisRanges);
}

function mergeMinMaxWithExistingRange(ranges: YAxisRanges, range: YRange, axis: YAxis) {
  ranges[axis] = [
    range[0] < ranges[axis][0] ? range[0] : ranges[axis][0],
    range[1] > ranges[axis][1] ? range[1] : ranges[axis][1],
  ];
}

function computeChartYRanges(chart: HootsChart, bounds: DefinedChartBounds): YAxisRanges {
  const yAxisMinMax: YAxisRanges = getInitialYAxisRangeObject(chart);

  for (let subPlot = 0; subPlot < chart.data.length; subPlot++) {
    const yaxis = convertPlotDataAxisToRangeAxis(chart.data[subPlot].yaxis!);

    if (chart.data[subPlot].x == undefined) continue;

    const beginIndex = chart.data[subPlot].x?.findIndex(elementLargerThan(bounds[0] * 1000));
    const endIndex =
      chart.data[subPlot].x!.findIndex(elementLargerThan(bounds[1] * 1000)) ||
      chart.data[subPlot].x!.length - 1;

    const yData = [...chart.data[subPlot].y!.slice(beginIndex, endIndex)] as number[];
    const yMax = Math.max(...yData);
    const yMin = Math.min(...yData);

    mergeMinMaxWithExistingRange(yAxisMinMax, [yMin, yMax], yaxis);
  }

  return yAxisMinMax;
}

function getYAxisAutoRangeLayout(chart: HootsChart) {
  const [startingX, startingY] = chart.data.reduce(
    (prev, plotData) => {
      if (!plotData.x) return prev;
      prev[0] = Math.min(prev[0], Number(plotData.x[0]));
      prev[1] = Math.max(prev[1], Number(plotData.x[plotData.x.length - 1]));
      return prev;
    },
    [+Infinity, -Infinity]
  );

  return getUniqueYAxis(chart).reduce((layout, yAxis) => {
    layout[`xaxis.range[0]`] = startingX;
    layout[`xaxis.range[1]`] = startingY;
    layout[`${yAxis}.autorange`] = true;
    return layout;
  }, {} as Record<string, boolean | Datum | Datum[]>) as Partial<Layout>;
}

function formatRangesAndBoundsToLayout(ranges: YAxisRanges, bounds: DefinedChartBounds) {
  const layoutYRanges = Object.entries(ranges).reduce((prev, yAxisRange) => {
    prev[`${yAxisRange[0]}.range[0]`] = yAxisRange[1][0];
    prev[`${yAxisRange[0]}.range[1]`] = yAxisRange[1][1];
    return prev;
  }, {} as Record<string, number>) as Partial<Layout>;
  return {
    ...layoutYRanges,
    "xaxis.range[0]": bounds[0] * 1000,
    "xaxis.range[1]": bounds[1] * 1000,
  };
}

function padChartYRanges(ranges: YAxisRanges): YAxisRanges {
  const paddedYRanges = {} as YAxisRanges;

  for (const range of Object.entries(ranges)) {
    const axis = range[0] as YAxis;
    const yDiff = Math.abs(range[1][1] - range[1][0]);
    const pad = yDiff * 0.1;

    paddedYRanges[axis] = [range[1][0] - pad, range[1][1] + pad];
  }

  return paddedYRanges;
}

function getNewLayoutFor(chart: HootsChart, bounds: LayoutEvent["bounds"]): Partial<Layout> {
  if (bounds[0] === undefined) {
    return getYAxisAutoRangeLayout(chart);
  } else {
    const ranges = computeChartYRanges(chart, bounds);
    const padded = padChartYRanges(ranges);
    return formatRangesAndBoundsToLayout(padded, bounds);
  }
}

export {
  getUnixTimestampsFromRelayoutEvent,
  checkIfBoundsChanged,
  getNewLayoutFor,
  getYAxisAutoRangeLayout,
};
