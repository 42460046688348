import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: true,
  },
  breakpoint: {
    thresholds: {
      xs: 576,
      sm: 768,
      md: 1024,
      lg: 1400,
    },
  },
});