<template>
  <div class="current-vehicle-info-container">
    <div class="vehicle-container">
      <div v-if="activeVehicle" class="car-info">
        <img class="car-image" :src="activeVehicle.image" alt="" />
        <div class="text">
          <h3>{{ activeVehicle.name }}</h3>
          <h5>{{ activeVehicle.make || "" }} {{ activeVehicle.model || "" }}</h5>
        </div>
      </div>
      <div v-else class="car-info">
        <div class="car-image"></div>
        <div class="text">
          <h3 class="disabled">{{ $t("ui.dashboard.activeVehicle.name") }}</h3>
        </div>
      </div>
      <button class="btn btn--color-default btn--lg" @click="requestOpenVehicleSelect">
        {{ $t("ui.common.button.select") }}
      </button>
    </div>

    <div v-if="activeVehicle && showLastUpdatedAt" class="last-updated-container">
      <div class="last-updated-bubble">
        <div class="status-indicator-circle" :class="lastUpdatedAt ? 'healthy' : 'warning'" />
        <p>
          {{
            $t("vehicle.lastUpdatedAt", {
              vehicleLastSeenAt,
            })
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { type PropType } from "vue";
import type { Vehicle } from "@/@types/data/vehicle.types";
import { formatTimeAgo } from "@/utils/dates/relativeDates.utils";

export default Vue.extend({
  name: "active-vehicle-bar",
  props: {
    activeVehicle: {
      type: Object as PropType<Vehicle>,
      required: false,
    },
    showLastUpdatedAt: {
      type: Boolean,
      required: false,
      default: true,
    },
    lastUpdatedAt: {
      type: Date as PropType<Date>,
      required: false,
    },
  },
  methods: {
    requestOpenVehicleSelect() {
      this.$emit("open-vehicle-select");
    },
  },

  computed: {
    vehicleLastSeenAt(): string | undefined {
      return this.lastUpdatedAt ? formatTimeAgo(this.lastUpdatedAt as Date) : "Unkown Time";
    },
  },
});
</script>

<style lang="scss" scoped>
.current-vehicle-info-container {
}

@mixin hoots-card {
  background: #111111;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
}

@mixin hoots-floating {
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.25);
}

.vehicle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  width: 100%;
  justify-content: space-between;

  border-radius: 8px;

  @include hoots-card;
  @include hoots-floating;
}

.car-info {
  display: flex;
  align-items: center;
  min-width: 0;

  .car-image {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 0.7rem;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .text {
    display: flex;
    min-width: 0;
    flex-direction: column;
    margin-right: 1rem;

    h3 {
      font-size: 1.1rem;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.disabled {
        color: rgba(255, 255, 255, 0.5);
      }
    }
    h5 {
      margin: 0;
      opacity: 0.5;
      font-size: 0.8rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.last-updated-container {
  margin-top: 0.3rem;

  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  .last-updated-bubble {
    border-radius: 4rem;
    padding: 4px 12px;
    display: flex;
    align-items: center;

    @include hoots-card;
    @include hoots-floating;

    .status-indicator-circle {
      $diameter: 6px;
      height: $diameter;
      width: $diameter;
      border-radius: 100%;
      margin-right: 0.6rem;

      &.healthy {
        background-color: mediumspringgreen;
      }

      &.warning {
        background-color: orange;
      }
    }

    p {
      margin: 0;
    }
  }
}
</style>
