function generateRowWithIconAndText(icon: string, text: string) {
  return `<tr><td><i class="mdi ${icon}"></i></td><td>${text}</td></tr>`;
}

export function generateTripMarkerCustomHTML(
  title: string,
  addressText: string,
  coordinates: string,
  time: string
) {
  return `<strong>${title}</strong><br>
     <table class='popup'>
    <tbody>
    ${generateRowWithIconAndText("mdi-map-marker", addressText)}
    ${generateRowWithIconAndText("mdi-map-marker", coordinates)}
    ${generateRowWithIconAndText("mdi-clock", time)}
    </tbody>
    </table>`;
}

export function generateLastPositionCustomHTML(
  title: string,
  addressText: string,
  vehicleName: string,
  deviceID: string,
  time: string
): string {
  return `<strong>${title}</strong><br>
     <table class='popup'>
    <tbody>
    ${generateRowWithIconAndText("mdi-map-marker", addressText)}
    ${generateRowWithIconAndText("mdi-car", vehicleName)}
    ${generateRowWithIconAndText("mdi-router-wireless", deviceID)}
    ${generateRowWithIconAndText("mdi-clock", time)}
    </tbody>
    </table>`;
}
