<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" persistent scrollable max-width="700px">
      <vehicle-editor
        :title="dialogueTitle"
        :initial-vehicle="initialVehicle"
        @save-vehicle="saveVehicle"
        @close="close"
      />
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import type { Vehicle } from "@/@types/data/vehicle.types";

import { mapGetters } from "vuex";
import VehicleEditor from "@/components/shared/vehicle/VehicleEditor.vue";

export default Vue.extend({
  name: "edit-vehicle",
  components: { VehicleEditor },
  props: {
    vehicleId: {
      type: Number as PropType<Vehicle["id"]>,
      required: false,
    },
  },
  data() {
    return {
      showDialog: true,
    };
  },
  computed: {
    ...mapGetters("vehicle", ["getVehicleByID"]),
    dialogueTitle(): string {
      return this.$t("ui.vehicle.edit") as string;
    },
    initialVehicle(): Vehicle {
      return { ...this.getVehicleByID(this.vehicleId) };
    },
  },
  methods: {
    async saveVehicle(vehicle: Vehicle): Promise<void> {
      if (window.location.hostname !== "demo.hoots.online") {
        const updateRequest = await this.$store.dispatch("vehicle/update", vehicle);
        if (updateRequest.wasSuccessful()) {
          this.close();
        } else {
          this.$emit("error", updateRequest.getMessage());
        }
      }
    },
    close(): void {
      this.$emit("close");
    },
  },
});
</script>
