let statsButtonEnabledState = false;

import {
  update,
  type PlotlyHTMLElement,
  type Annotations,
  type YAxisName,
} from "plotly.js";

import { EventBus } from "@/plugins/event-bus.js";

import { min, max } from "mathjs";

function unit_from_id(id: string) {
  let unit = "";
  if (id === "gps") {
    unit = "km/h";
  }
  if (id === "rpm") {
    unit = "U/min";
  }
  if (id === "imu") {
    unit = "g";
  }
  if (id === "voltage") {
    unit = "V";
  }
  if (id === "comp") {
    unit = "°C";
  }
  if (id === "cool") {
    unit = "°C";
  }
  if (id === "temp") {
    unit = "°C";
  }
  return unit;
}

export default function (
  gd: PlotlyHTMLElement & {
    data: { x: number[]; y: number[]; yaxis: YAxisName }[];
  }
) {
  const annotations = [];
  let ts_max = 0;
  let a_max = 0;
  for (let i = 0; i < gd.data.length; i++) {
    if (gd.data[i].y[0] === null) {
      continue;
    }
    const a_min = min(gd.data[i].y);
    a_max = max(gd.data[i].y);
    let precision = 1;
    if (gd.id === "gps" || gd.id === "rpm") {
      precision = 0;
    }
    if (gd.id === "cool" || gd.id === "comp" || gd.id === "temp") {
      precision = 1;
    }
    if (gd.id === "voltage") {
      precision = 2;
    }
    const min_annotation: Partial<Annotations> = {
      text: a_min.toFixed(precision) + " " + unit_from_id(gd.id),
      x: gd.data[i].x[gd.data[i].y.indexOf(a_min)],
      y: a_min,
      yref: gd.data[i].yaxis,
      xref: "x",
      ay: 20,
      ax: 0,
      font: {
        color: "#ffffff",
      },
      align: "center",
      arrowhead: 2,
      arrowsize: 1,
      arrowwidth: 2,
      arrowcolor: "#00ff00",
      borderwidth: 1,
      borderpad: 2,
      opacity: 1.0,
    };
    const max_annotation: Partial<Annotations> = {
      text: a_max.toFixed(precision) + " " + unit_from_id(gd.id),
      x: gd.data[i].x[gd.data[i].y.indexOf(a_max)],
      y: a_max,
      yref: gd.data[i].yaxis,
      xref: "x",
      ay: -20,
      ax: 0,
      font: {
        color: "#ffffff",
      },
      align: "center",
      arrowhead: 2,
      arrowsize: 1,
      arrowwidth: 2,
      arrowcolor: "#ff0000",
      borderwidth: 1,
      borderpad: 2,
      opacity: 1.0,
    };

    ts_max = gd.data[i].x[gd.data[i].y.indexOf(a_max)];
    annotations.push(min_annotation);
    annotations.push(max_annotation);
  }

  const btns = document.getElementsByClassName("modebar-btn");

  statsButtonEnabledState = !statsButtonEnabledState;

  if (statsButtonEnabledState) {
    for (let i = 0; i < btns.length; i++) {
      if (btns[i].getAttribute("data-title") === "Statistik") {
        btns[i].classList.remove("enabled");
      }
    }
    update(
      gd,
      {},
      {
        annotations: annotations,
      }
    ).then(() => {
      EventBus.$emit("maxMarkerOn", ts_max, a_max, unit_from_id(gd.id));
    });
  } else {
    for (let i = 0; i < btns.length; i++) {
      if (btns[i].getAttribute("data-title") === "Statistik") {
        btns[i].classList.add("enabled");
      }
    }

    // clear annotations
    update(
      gd,
      {},
      {
        annotations: [],
      }
    ).then(() => {
      EventBus.$emit("maxMarkerOff", ts_max);
    });
  }
}
