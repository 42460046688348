import { SessionSensorData } from "@/@types/data/session.types";

export type SensorPlotData = Omit<Required<SessionSensorData>, "coordinates" | "temp">;

export type MeasurementAccuracy = 1 | 2;
export type MeasurementType =
  | "frequency"
  | "velocity"
  | "humidity"
  | "lowPressure"
  | "highPressure"
  | "voltage"
  | "temperature"
  | "lambda"
  | "acceleration";

type MeasurementUnitMap = {
  [type in MeasurementType]: {
    unit: string;
    accuracy: MeasurementAccuracy;
    title: string;
  };
};

export type SensorTypePlotMap = {
  [sensor in keyof SensorPlotData]: {
    [property in keyof Partial<Omit<SensorPlotData[sensor], "ts">>]: {
      name: string;
      type: MeasurementType;
      pos?: number;
    };
  };
};

export const measurementUnitMap: MeasurementUnitMap = {
  frequency: { unit: "1/min", accuracy: 2, title: "ui.charts.axis.frequency" },
  velocity: { unit: "km/h", accuracy: 2, title: "ui.charts.axis.speed" },
  humidity: { unit: "%rel", accuracy: 1, title: "ui.charts.axis.humidity" },
  lowPressure: { unit: "mbar", accuracy: 1, title: "ui.charts.axis.lowPressure" },
  highPressure: { unit: "bar", accuracy: 2, title: "ui.charts.axis.highPressure" },
  voltage: { unit: "V", accuracy: 2, title: "ui.charts.axis.voltage" },
  temperature: { unit: "°C", accuracy: 2, title: "ui.charts.axis.temperature" },
  lambda: { unit: "", accuracy: 2, title: "ui.charts.axis.lambda" },
  acceleration: { unit: "g", accuracy: 2, title: "ui.charts.axis.acceleration" },
} as const;

export const plotlyDataConfig: SensorTypePlotMap = {
  gps: {
    v_kmh: {
      name: "ui.charts.gps.speed",
      type: "velocity",
    },
  },
  rpm: {
    rpm: {
      name: "ui.charts.rpm.rpm",
      type: "frequency",
    },
  },
  ambient: {
    h_amb: {
      name: "ui.charts.ambient.humidity",
      type: "humidity",
    },
    p_amb: {
      name: "ui.charts.ambient.pressure",
      type: "lowPressure",
    },
    t_amb: {
      name: "ui.charts.ambient.temperature",
      type: "temperature",
    },
  },
  voltages: {
    v_int: {
      name: "ui.charts.voltage.internal",
      type: "voltage",
    },
    v_ext: {
      name: "ui.charts.voltage.external",
      type: "voltage",
    },
  },
  oil: {
    p_bar1: {
      name: "ui.charts.oil.pressure",
      type: "highPressure",
    },
    p_bar2: {
      name: "ui.charts.oil.pressure",
      pos: 2,
      type: "highPressure",
    },
    t_c1: {
      name: "ui.charts.oil.temperature",
      type: "temperature",
    },
    t_c2: {
      name: "ui.charts.oil.temperature",
      pos: 2,
      type: "temperature",
    },
  },
  cool: {
    t_c1: {
      name: "ui.charts.cool.temperature",
      type: "temperature",
    },
    t_c2: {
      name: "ui.charts.cool.temperature",
      pos: 2,
      type: "temperature",
    },
  },
  comp: {
    t_c1: {
      name: "ui.charts.comp.temperature",
      type: "temperature",
    },
    t_c2: {
      name: "ui.charts.comp.temperature",
      pos: 2,
      type: "temperature",
    },
  },
  hot: {
    t_c1: {
      name: "ui.charts.hot.temperature",
      type: "temperature",
    },
    t_c2: {
      name: "ui.charts.hot.temperature",
      pos: 2,
      type: "temperature",
    },
  },
  lambda: {
    v_l1: {
      name: "ui.charts.lambda.lambda",
      type: "lambda",
    },
    v_l2: {
      name: "ui.charts.lambda.lambda",
      type: "lambda",
    },
  },
  imu: {
    ax: {
      name: "ui.charts.imu.ax",
      type: "acceleration",
    },
    ay: {
      name: "ui.charts.imu.ay",
      type: "acceleration",
    },
    az: {
      name: "ui.charts.imu.az",
      type: "acceleration",
    },
  },
} as const;
