import { EventBus } from "./eventbus.types";
import EventHandler = EventBus.EventHandler;
import Observer = EventBus.Observer;

export function createEventBus<Events extends EventBus.BusDefinition>(debug = false) {
  const EventBus = (): EventBus.EventBus<Events> => {
    const retainedMessages: Map<keyof Events, EventBus.Payload> = new Map();

    const listenerMap: Map<
      keyof Events,
      (EventBus.Observer<EventBus.Payload> | EventBus.EventHandler<EventBus.Payload>)[]
    > = new Map();

    return {
      subscribe: <K extends keyof Events>(
        channel: K,
        handlerOrObserver: EventBus.ClassHandlerOrObserver<Events[K]>
      ): EventBus.ClassHandlerOrObserver<Events[K]> => {
        if (!listenerMap.has(channel)) {
          listenerMap.set(channel, [
            handlerOrObserver,
          ] as EventBus.ClassHandlerOrObserver<EventBus.Payload>[]);
        } else {
          listenerMap
            .get(channel)
            ?.push(handlerOrObserver as EventBus.ClassHandlerOrObserver<EventBus.Payload>);
        }

        const retainedMessage = retainedMessages.get(channel);

        if (retainedMessage !== undefined) {
          if (typeof handlerOrObserver === "function") {
            (handlerOrObserver as Observer<EventBus.Payload>)(retainedMessage);
          } else {
            (handlerOrObserver as EventHandler<EventBus.Payload>).handleEvent(retainedMessage);
          }
        }

        return handlerOrObserver;
      },

      unsubscribe: <K extends keyof Events>(
        channel: K,
        targetHandlerOrObserver: EventBus.ClassHandlerOrObserver<Events[K]>
      ): void => {
        if (listenerMap.has(channel) && listenerMap.get(channel)) {
          const handlers = listenerMap.get(channel);
          if (handlers) {
            const handlersWithoutTarget = handlers.filter(
              (handlerOrObserver) => handlerOrObserver !== targetHandlerOrObserver
            );
            listenerMap.set(channel, handlersWithoutTarget);
          }
        }
      },

      dispatch<K extends keyof Events>(
        channel: K,
        payload: Events[K],
        options: EventBus.DispatchOptions = {}
      ): void {
        if (options.retain) retainedMessages.set(channel, payload);

        if (listenerMap.has(channel)) {
          const handlers = listenerMap.get(channel);
          if (handlers) {
            handlers.forEach((handler) => {
              if (typeof handler === "function") {
                handler(payload);
              } else {
                handler.handleEvent(payload);
              }
            });
          } else if (debug) console.log(channel, payload);
        }
      },
    };
  };

  return EventBus();
}

export class EventBusMaster<T extends EventBus.MasterBusDefinition>
  implements EventBus.EventBusMaster<T>
{
  public constructor(private busMap: T) {}

  public getBus<K extends keyof T>(busName: K): T[K] {
    return this.busMap[busName];
  }

  public subscribe<
    BusName extends keyof T,
    BusEvents extends T[BusName] extends EventBus.EventBus<infer A> ? A : never,
    EventName extends keyof BusEvents
  >(
    busName: BusName,
    channel: EventName,
    handler: EventBus.ClassHandlerOrObserver<BusEvents[EventName]>
  ): EventBus.ClassHandlerOrObserver<BusEvents[EventName]> {
    this.busMap[busName].subscribe(channel, handler);
    return handler;
  }

  public unsubscribe<
    BusName extends keyof T,
    BusEvents extends T[BusName] extends EventBus.EventBus<infer A> ? A : never,
    EventName extends keyof BusEvents
  >(
    busName: BusName,
    channel: EventName,
    handler: EventBus.ClassHandlerOrObserver<BusEvents[EventName]>
  ): void {
    this.busMap[busName].unsubscribe(channel, handler);
  }

  public dispatch<
    BusName extends keyof T,
    BusEvents extends T[BusName] extends EventBus.EventBus<infer A> ? A : never,
    EventName extends keyof BusEvents
  >(busName: BusName, channel: EventName, payload: BusEvents[EventName]): void {
    this.busMap[busName].dispatch(channel as string, payload);
  }
}
