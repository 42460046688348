import i18n from "@/plugins/i18n.plugin";
import type { Layout } from "plotly.js";
import Plotly from "plotly.js";
import plotlyAnnotations from "@/charts/config/config.annotations.plotly";

const titleSizeInPx = 16;
const legendSizeInPx = 12;
const textColor = "#ececec";
const gridColor = "#444444";

const defaultLayout: Partial<Layout> = {
  title: {
    font: {
      size: titleSizeInPx,
      color: textColor,
    },
  },

  dragmode: "zoom",
  selectdirection: "h",

  // @ts-expect-error not typed yet
  spikedistance: -1,

  xaxis: {
    type: "date",
    tickformatstops: [
      {
        dtickrange: [null, 1000],
        value: "%H:%M:%S.%L",
      },
      {
        dtickrange: [1000, 60000],
        value: "%H:%M:%S",
      },
      {
        dtickrange: [60000, 3600000],
        value: "%H:%M",
      },
      {
        dtickrange: [3600000, 86400000],
        value: "%H:%M",
      },
      {
        dtickrange: [86400000, 604800000],
        value: "%e. %b",
      },
      {
        dtickrange: [604800000, "M1"],
        value: "%e. %b",
      },
      {
        dtickrange: ["M1", "M12"],
        value: "%b '%y",
      },
      {
        dtickrange: ["M12", null],
        value: "%Y",
      },
    ],
    tickfont: {
      color: "#ececec",
    },
    showgrid: true,
    zeroline: true,
    hoverformat: "%H:%M:%S",
    gridcolor: gridColor,
    showspikes: true,
    spikemode: "across",
    spikesnap: "data",
    spikedash: "solid",
    spikecolor: "#FF0000",
    spikethickness: 1,
  },
  yaxis: {
    fixedrange: true, // fixed range for zoom: allow zoom only in x-axis direction
    showgrid: true,
    zeroline: true,
    gridcolor: gridColor,
    tickfont: {
      color: textColor,
    },
    titlefont: {
      color: "#ececec",
    },
  },
  yaxis2: {
    fixedrange: true, // fixed range for zoom: allow zoom only in x-axis direction
    overlaying: "y",
    side: "right",
    position: 1,
    tickfont: {
      color: textColor,
    },
    titlefont: {
      color: "#fff",
    },
    showgrid: false,
    gridcolor: gridColor,
  },
  yaxis3: {
    fixedrange: true, // fixed range for zoom: allow zoom only in x-axis direction
    overlaying: "y",
    anchor: "free",
    // scaleanchor: "y",
    side: "right",
    position: 0.1,
    automargin: true,
    tickfont: {
      color: textColor,
    },
    titlefont: {
      color: "#fff",
    },
    showgrid: false,
  },
  legend: {
    orientation: "h",
    font: {
      size: legendSizeInPx,
      color: textColor,
    },
  },
  showlegend: true,
  paper_bgcolor: "rgba(48,48,48, 0.0)",
  plot_bgcolor: "#161616",
};

const plotlyOptions: Plotly.Config = {
  responsive: true,
  sendData: false,
  displaylogo: false,
  locale: i18n.locale,
  toImageButtonOptions: {
    format: "jpeg",
    filename: "hoots_online_".concat(new Date().toISOString()),
    width: 640,
    height: 480,
    scale: 1,
  },
  displayModeBar: true,
  modeBarButtons: [
    [
      {
        title: "",
        name: i18n.t("ui.charts.stats") as string,
        // @ts-expect-error plotly types
        icon: Plotly.Icons.spikeline,
        // @ts-expect-error plotly types
        click: plotlyAnnotations,
      },
    ],
    ["toImage", "zoomIn2d", "zoomOut2d", "resetScale2d"],
  ],
};

export { defaultLayout, plotlyOptions };
