import { LocalStorageProvider } from "@/service/storage/localstorage.provider";

const localStorageProvider = new LocalStorageProvider();

const LOCALSTORAGE_KEYS = { SELECTED_VEHICLE_ID: "device:selected-vehicle-id" };

function persistActiveVehicleId(vehicleId) {
  vehicleId && localStorageProvider.addItem(LOCALSTORAGE_KEYS.SELECTED_VEHICLE_ID, vehicleId);
}

const state = {
  drawer: true,
  currentVehicleID: null,
};

const getters = {
  getAppActiveVehicleID: (state) => state.currentVehicleID,
};

const actions = {
  initialize: async ({ state }) => {
    state.currentVehicleID = +localStorageProvider.getItem(LOCALSTORAGE_KEYS.SELECTED_VEHICLE_ID);
  },

  setCurrentVehicle: ({ state }, vehicleID) => {
    state.currentVehicleID = vehicleID;
    persistActiveVehicleId(vehicleID);
  },

  clearData: ({ state }) => {
    state.currentVehicleID = null;
    localStorageProvider.deleteItem(LOCALSTORAGE_KEYS.SELECTED_VEHICLE_ID);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
