<template>
  <div class="page-container">
    <div class="loading-container" v-if="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div>

    <div v-if="vehiclePickDialog" class="vehicle-picker-container">
      <vehicle-picker
        :showDialog="true"
        :active-vehicle-i-d="appActiveVehicleID"
        @select-vehicle="selectVehicle"
        @close="vehiclePickDialog = false"
      ></vehicle-picker>
    </div>

    <div class="main-container">
      <div class="vehicle-container">
        <active-vehicle-bar
          :active-vehicle="activeVehicle"
          :last-updated-at="activeDeviceLastUpdatedDate"
          @open-vehicle-select="openVehiclePickDialogue"
        />
      </div>
      <div class="map-container">
        <leaflet-map :zoom-controls="false" :last-location="lastLocation" />
      </div>
      <transition name="slide-up">
        <div v-if="!mapCenteredOnLastLocation" class="reset-button-container">
          <button class="btn btn--color-default btn--lg" @click="centerMapToLastLocation">
            {{ $t("ui.map.recenter") }}
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";

import LeafletMap from "@/components/base/map/LeafletMap.vue";
import VehiclePicker from "@/components/VehiclePicker.vue";
import ActiveVehicleBar from "@/components/shared/vehicle/ActiveVehicleBar.vue";
import { Vehicle } from "@/@types/data/vehicle.types";
import { LeafletMap as LeafletMapTypes } from "@/@types/ui/map/leafletmap.types";
import { DeviceLastKnownLocation } from "@/@types/data/device.types";
import { Coordinates, ReverseGeo } from "@/@types/data/geo.types";
import {
  DashboardCommandID,
  dashboardEventBus,
  DashboardEventID,
} from "@/events/dashboard.channel";
import { parseISO } from "date-fns";
import { areRoughlyEqual } from "@/utils/coordinates/coordinateComparison.utils";

export default Vue.extend({
  components: {
    LeafletMap,
    VehiclePicker,
    ActiveVehicleBar,
  },
  data() {
    return {
      loading: false,
      vehiclePickDialog: false,
      mapCenteredOnLastLocation: false,
    };
  },

  computed: {
    ...mapGetters("device", [
      "getActiveVehicleID",
      "getLastDeviceLocationByDeviceID",
      "getLastDeviceAddressDataByDeviceID",
    ]),
    ...mapGetters("vehicle", ["getVehicleByID"]),
    ...mapGetters("app", ["getAppActiveVehicleID"]),

    appActiveVehicleID(): Vehicle["id"] | null {
      return this.getAppActiveVehicleID || null;
    },

    activeVehicle(): Vehicle | undefined {
      const activeVehicleID = this.getAppActiveVehicleID;
      return (activeVehicleID && this.getVehicleByID(activeVehicleID)) || undefined;
    },

    activeDeviceLastLocation(): DeviceLastKnownLocation | undefined {
      const activeDeviceID = this.activeVehicle?.device_id;
      return activeDeviceID && this.getLastDeviceLocationByDeviceID(activeDeviceID);
    },

    activeDeviceLastAddress(): ReverseGeo.Item | undefined {
      const activeDeviceID = this.activeVehicle?.device_id;
      return activeDeviceID && this.getLastDeviceAddressDataByDeviceID(activeDeviceID);
    },

    activeDeviceLastUpdatedDate(): Date | undefined {
      return this.activeDeviceLastLocation?.updated_at !== undefined
        ? parseISO(this.activeDeviceLastLocation.updated_at)
        : undefined;
    },

    lastLocation(): LeafletMapTypes.LastLocation | undefined {
      const time = this.activeDeviceLastLocation?.updated_at;
      const coordinate = this.activeDeviceLastLocation
        ? {
            lng: this.activeDeviceLastLocation.longitude,
            lat: this.activeDeviceLastLocation.latitude,
          }
        : undefined;
      const vehicle = this.activeVehicle;
      const address = this.activeDeviceLastAddress;
      return { time, coordinate, vehicle, address };
    },
  },

  methods: {
    openVehiclePickDialogue(): void {
      this.vehiclePickDialog = true;
    },

    closeVehiclePicker(): void {
      this.vehiclePickDialog = false;
    },

    centerMapToLastLocation() {
      dashboardEventBus.dispatch(DashboardCommandID.CENTER_MAP_TO_LAST_LOCATION, {});
    },

    coordinateMatchesLastLocation(mapCenter: Coordinates): boolean {
      return (
        this.lastLocation?.coordinate !== undefined &&
        areRoughlyEqual(this.lastLocation.coordinate, mapCenter)
      );
    },

    handleMapMoved(newCenter: { to: Coordinates }) {
      this.mapCenteredOnLastLocation = this.coordinateMatchesLastLocation(newCenter.to);
    },

    async selectVehicle(vehicleID: Vehicle["id"]): Promise<void> {
      this.loading = true;

      await this.$store.dispatch("app/setCurrentVehicle", vehicleID);
      this.closeVehiclePicker();
      await this.loadActiveDeviceData();

      this.loading = false;

      this.centerMapToLastLocation();
    },

    subscribeToEvents(): void {
      dashboardEventBus.subscribe(DashboardEventID.MAP_CENTER_MOVED, this.handleMapMoved);
    },

    async loadActiveDeviceData(): Promise<void> {
      if (this.activeVehicle?.device_id)
        await this.$store.dispatch(
          "device/loadAddressForDeviceLastLocation",
          this.activeVehicle.device_id
        );
    },

    unsubscribeFromEvents(): void {
      dashboardEventBus.unsubscribe(DashboardEventID.MAP_CENTER_MOVED, this.handleMapMoved);
    },

    async setInitialVehicle(): Promise<void> {
      const appActiveVehicleID = this.getAppActiveVehicleID || this.getActiveVehicleID || null;
      await this.$store.dispatch("app/setCurrentVehicle", appActiveVehicleID);
    },

    async initializeStore(): Promise<void> {
      await Promise.all([
        this.$store.dispatch("vehicle/fetchAll"),
        this.$store.dispatch("device/initialize"),
        this.$store.dispatch("app/initialize"),
      ]);
    },

    setScrollbarOnHtml(visible: boolean) {
      const html = document.querySelector("html");
      if (html) html.style.overflowY = visible ? "auto" : "hidden";
    },
  },

  async mounted() {
    this.loading = true;
    this.setScrollbarOnHtml(false);

    await this.initializeStore();
    await this.setInitialVehicle();

    this.subscribeToEvents();
    this.centerMapToLastLocation();

    this.loading = false;
  },

  beforeDestroy() {
    this.unsubscribeFromEvents();
    this.setScrollbarOnHtml(true);
  },
});
</script>

<style lang="scss" scoped>
@use "src/style/animations/position";
@include position.createVueAnimationClasses("slide-up");

.page-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.vehicle-picker-container {
  position: absolute;
  inset: 0;
  z-index: 4;
}

.loading-container {
  position: absolute;
  inset: 0;
  z-index: 3;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.2);
}

.main-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.vehicle-container {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;

  padding: 1rem;
}

.map-container {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.reset-button-container {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 1rem;
  z-index: 2;
}
</style>
