<template>
  <div v-if="!showChartsAsGrid">
    <v-carousel
      :continuous="true"
      :cycle="cycleChartsActive"
      :show-arrows="true"
      hide-delimiter-background
      show-arrows-on-hover
      eager
      @change="handleCarouselChange"
    >
      <v-carousel-item v-for="chart in charts" :key="chart.id" eager>
        <hoots-single-chart :chart="chart" />
      </v-carousel-item>
    </v-carousel>
  </div>
  <div v-else>
    <v-row no-gutters align="start" justify="start">
      <v-col v-for="chart in charts" :key="chart.id" cols="12" xs="12" sm="12" md="6" lg="4">
        <hoots-single-chart :chart="chart" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";

import Plotly from "plotly.js";

import { buildCharts, HootsChart } from "@/charts/processor.plotly";

import type { Locale } from "@/@types/ui/language/locale.types";
import { uiEventBus, UIEventID } from "@/events/ui.channel";
import { defaultPlotConfig } from "@/charts/config/config.plots.plotly";
import HootsSingleChart from "@/components/HootsSingleChart.vue";

export default Vue.extend({
  components: {
    HootsSingleChart,
  },
  props: {
    cycleChartsActive: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters("device", ["getActiveSessionSensorData"]),
    ...mapGetters("ui/dashboard", ["getShowAllCharts"]),

    showChartsAsGrid(): boolean {
      return this.getShowAllCharts;
    },

    charts(): HootsChart[] {
      const data = this.getActiveSessionSensorData;
      return buildCharts(defaultPlotConfig, data);
    },
  },

  methods: {
    handleCarouselChange(index: number): void {
      uiEventBus.dispatch(UIEventID.CAROUSEL_CHART_VISIBLE, {
        chartId: this.charts[index]?.id,
      });
    },

    handleLocaleChange(locale: { locale: Locale }): void {
      // @ts-expect-error  (Plotlys type file is missing so much)
      Plotly.setPlotConfig({ locale: locale });
    },
  },

  mounted() {
    uiEventBus.subscribe(UIEventID.UPDATE_LOCALE, this.handleLocaleChange);
  },

  beforeDestroy() {
    // this.unlinkChart?.();
    uiEventBus.unsubscribe(UIEventID.UPDATE_LOCALE, this.handleLocaleChange);
  },
});
</script>
