<template>
  <v-app-bar app clipped-left dense v-if="$route.name !== 'map'">
    <v-app-bar-nav-icon v-if="isMobile" @click.stop="onToggleDrawer" />
    <v-toolbar-title id="toolbar-title">hoots|online</v-toolbar-title>

    <privacy v-bind:showDialog="privacy" @close="privacy = false"></privacy>
    <terms v-bind:showDialog="terms" @close="terms = false"></terms>

    <v-spacer></v-spacer>

    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          icon
          v-on="{ ...tooltip }"
          v-show="isLoggedIn"
          data-test="messagecenter"
          to="/messagecenter"
        >
          <v-badge color="error" overlap dot :value="unread" :content="unread">
            <v-icon color="tertiary" v-if="unread > 0 && !loading">mdi-bell-alert</v-icon>
            <v-icon color="info" v-else-if="unread > 0 && loading">mdi-bell-alert</v-icon>
            <v-icon color="info" v-else-if="unread === 0 && loading">mdi-bell</v-icon>
            <v-icon v-else>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <span
        >{{ unread > 0 ? unread : $t("ui.toolbar.messagecenter.no") }}
        {{
          unread > 0
            ? $t("ui.toolbar.messagecenter.unread")
            : $t("ui.toolbar.messagecenter.unreads")
        }}
        {{
          unread === 1
            ? $t("ui.toolbar.messagecenter.message")
            : $t("ui.toolbar.messagecenter.messages")
        }}</span
      >
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn id="logout" text @click="logout" v-on="on" v-show="isLoggedIn" data-test="logout">
          <!-- <v-icon>mdi-exit-to-app</v-icon> -->
          <v-icon>mdi-power</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("ui.toolbar.logout") }}</span>
    </v-tooltip>

    <v-menu left bottom>
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn icon v-on="{ ...tooltip, ...menu }">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("ui.toolbar.settings.menu") }}</span>
        </v-tooltip>
      </template>

      <v-list dense>
        <v-subheader>{{ $t("ui.toolbar.legal.menu") }}</v-subheader>
        <v-list-item @click="privacy = true">
          <v-list-item-action>
            <v-icon small>mdi-shield-check</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $t("ui.toolbar.legal.privacy") }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="terms = true">
          <v-list-item-action>
            <v-icon small>mdi-file</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $t("ui.toolbar.legal.terms") }}</v-list-item-title>
        </v-list-item>
        <!--        <v-divider inset></v-divider> -->
        <v-subheader>{{ $t("ui.toolbar.locale") }}</v-subheader>
        <v-list-item
          v-for="lang in localeSelectorData"
          :key="lang.locale"
          active-class="primary darken-3"
          class="v-list-item"
          @click="setLocale(lang.locale)"
          :disabled="isLocale(lang.locale)"
        >
          <v-list-item-action>
            <flag :iso="lang.flag" />
          </v-list-item-action>
          <v-list-item-title v-text="lang.title" />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import i18n from "@/plugins/i18n.plugin";
import { Locale } from "@/@types/ui/language/locale.types";
import Privacy from "@/components/PrivacyPolicy.vue";
import Terms from "@/components/TermsAndConditions.vue";
import { uiEventBus, UIEventID } from "@/events/ui.channel";

enum Flag {
  EN = "us",
  DE = "de",
}

type LocaleSelectorData = {
  title: string;
  locale: Locale;
  flag: Flag;
}[];

const localeSelectorData: LocaleSelectorData = [
  {
    title: "Deutsch",
    locale: Locale.DE,
    flag: Flag.DE,
  },
  {
    title: "English",
    locale: Locale.EN,
    flag: Flag.EN,
  },
];

export default Vue.extend({
  components: {
    privacy: Privacy,
    terms: Terms,
  },
  data: () => ({
    privacy: false,
    terms: false,
    localeSelectorData,
  }),

  computed: {
    isLoggedIn(): boolean {
      return this.$auth.isLoggedIn();
    },
    isMobile(): boolean {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    ...mapState("messages", ["loading"]),
    ...mapGetters("messages", ["unread"]),
  },

  methods: {
    logout(): void {
      this.$auth.logout();
      this.$store.dispatch("clearData");
    },

    setLocale(locale: Locale): void {
      i18n.locale = locale;
      uiEventBus.dispatch(UIEventID.UPDATE_LOCALE, { locale });
    },

    isLocale(locale: Locale): boolean {
      return i18n.locale == locale;
    },

    onToggleDrawer(): void {
      uiEventBus.dispatch(UIEventID.TOGGLE_MENU, {});
    },
  },
});
</script>

<style>
#core-toolbar a {
  text-decoration: none;
}
</style>
