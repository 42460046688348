import { createEventBus } from "@/packages/event-bus";
import { Locale } from "@/@types/ui/language/locale.types";

const debugEnabled = process.env.NODE_ENV === "development";

type Empty = Record<string, never>;

export enum UIEventID {
  TOGGLE_MENU = "toggle:menu",
  UPDATE_LOCALE = "update:locale",
  CAROUSEL_CHART_VISIBLE = "update:carousel:chart:visible",
}

export type UIEvents = {
  [UIEventID.TOGGLE_MENU]: Empty;
  [UIEventID.UPDATE_LOCALE]: { locale: Locale };
  [UIEventID.CAROUSEL_CHART_VISIBLE]: { chartId: string };
};

const uiEventBus = createEventBus<UIEvents>(debugEnabled);

export { uiEventBus };
