import { Session } from "@/@types/data/session.types";
import { Device } from "@/@types/data/device.types";
import { Vehicle } from "@/@types/data/vehicle.types";

function getUniqueDevicesFromSessionList(
  sessionList: Session[]
): Device["id"][] {
  const uniqueDevicesSet = sessionList.reduce((devicesSet, session) => {
    devicesSet.add(session.device_id);
    return devicesSet;
  }, new Set<Device["id"]>());
  return Array.from(uniqueDevicesSet);
}

function getUniqueVehiclesFromSessionList(
  sessionList: Session[]
): Vehicle["id"][] {
  const uniqueDevicesSet = sessionList.reduce((devicesSet, session) => {
    devicesSet.add(session.vehicle_id);
    return devicesSet;
  }, new Set<Vehicle["id"]>());
  return Array.from(uniqueDevicesSet);
}

function categorizeSessionsByVehicleId(
  sessionList: Session[]
): Record<Session["vehicle_id"], Session[]> {
  return sessionList.reduce((sessionsByVehicle, session) => {
    const existingSessions = sessionsByVehicle[session.vehicle_id] || [];
    existingSessions.push(session);
    sessionsByVehicle[session.vehicle_id] = existingSessions;
    return sessionsByVehicle;
  }, {} as Record<Session["vehicle_id"], Session[]>);
}

export {
  getUniqueDevicesFromSessionList,
  getUniqueVehiclesFromSessionList,
  categorizeSessionsByVehicleId,
};
