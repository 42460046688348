import { Async, Response } from "@/@types/helper/api.types";
import { TimestampTZ } from "@/@types/helper/postgres.types";

import { UserProvider } from "@/service/user/user.interface";
import { AuthProvider } from "@/service/auth/auth.interface";
import { HootsUserService } from "@/service/user/hootsuser/hootsuser.types";
import { FetchProvider } from "@/service/fetch/fetch.interface";

interface BaseUser {
  id: string;
  email: string;
  phone?: string;
  password: string;
}

interface User extends BaseUser {
  created_at: TimestampTZ;
  updated_at: TimestampTZ;
  last_login: TimestampTZ;
}

export class HootsUserProvider implements UserProvider<BaseUser> {
  private initialized = false;
  private currentUser?: User;

  constructor(
    private config: HootsUserService.Config,
    private authModule: AuthProvider,
    private fetchProvider: FetchProvider
  ) {}

  public async init(): Promise<void> {
    if (!this.initialized) await this.fetchUser();
  }

  public getEmail(): string | undefined {
    return this.currentUser?.email;
  }

  public getPhone(): string | undefined {
    return this.currentUser?.phone;
  }

  public getLastLoginTimestamp(): string | undefined {
    return this.currentUser?.last_login;
  }

  async createUser(user: BaseUser, deviceId: string): Async<Response> {
    const createUserRequest = await this.fetchProvider.post(
      `${this.config.endpoint}/user/${deviceId}`,
      user
    );

    if (createUserRequest.wasSuccessful()) {
      await this.fetchUser();
      return new Response(true, "User created successfully");
    } else {
      return new Response(false, createUserRequest.getMessage());
    }
  }

  async deleteUser(): Async<Response> {
    const userId = this.currentUser?.id;
    if (userId !== undefined) {
      const deleteUserRequest = await this.fetchProvider.delete(
        `${this.config.endpoint}/user/${userId}`,
        {}
      );

      if (deleteUserRequest.wasSuccessful()) {
        this.currentUser = undefined;
        return new Response(true, "User deleted successfully");
      } else {
        return new Response(false, deleteUserRequest.getMessage());
      }
    }

    return new Response(false, "Error deleting user");
  }

  async updateEmail(newEmail: string): Async<Response> {
    return this.updateUser({ email: newEmail });
  }

  async updatePassword(currentPassword: string, newPassword: string): Async<Response> {
    const userId = this.currentUser?.id;

    if (userId !== undefined) {
      const updateUserPasswordRequest = await this.fetchProvider.put(
        `${this.config.endpoint}/user-password`,
        {
          changePasswordInformation: {
            userId,
            currentPassword,
            newPassword,
          },
        }
      );

      if (updateUserPasswordRequest.wasSuccessful()) {
        return new Response(true, "Password updated successfully");
      } else {
        return new Response(false, updateUserPasswordRequest.getMessage());
      }
    }

    return new Response(false, "Error updating password");
  }

  async updatePhone(newPhone: string): Async<Response> {
    return this.updateUser({ phone: newPhone });
  }

  private async fetchUser(): Promise<void> {
    const userRequest = await this.fetchProvider.get<{ user: User }>(
      `${this.config.endpoint}/user`
    );
    if (userRequest.wasSuccessful()) this.currentUser = userRequest.getData()?.user;
  }

  private async updateUser(updatedUser: Partial<BaseUser>): Async<Response> {
    if (this.currentUser) {
      const updateUserRequest = await this.fetchProvider.put<{ user: BaseUser }, string>(
        `${this.config.endpoint}/user`,
        {
          user: {
            ...this.currentUser,
            ...updatedUser,
            id: this.currentUser.id,
          },
        }
      );

      if (updateUserRequest.wasSuccessful()) {
        await this.fetchUser();
        return new Response(true, "User updated successfully");
      } else {
        return new Response(false, updateUserRequest.getMessage());
      }
    }

    return new Response(false, "Error updating user");
  }
}
