<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="isOpen"
    app
    clipped
    :mobile-breakpoint="768"
    :expand-on-hover="isDesktop"
  >
    <v-list dense>
      <v-list-item
        v-for="menuItem of menuItems"
        :key="menuItem.url"
        :to="menuItem.url"
        active-class="primary darken-3"
        class="v-list-item"
      >
        <v-list-item-action>
          <v-icon>{{ menuItem.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import { uiEventBus, UIEventID } from "@/events/ui.channel";

type MenuItem = {
  url: string;
  icon: string;
  title: string;
};

export default Vue.extend({
  data: () => ({
    opened: false,
    logo: "favicon.ico",
  }),
  computed: {
    ...mapGetters("messages", ["unread"]),

    menuItems(): MenuItem[] {
      return [
        {
          url: "/",
          icon: "mdi-view-dashboard",
          title: this.$t("ui.drawer.dashboard.title") as string,
        },
        {
          url: "/device",
          icon: "mdi-router-wireless-settings",
          title: this.$t("ui.drawer.device.title") as string,
        },
        {
          url: "/vehicle",
          icon: "mdi-car-connected",
          title: this.$t("ui.drawer.vehicle.title") as string,
        },
        {
          url: "/me",
          icon: "mdi-account",
          title: this.$t("ui.drawer.me.title") as string,
        },
      ];
    },

    isDesktop(): boolean {
      return this.$vuetify.breakpoint.mdAndUp;
    },

    isOpen: {
      get(): boolean {
        return this.isDesktop || this.opened;
      },
      set(openStatus: boolean) {
        this.opened = openStatus;
      },
    },

    enableExpandOnHover(): boolean {
      return this.isDesktop;
    },
  },
  methods: {
    handleToggleMenuBar() {
      this.opened = !this.opened;
    },
    subscribeToEvents(): void {
      uiEventBus.subscribe(UIEventID.TOGGLE_MENU, this.handleToggleMenuBar);
    },
    unsubscribeFromEvents(): void {
      uiEventBus.unsubscribe(UIEventID.TOGGLE_MENU, this.handleToggleMenuBar);
    },
  },
  mounted() {
    this.subscribeToEvents();
  },
  beforeDestroy() {
    this.unsubscribeFromEvents();
  },
});
</script>

<style></style>
