<template>
  <v-row justify="center">
    <v-dialog :value="showDialog" persistent scrollable max-width="700px">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <div v-text="$t('ui.vehicle.dialog.share.description')" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <h2 v-text="$t('ui.vehicle.dialog.share.list')"></h2>
              </v-col>
            </v-row>
            <!-- share line -->
            <v-row v-for="share in shares" :key="share.id">
              <v-col cols="5" xs="5" sm="5" md="5" lg="5">
                {{ share.user_email }}
              </v-col>
              <v-col cols="2" xs="2" sm="2" md="2" lg="2">
                {{ createdString(share) }}
              </v-col>
              <v-col cols="3" xs="3" sm="3" md="3" lg="3">
                <v-icon small>
                  {{ shareViewSessionsCheckboxIcon(share) }}
                </v-icon>
                {{ $t("ui.vehicle.dialog.share.shareSessions") }}
              </v-col>
              <v-col cols="2" xs="2" sm="2" md="2" lg="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="deleteShare(share)" v-on="on" color="error" text>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("ui.vehicle.dialog.share.delete") }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row v-if="shares.length === 0">
              <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                <div v-text="$t('ui.vehicle.dialog.share.emptyList')" />
              </v-col>
            </v-row>
          </v-container>
          <!-- share form -->
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                  <h2 v-text="$t('ui.vehicle.dialog.share.addNew')"></h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <v-text-field
                    v-model="formData.serviceEmail"
                    :label="$t('ui.vehicle.dialog.share.serviceEmail')"
                    placeholder=""
                    required
                    hide-details
                    prepend-icon="email"
                    :rules="[required, emailValid]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <v-checkbox
                    v-model="formData.shareSessions"
                    hide-details
                    :label="$t('ui.vehicle.dialog.share.shareSessions')"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                  <div v-text="$t('ui.vehicle.dialog.share.legal')" />

                  <div class="legal-information-text">
                    <hoots-datenschutzerklaerung2021 />
                  </div>

                  <v-checkbox
                    v-model="formData.confirmLegal"
                    hide-details
                    :label="$t('ui.vehicle.dialog.share.confirmLegal')"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                  <v-btn
                    color="blue darken-1"
                    @click="addShare"
                    :disabled="!formData.confirmLegal"
                    text
                    >{{ $t("ui.action.addShare") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="close" text>{{ $t("ui.action.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import { mapState } from "vuex";

import Datenschutzerklaerung2021 from "@/components/Datenschutzerklaerung2021";

export default {
  name: "VehicleSharing",
  props: ["showDialog", "title", "vehicleItem", "vehicleItemIndex"],
  components: {
    "hoots-datenschutzerklaerung2021": Datenschutzerklaerung2021,
  },
  data() {
    return {
      valid: false,
      error: false,
      formData: {
        serviceEmail: "",
        shareSessions: false,
        confirmLegal: false,
      },
      emailRegExp:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  },

  watch: {
    showDialog(val) {
      if (val === true) {
        this.loadShares(this.vehicleItem);
      }
    },
  },

  computed: {
    ...mapState("vehicle", ["shares"]), // shares property from vehicle store module
    required() {
      return (value) => !!value || this.$t("ui.rule.required");
    },
    emailValid() {
      return (value) => this.emailRegExp.test(value) || this.$t("ui.rule.emailInvalid");
    },
  },

  methods: {
    loadShares(vehicle) {
      this.$store
        .dispatch("vehicle/shares", vehicle.id)
        .then(() => {
          this.error = false;
        })
        .catch(() => {
          this.error = true;
        });
    },

    addShare() {
      const shareData = {
        type: this.formData.shareSessions ? "edit_view_sessions" : "edit",
        vehicle_id: this.vehicleItem.id,
        user_email: this.formData.serviceEmail,
      };
      this.$store
        .dispatch("vehicle/createShare", {
          share: shareData,
          vehicleItemIndex: this.vehicleItemIndex,
        })
        .then(() => {
          this.error = false;
          this.resetForm();
        })
        .catch(() => {
          this.error = true;
        });
    },

    deleteShare(share) {
      this.$store
        .dispatch("vehicle/deleteShare", {
          share,
          vehicleItemIndex: this.vehicleItemIndex,
        })
        .then(() => {
          this.error = false;
        })
        .catch(() => {
          this.error = true;
        });
    },

    resetForm() {
      this.formData = {
        serviceEmail: "",
        shareSessions: false,
        confirmLegal: false,
      };
    },

    close() {
      this.resetForm();
      this.$emit("close");
    },

    createdString(share) {
      if (share.created_at === undefined) {
        return "-";
      }

      return new Date(share.created_at).toLocaleString(this.locale, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    },

    shareViewSessionsCheckboxIcon(share) {
      if (share.type === "edit") {
        return "mdi-checkbox-blank-outline";
      } else if (share.type === "edit_view_sessions") {
        return "mdi-checkbox-marked";
      }
    },
  },
};
</script>

<style scoped>
.legal-information-text {
  border: 1px solid #ffffff;
  height: 105px;
  overflow-y: scroll;
}
</style>
