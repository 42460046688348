<template>
  <v-dialog :value="showDialog" persistent scrollable max-width="700">
    <v-card>
      <v-card-title class="headline">{{ $t("ui.dialog.delete.title") }}</v-card-title>
      <v-container>
        <v-card-text style="height: 100px">
          {{ $t("ui.dialog.delete.text") }}
        </v-card-text>
        <v-divider></v-divider>
        <v-checkbox v-model="confirm_delete" :label="confirm1Label"></v-checkbox>
        <v-checkbox v-model="confirm_delete_2" :label="confirm2Label"></v-checkbox>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" @click="abortDelete">{{ $t("ui.action.cancel") }}</v-btn>
        <v-btn
          color="red darken-3"
          @click="deleteAccount"
          :disabled="!(confirm_delete && confirm_delete_2)"
          >{{ $t("ui.action.delete") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DeleteAccount",
  props: ["showDialog"],
  data() {
    return {
      confirm_delete: false,
      confirm_delete_2: false,
      alert: false,
    };
  },

  computed: {
    confirm1Label() {
      return this.$t("ui.dialog.delete.confirm1");
    },
    confirm2Label() {
      return this.$t("ui.dialog.delete.confirm2");
    },
  },

  methods: {
    userInfo: function () {
      this.alert = true;
    },
    abortDelete: function () {
      this.confirm_delete = false;
      this.confirm_delete_2 = false;
      // emit close-event to parent component
      this.$emit("close");
    },
    deleteAccount: function () {
      if (window.location.hostname === "demo.hoots.online") {
        this.$emit("error", "Demo-Accounts können nicht gelöscht werden!");
      } else {
        this.confirm_delete = false;
        this.confirm_delete_2 = false;
        this.$store.dispatch("deleteUser").then(() => {
          this.$router.push("/login");
        });
      }
    },
  },
};
</script>
