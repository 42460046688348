<template>
  <v-row align="start" justify="start">
    <v-col cols="12" xs="6" sm="3" md="3" lg="2">
      <complex-button
        step="0"
        icon="mdi-calendar"
        :title="monthSessionCountCardTitle"
        :value="monthSessionCount.toFixed(0)"
        :tooltip="monthSessionCountTooltip"
        @triggered="openSessionPicker"
        :ready="loading"
      />
    </v-col>

    <v-col cols="12" xs="6" sm="3" md="3" lg="2">
      <complex-button
        step="1"
        icon="mdi-car"
        :title="vehiclesInFleetCardTitle"
        :value="numVehiclesInFleet.toFixed(0)"
        :tooltip="vehiclesInFleetTooltip"
        @triggered="openVehiclePicker"
        :ready="loading"
      />
    </v-col>

    <v-col cols="12" xs="6" sm="3" md="3" lg="2">
      <complex-button
        icon="mdi-router-wireless"
        :title="lastLiveDateCardTitle"
        :value="lastLiveDateCardValue"
        :tooltip="lastLiveDateTooltipValue"
        :ready="loading"
      />
    </v-col>

    <v-col cols="12" xs="6" sm="3" md="3" lg="2">
      <complex-button
        icon="mdi-gauge"
        :title="totalMileageCardTitle"
        :value="totalMileageInKm.toFixed(0)"
        unit="km"
        :tooltip="totalMileageTooltip"
        :ready="loading"
      />
    </v-col>

    <v-col cols="12" xs="6" sm="3" md="3" lg="2">
      <complex-button
        icon="mdi-road"
        :title="averageTripDistanceCardTitle"
        :value="averageTripDistanceInKm.toFixed(0)"
        unit="km"
        :tooltip="averageTripDistanceCardTooltip"
        :ready="loading"
      />
    </v-col>

    <v-col cols="12" xs="6" sm="3" md="3" lg="2">
      <complex-button
        icon="mdi-bus-clock"
        :title="averageDurationPerTripTitle"
        :value="averageDurationPerTripInMin.toFixed(0)"
        unit="min"
        :progress="28"
        :tooltip="averageDurationPerTripTooltip"
        :ready="loading"
      />
    </v-col>
  </v-row>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import ComplexButton from "@/components/ComplexButton.vue";
import i18n from "@/plugins/i18n.plugin";

export default Vue.extend({
  name: "OverviewCards",
  components: { ComplexButton },
  props: {
    loading: {
      type: Boolean,
    },
    monthSessionCount: {
      type: Number,
      required: true,
    },
    numVehiclesInFleet: {
      type: Number,
      required: true,
    },
    lastLiveDate: {
      type: Date as PropType<Date | null>,
      required: false,
    },
    totalMileageInKm: {
      type: Number,
      required: true,
    },
    averageTripDistanceInKm: {
      type: Number,
      required: true,
    },
    averageDurationPerTripInMin: {
      type: Number,
      required: true,
    },
  },
  computed: {
    vehiclesInFleetCardTitle(): string {
      return (
        (this.numVehiclesInFleet === 1
          ? i18n.t("ui.dashboard.vehicle")
          : i18n.t("ui.dashboard.vehicles")) +
        " " +
        i18n.t("ui.dashboard.inFleet")
      );
    },
    vehiclesInFleetTooltip(): string {
      return i18n.t("ui.dashboard.tooltip.vehicle") as string;
    },
    monthSessionCountCardTitle(): string {
      return (
        i18n.t("ui.dashboard.tripsIn") +
        " " +
        new Date().toLocaleString(i18n.locale, { month: "short" }) +
        " " +
        new Date().getFullYear()
      );
    },
    monthSessionCountTooltip(): string {
      return i18n.t("ui.dashboard.tooltip.trip") as string;
    },
    lastLiveDateCardTitle(): string {
      return i18n.t("ui.dashboard.lastLiveDate") as string;
    },
    lastLiveDateCardValue(): string {
      return this.lastLiveDate === null
        ? "-"
        : this.lastLiveDate.toLocaleString(i18n.locale, {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          });
    },
    lastLiveDateTooltipValue(): string {
      return this.lastLiveDate === null ? "-" : this.lastLiveDate.toLocaleString(i18n.locale);
    },
    totalMileageCardTitle(): string {
      return i18n.t("ui.dashboard.completedDistance") as string;
    },
    totalMileageTooltip(): string {
      return i18n.t("ui.dashboard.tooltip.totalDistance") as string;
    },
    averageTripDistanceCardTitle(): string {
      return i18n.t("ui.dashboard.distancePerTrip") as string;
    },
    averageTripDistanceCardTooltip(): string {
      return i18n.t("ui.dashboard.tooltip.avgDistance") as string;
    },
    averageDurationPerTripTitle(): string {
      return i18n.t("ui.dashboard.durationPerTrip") as string;
    },
    averageDurationPerTripTooltip(): string {
      return i18n.t("ui.dashboard.tooltip.avgDuration") as string;
    },
  },
  methods: {
    openSessionPicker(): void {
      this.$emit("openSessionPicker");
    },
    openVehiclePicker(): void {
      this.$emit("openVehiclePicker");
    },
  },
});
</script>
