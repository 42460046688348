import { FetchProvider, FetchOptions } from "@/service/fetch/fetch.interface";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Response } from "@/@types/helper/api.types";

type AxiosRequest = () => Promise<AxiosResponse>;

type GRPCError = {
  code: number;
  message: string;
};

function isAxiosError(error: AxiosError | unknown): error is AxiosError {
  return axios.isAxiosError(error);
}

function isGRPCError(error: GRPCError | unknown | undefined): error is GRPCError {
  return (
    !!error &&
    typeof error === "object" &&
    error.hasOwnProperty("code") &&
    error.hasOwnProperty("message")
  );
}

async function sendAsyncRequest<ReturnType>(
  asyncCall: AxiosRequest
): Promise<Response<ReturnType>> {
  try {
    const response = await asyncCall();
    return new Response<ReturnType>(true, undefined, response.data);
  } catch (error: unknown | undefined) {
    let message: string;

    if (!!error && isAxiosError(error) && isGRPCError(error.response?.data)) {
      message = `Code: ${error.response?.data.code}: ${error.response?.data.message}`;
    } else {
      message = (error as Error | undefined)?.message || "Unknown error";
    }

    return new Response(false, message);
  }
}

export class AxiosProvider implements FetchProvider {
  addHTTPHeaderField(fieldID: string, value: string): void {
    axios.defaults.headers.common[fieldID] = value;
  }

  removeHTTPHeaderField(fieldID: string): void {
    delete axios.defaults.headers.common[fieldID];
  }

  async get<ReturnType>(url: string, options: FetchOptions = {}): Promise<Response<ReturnType>> {
    return await sendAsyncRequest(() => axios.get<ReturnType>(url, options));
  }

  async post<SendType, ReturnType>(
    url: string,
    data: SendType,
    options: FetchOptions = {}
  ): Promise<Response<ReturnType>> {
    return await sendAsyncRequest(() => axios.post<ReturnType>(url, data, options));
  }

  async put<SendType, ReturnType>(
    url: string,
    data: SendType,
    options: FetchOptions = {}
  ): Promise<Response<ReturnType>> {
    return await sendAsyncRequest(() => axios.put<ReturnType>(url, data, options));
  }

  async delete<ReturnType>(url: string, options: FetchOptions = {}): Promise<Response<ReturnType>> {
    return await sendAsyncRequest(() => axios.delete<ReturnType>(url, options));
  }
}
