<template>
  <v-toolbar dark dense flat>
    <v-text-field
      prepend-inner-icon="mdi-road"
      hide-details
      solo
      readonly
      :label="sessionDistance"
    ></v-text-field>
    <v-text-field
      hide-details
      prepend-inner-icon="mdi-calendar"
      solo
      readonly
      :label="sessionTripDate"
    ></v-text-field>
    <v-text-field
      hide-details
      prepend-inner-icon="fa-chevron-right"
      solo
      readonly
      :label="sessionTripTimeStarted"
    ></v-text-field>
    <v-text-field
      hide-details
      prepend-inner-icon="fa-flag-checkered"
      solo
      readonly
      :label="sessionTripTimeFinished"
    ></v-text-field>
  </v-toolbar>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import format from "date-fns/format";
import fromUnixTime from "date-fns/fromUnixTime";
import { Session } from "@/@types/data/session.types";

export default Vue.extend({
  name: "hoots-map-trip-summary-bar",
  props: {
    activeSession: {
      type: Object as PropType<Session>,
      required: false
    },
  },
  computed: {
    sessionDistance(): string {
      return this.activeSession !== undefined
        ? this.activeSession.distance.toFixed(1) + " km"
        : "";
    },

    sessionTripDate(): string {
      return this.activeSession?.started_at !== undefined
        ? format(fromUnixTime(this.activeSession.started_at), "dd.MM.yyyy")
        : "";
    },

    sessionTripTimeStarted(): string {
      return this.activeSession?.started_at !== undefined
        ? format(fromUnixTime(this.activeSession.started_at), "HH:mm")
        : "";
    },

    sessionTripTimeFinished(): string {
      return this.activeSession?.finished_at !== undefined
        ? format(fromUnixTime(this.activeSession.finished_at), "HH:mm")
        : "";
    },
  }
});
</script>
