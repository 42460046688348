<template>
  <v-layout row justify-center>
    <v-form v-model="valid" class="px-3">
      <v-dialog :value="showDialog" persistent scrollable max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("ui.dialog.password.title") }}</v-card-title>
          <v-form>
            <v-container>
              <v-flex xs12>
                <input
                  type="text"
                  name="email"
                  :value="username"
                  autocomplete="username email"
                  style="display: none"
                />
                <v-text-field
                  v-model="currentPassword"
                  :append-icon="showCurrentPw ? 'visibility' : 'visibility_off'"
                  :label="$t('ui.dialog.password.label.current')"
                  :type="showCurrentPw ? 'text' : 'password'"
                  prepend-icon="lock"
                  required
                  autocomplete="current-password"
                  @click:append="showCurrentPw = !showCurrentPw"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="newPassword"
                  :append-icon="show1 ? 'visibility' : 'visibility_off'"
                  :label="$t('ui.dialog.password.label.new')"
                  :type="show1 ? 'text' : 'password'"
                  :rules="[required, passwordValid]"
                  prepend-icon="lock"
                  required
                  autocomplete="off new-password"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="confirmNewPassword"
                  :append-icon="show2 ? 'visibility' : 'visibility_off'"
                  :type="show2 ? 'text' : 'password'"
                  :rules="[required, match]"
                  :label="$t('ui.dialog.password.label.confirm')"
                  prepend-icon="verified_user"
                  required
                  autocomplete="off new-password"
                  @click:append="show2 = !show2"
                ></v-text-field>
              </v-flex>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="cancel">{{ $t("ui.action.cancel") }}</v-btn>
            <v-btn color="green darken-1" @click="changePassword" :disabled="!valid">{{
              $t("ui.action.confirm")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="error"
      :timeout="6000"
      :bottom="true"
      :multi-line="true"
      color="red darken-2"
      >{{ errortext }}</v-snackbar
    >
  </v-layout>
</template>

<script lang="ts">
import Vue from "vue";

import { isPasswordValid, PASSWORD_VALIDATION_ERROR } from "@/validators/auth.validators";
import {
  convertErrorCheckerOutputToStrings,
  ErrorStringMap,
} from "@/validators/generic.validators";
import i18n from "@/plugins/i18n.plugin";

export default Vue.extend({
  name: "ChangePassword",
  props: ["showDialog"],
  data() {
    return {
      dialog: false,
      showCurrentPw: false,
      show1: false,
      show2: false,
      valid: false,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",

      error: false,
      errortext: "",
    };
  },
  computed: {
    username(): string {
      return this.$user.getEmail() || "";
    },

    required(): (value: string) => true | string {
      return (value) => !!value || (this.$t("ui.rule.required") as string);
    },
    passwordValid(): (password: string) => string | true {
      const passwordErrorToStringMap: ErrorStringMap<PASSWORD_VALIDATION_ERROR> = {
        [PASSWORD_VALIDATION_ERROR.TOO_LONG]: String(i18n.t("ui.rule.length80")),
        [PASSWORD_VALIDATION_ERROR.TOO_SHORT]: String(i18n.t("ui.rule.passwordMin")),
        [PASSWORD_VALIDATION_ERROR.NO_REGEX_MATCH]: String(i18n.t("ui.rule.passwordPolicy")),
      };
      return (password: string) =>
        convertErrorCheckerOutputToStrings(isPasswordValid(password), passwordErrorToStringMap);
    },
    match(): (newPasswordConfirm: string) => true | string {
      return (value) => value === this.newPassword || (this.$t("ui.rule.mismatch") as string);
    },
  },

  async mounted() {
    await this.$user.init();
  },

  methods: {
    cancel() {
      this.$emit("close");
    },

    async changePassword() {
      if (window.location.hostname !== "demo.hoots.online") {
        try {
          await this.$user.updatePassword(this.currentPassword, this.newPassword);
          this.$emit("close");
        } catch (err) {
          this.$emit("error", (err as Error).message);
        }
      }
    },
  },
});
</script>
