import Dashboard from "@/views/DashboardView.vue";
import Me from "@/views/UserView.vue";
import Map from "@/views/app/AppMap.vue";
import Device from "@/views/dashboard/device/DeviceView.vue";
import Login from "@/views/LoginView.vue";
import Vehicle from "@/views/VehicleView.vue";
import Welcome from "@/views/WelcomeView.vue";
import PasswordReset from "@/views/PasswordReset.vue";
import HTTP404 from "@/views/HTTP404.vue";

export const routes = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    props: {},
    meta: {
      requiresAuth: true,
      toolbarVisible: true,
      sidebarVisible: true,
    },
  },
  {
    path: "/me",
    name: "me",
    component: Me,
    meta: {
      requiresAuth: true,
      toolbarVisible: true,
      sidebarVisible: true,
    },
  },
  {
    path: "/map",
    name: "map",
    component: Map,
    meta: {
      requiresAuth: true,
      toolbarVisible: false,
      sidebarVisible: false,
    },
  },
  {
    path: "/device",
    name: "device",
    component: Device,
    meta: {
      requiresAuth: true,
      toolbarVisible: true,
      sidebarVisible: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      toolbarVisible: false,
      sidebarVisible: false,
    },
  },
  {
    path: "/vehicle",
    name: "vehicle",
    component: Vehicle,
    meta: {
      requiresAuth: true,
      toolbarVisible: true,
      sidebarVisible: true,
    },
  },
  {
    path: "/welcome",
    name: "welcome",
    component: Welcome,
    meta: {
      requiresAuth: false,
      toolbarVisible: false,
      sidebarVisible: false,
    },
  },
  {
    path: "/password/reset/:token",
    name: "passwordreset",
    component: PasswordReset,
    props: true,
    meta: {
      toolbarVisible: false,
      sidebarVisible: false,
    },
  },
  {
    path: "/messagecenter",
    name: "messagecenter",
    component: () => import("@/views/MessageCenter.vue"),
    meta: {
      requiresAuth: true,
      toolbarVisible: true,
      sidebarVisible: true,
    },
  },
  {
    path: "/trips/:tripID?",
    name: "trips",
    component: () => import("@/views/dashboard/trips/TripsView.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      toolbarVisible: true,
      sidebarVisible: true,
    },
  },
  {
    path: "*",
    component: HTTP404,
    meta: {
      toolbarVisible: false,
      sidebarVisible: false,
    },
  },
];
