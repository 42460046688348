import { Device, DeviceIDInt } from "@/@types/data/device.types";

function padNumberToLength(num: number, stringLength: number): string {
  let numberString = num.toString();
  while (numberString.length < stringLength) numberString = "0" + numberString;
  return numberString;
}

export function convertDeviceIDToStringForm(
  deviceIDInt: DeviceIDInt
): Device["id"] {
  const deviceSeries = parseInt(deviceIDInt.toString()[0]);
  const deviceNumber = deviceIDInt % 10000;

  const deviceSeriesString = padNumberToLength(deviceSeries, 3);
  const deviceNumberString = padNumberToLength(deviceNumber, 5);

  return `${deviceSeriesString}-${deviceNumberString}`;
}

export function convertDeviceIDToNumberForm(deviceID: string): number {
  const [deviceSeriesString, deviceNumberString] = deviceID.split("-");
  const deviceSeries = parseInt(deviceSeriesString) * 100_000;
  const deviceNumber = parseInt(deviceNumberString);
  return deviceSeries + deviceNumber;
}
