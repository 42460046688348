import { SynchronousStorageInterface } from "@/service/storage/storage.interface";

export class LocalStorageProvider
  implements SynchronousStorageInterface<string, string>
{
  addItem(id: string, item: string) {
    localStorage.setItem(id, item);
  }

  getItem(id: string): string | null {
    return localStorage.getItem(id);
  }

  deleteItem(id: string) {
    localStorage.removeItem(id);
  }

  doesItemExist(id: string): boolean {
    return localStorage.getItem(id) != null;
  }
}
