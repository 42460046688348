<template>
  <div>
    <p class="western" align="center" style="margin-bottom: 0in">
      <font face="Verdana, serif"
        ><font size="3" style="font-size: 12pt"><b>Erklärung zum Datenschutz</b></font></font
      >
    </p>
    <p class="western" align="center" style="margin-bottom: 0in">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >im Zusammenhang mit der Nutzung der Ihres
        </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"><i>hoots one Systems</i></font></font
      >
    </p>
    <p class="western" align="center" style="margin-bottom: 0in">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          ><i>(„hoots one box“, „hoots online“, „hoots GPS“</i></font
        ></font
      ><font face="Verdana, serif"><font size="2" style="font-size: 9pt">)</font></font>
    </p>
    <p class="western" align="center" style="margin-bottom: 0in">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >im Rahmen des Werkstatt-/Servicevertrages</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"><b>Überblick</b></font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Mit der nachfolgenden Erklärung möchten wir Sie über Art, Umfang und Zweck der Erhebung,
          Speicherung und Verwendung (Verarbeitung) Ihrer personenbezogenen und sonstigen Daten
          informieren, die wir im Zusammenhang mit der Nutzung Ihres
        </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"><i>hoots one Systems</i></font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">
          („hoots one box“, „hoots online“, „hoots GPS“) im Rahmen des zwischen uns bestehenden
          Werkstatt-/Servicevertrages erhalten.
        </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Gleichzeitig bitten wir Sie um Ihre Einwilligung, die nachfolgend beschriebenen Daten zu
          nutzen und zum Zweck der Durchführung des mit uns abgeschlossenen
          Werkstatt-/Servicevertrages zu verarbeiten.
        </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Um Ihnen die Funktionen des hoots one Systems zur Verfügung zu stellen</font
        ></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">
          (z.B. damit wir die bei Nutzung anfallenden Daten im hoots-online-Portal einsehen können)
          ist es zudem erforderlich, dass bestimmte
        </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">personenbezogenen und sonstige Daten</font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"> an den Hersteller (</font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >hoots classic GmbH, Sebnitzer Straße 53, 01099 Dresden)</font
        ></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">
          übermittelt und von diesem gemäß der jeweiligen Produktdatenschutzerklärung des
          Herstellers gespeichert und verarbeitet werden.
        </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Für die Datenverarbeitung durch die hoots classic GmbH gelten die jeweiligen
        </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Produktdatenschutzerklärungen des Herstellers</font
        ></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"> welche unter dem Link </font></font
      ><font color="#0000ff"
        ><u
          ><a href="https://hoots.de/datenschutzerklaerung-produkt/"
            ><font face="Verdana, serif"
              ><font size="2" style="font-size: 9pt"
                >https://hoots.de/datenschutzerklaerung-produkt/</font
              ></font
            ></a
          ></u
        ></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">
          oder bei uns jederzeit eingesehen werden können.
        </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"><b>I. </b></font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          ><b>Datenverarbeitung durch die Werkstatt bei der Nutzung Ihres </b></font
        ></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          ><i><b>hoots one Systems</b></i></font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"><u>1. </u></font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"><u>Verantwortliche Stelle</u></font></font
      ><font face="Verdana, serif"><font size="2" style="font-size: 9pt">: </font></font>
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der
          EU-Datenschutzgrundverordnung (DSGVO), ist:</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Die unter der freizugebenden Mailadresse erreichbare Werkstatt.</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"><u>2. Welche Daten verarbeiten wir ?</u></font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Zur Erfüllung unserer vertraglichen Pflichten aus dem
        </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Werkstatt-/Servicevertrag gestatten Sie uns auf folgende Daten zuzugreifen und diese zur
          Prüfung und Überwachung der technischen Funktionen sowie zur Diagnose Ihres
          Fahrzeugs</font
        ></font
      ><font face="Verdana, serif"><font size="2" style="font-size: 9pt"> </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">zu verarbeiten: </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="margin-left: 2.46in; text-indent: -2.46in; margin-bottom: 0in; line-height: 130%"
    >
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Persönliche Daten: Ihre Zugangsdaten zum Portal „hoots Online“
        </font></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="margin-left: 2.46in; margin-bottom: 0in; line-height: 130%"
    >
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >(Benutzername, E-Mailadresse, Zugangspasswort,</font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="margin-left: 2.46in; margin-bottom: 0in; line-height: 130%"
    >
      <font face="Verdana, serif"><font size="2" style="font-size: 9pt">Telefonnummer)</font></font>
    </p>
    <p
      class="western"
      align="justify"
      style="margin-left: 0.49in; text-indent: -0.49in; margin-bottom: 0in; line-height: 130%"
    >
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >allgemeine Fahrzeugdaten: Marke, Modellbezeichnung, Name</font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="margin-left: 2.46in; text-indent: -2.46in; margin-bottom: 0in; line-height: 130%"
    >
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >technische Fahrzeugdaten: Zylinderanzahl, Taktzahl, Verbrennungsprinzip, Baujahr, Hubraum
          und PS-Zahl Ihres Fahrzeugs</font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="margin-left: 2.46in; text-indent: -2.46in; margin-bottom: 0in; line-height: 130%"
    >
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Gerätedaten: Geräte-ID und Seriennummer der
        </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"><i>hoots one box</i></font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">, Seriennummer der </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"><i>hoots one Sensoren</i></font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >, IMEI und SIM-Nummer des GSM-Moduls, verwendete Softwareversionen.</font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="margin-left: 2.46in; text-indent: -2.46in; margin-bottom: 0in; line-height: 130%"
    >
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Betriebs- und Zustandsdaten: Drehzahl(en), Öltemperatur(en), Öldru(e)ck(e),</font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="margin-left: 2.46in; margin-bottom: 0in; line-height: 130%"
    >
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Batteriespannung(en), Kühlmitteltemperatur(en),</font
        ></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="margin-left: 2.46in; margin-bottom: 0in; line-height: 130%"
    >
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Bauteiltemperatur(en), Lambdawert(e), Hochtemperatur(en) Umgebungsbedingungen,
          Beschleunigungen
        </font></font
      >
    </p>
    <p
      class="western"
      align="justify"
      style="margin-left: 2.46in; text-indent: -2.46in; margin-bottom: 0in; line-height: 130%"
    >
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Positions- und Bewegungsdaten: Längen- und Breitengrad, Geschwindigkeit, Start/Ziel der
          Fahrt, Distanz, Datum, Beginn/Ende der Fahrt.
        </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          ><u>3. Zu welchem Zweck erfolgt die Datenverarbeitung/ Rechtsgrundlage: </u></font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Die Verarbeitung Ihrer Daten (Persönliche Daten, allgemeine und technische Fahrzeugdaten,
          Gerätedaten, Betriebs- und Zustandsdaten) erfolgt zur
        </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Prüfung und Überwachung der technischen Funktionen sowie zur Diagnose Ihres
          Fahrzeugs</font
        ></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">
          und damit zur Erfüllung unserer vertraglichen Pflichten aus dem
        </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">Werkstatt-/Servicevertrag </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">(Art. 6 Abs. 1 S. 1 lit. b. DSGVO)</font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >. Soweit in Ausnahmefällen eine Verarbeitung von
        </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Positions- und Bewegungsdaten erfolgt, erfolgt dies auf Basis ihrer mit dieser Erklärung
          erteilten Einwilligung (Art. 6 Abs. 1 lit. a) DSGVO).
        </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"><u>4. Empfänger der Daten:</u></font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">Empfänger der Daten ist die</font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="margin-left: 0.49in; text-indent: -0.49in; margin-bottom: 0in; line-height: 130%"
    >
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >- Verantwortliche Stelle sowie die von uns für den Betrieb unserer IT-Systeme
          beauftragten Auftragsverarbeiter (IT-Dienstleister).
        </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="margin-left: 0.49in; text-indent: -0.49in; margin-bottom: 0in; line-height: 130%"
    >
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >- hoots classic GmbH, Sebnitzer Straße 53, 01099 Dresden sowie deren
        </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >für den Betrieb ihrer IT-Systeme beauftragten Auftragsverarbeiter (IT-Dienstleister).
        </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Die jeweiligen IT-Dienstleister sind im Rahmen einer Auftragsverarbeitung gemäß Art. 28
          DSGVO strikt zur Einhaltung der (datenschutz-)rechtlichen Bestimmungen sowie an unsere
          sonstigen Weisungen gebunden und werden von uns regelmäßig kontrolliert. Einzelheiten zu
          den beauftragten Auftragsverarbeitern, deren Pflichten und die von diesen zum Schutz Ihrer
          Daten ergriffenen technischen- und organisatorischen Maßnahmen, stellen wir Ihnen auf
          Anfrage gern zur Verfügung.</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          ><u>5. Wie lange werden die Daten gespeichert ?</u></font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Die persönlichen Daten, allgemeine und technische Fahrzeug,- Geräte-, Sensor- Betriebs-
          und Zustandsdaten (sowie ggf. Positions- und Bewegungsdaten) bleiben gespeichert, soweit
          und solange dies zur Erbringung unserer vertraglichen Verpflichtungen Ihnen gegenüber
          erforderlich ist und werden danach gelöscht.
        </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" style="margin-bottom: 0in; line-height: 100%">
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="margin-bottom: 0in; line-height: 130%; page-break-before: always"
    >
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          ><u>6. Pflicht zur Bereitstellung der Daten:</u></font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Die Bereitstellung der persönlichen Daten sowie der
        </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">Betriebs- und Zustandsdaten</font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">
          ist weder gesetzlich noch vertraglich vorgeschrieben. Ebenso wenig besteht eine Pflicht,
          uns diese Daten zur Verfügung zu stellen. Eine Nichtbereitstellung Daten hätte jedoch zur
          Folge, dass wir den mit Ihnen geschlossenen
        </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">Werkstatt-/Servicevertrages</font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">
          nicht erfüllen können. Eine Nichtbereitstellung der übrigen Daten hätte keine
          Folgen.</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p
      class="western"
      align="justify"
      style="margin-left: 0.49in; text-indent: -0.49in; margin-bottom: 0in; line-height: 130%"
    >
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          ><b
            >II. Datenverarbeitung durch den Hersteller bei der Nutzung Ihres hoots one Systems</b
          ></font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Bei der Nutzung Ihres hoots one Systems werden je nach Umfang des von Ihnen gebuchten
          Leistungspaketes personenbezogene Daten und sonstige allgemeine und technische Fahrzeug,-
          Geräte-, Sensor- Betriebs- und Zustandsdaten (sowie ggf. Positions- und Bewegungsdaten),
          die Sie uns zur Durchführung des Vertrages mitgeteilt haben oder die vom hoots one System
          bei der Benutzung erfasst werden, an den Hersteller (</font
        ></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >hoots classic GmbH, Sebnitzer Straße 53, 01099 Dresden sowie deren
        </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >für den Betrieb ihrer IT-Systeme beauftragten Auftragsverarbeiter</font
        ></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">) übermittelt und </font></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >von diesem gemäß der jeweiligen Produktdatenschutzerklärung gespeichert und verarbeitet.
        </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Der Hersteller wird diese Daten verwenden, um uns und Ihnen die Nutzung des hoots one
          Systems zu ermöglichen und dessen Funktionen zur Verfügung zu stellen.</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Darüber hinaus werden die allgemeinen und technischen Fahrzeug,- Geräte-, Sensor-
          Betriebs- und Zustandsdaten vom Hersteller auch zum Zweck der Qualitätssicherung und
          statistischen Auswertung des Produkts verwendet, z.b. um hieraus fahrzeugtypen- und
          modellspezifische Statistiken und Analysen zu erstellen (Art. 6 Abs. 1 lit. f) DSGVO).
          Ggf. erhobene Positions- und Bewegungsdaten werden hierfür NICHT verwendet. Die
          Verarbeitung zur Qualitätssicherung bzw. Statistik- und Analysezwecken erfolgt zum Schutz
          Ihrer Privatsphäre ausschließlich anonymisiert, so dass aus den enthaltenen Daten keine
          Rückschlüsse auf Sie als Person gezogen werden können. Die anonymisierten Statistik- und
          Analysedaten können vom Hersteller ggf. an Dritte unter Einhaltung der geltenden
          datenschutzrechtlichen Bestimmungen weitergebenen werden.
        </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">Verantwortliche Stelle ist: </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">hoots classic GmbH</font></font
      >
    </p>
    <p class="western" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Sebnitzer Straße 53<br />
          01099 Dresden
        </font></font
      >
    </p>
    <p class="western" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          ><span lang="en-US">Tel.: 0049 (0) 351 810 810 27</span></font
        ></font
      >
    </p>
    <p class="western" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"><span lang="en-US">Email: </span></font></font
      ><font color="#0000ff"
        ><u
          ><a href="mailto:info@hoots.de"
            ><font face="Verdana, serif"
              ><font size="2" style="font-size: 9pt"
                ><span lang="en-US">info@hoots.de</span></font
              ></font
            ></a
          ></u
        ></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"><span lang="en-US">. </span></font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Für die Datenverarbeitung durch die hoots classic GmbH gelten die jeweiligen
          Produktdatenschutzerklärungen des Herstellers, welche auf der Webseite des Herstellers
        </font></font
      ><font color="#0000ff"
        ><u
          ><a href="https://hoots.de/datenschutzerklaerung-produkt/"
            ><font face="Verdana, serif"
              ><font size="2" style="font-size: 9pt"
                >https://hoots.de/datenschutzerklaerung-produkt/</font
              ></font
            ></a
          ></u
        ></font
      ><font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">
          oder bei uns jederzeit eingesehen werden können.</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          ><b>III. Angaben zum Datentransfer in EU-Drittstaaten</b></font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Soweit wir Sie in der Beschreibung unserer jeweiligen Datenverarbeitung nicht explizit
          darauf hingewiesen haben, ist eine Übermittlung Ihrer personenbezogenen Daten in
          EU-Drittländer nicht beabsichtigt.</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          ><b>IV. Angaben zur automatisierten Entscheidungsfindung (Profiling)</b></font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Wir verzichten auf eine automatisierte Entscheidungsfindung oder ein Profiling.</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"><b>V. Ihre Rechte</b></font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Ihre nachfolgenden Rechte können Sie gegenüber der Verantwortlichen Stelle jederzeit
          unentgeltlich geltend machen. Wenn Sie von Ihren nachfolgenden Rechten uns gegenüber
          Gebrauch machen wollen, können Sie sich an uns telefonisch, per E-Mail oder Brief unter
          unseren zu Beginn dieser Datenschutzerklärung aufgeführten Kontaktdaten wenden.
        </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <br />
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">1. Auskunftsrecht:</font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob wir Sie
          betreffende personenbezogene Daten verarbeitet haben und das Recht auf Auskunft über diese
          personenbezogenen Daten und weitere Informationen gemäß Art. 15 DSGVO. Unter bestimmten
          Voraussetzungen kann Ihr Auskunftsrecht gemäß der gesetzlichen Vorgaben, insbesondere nach
          § 34 BDSG eingeschränkt sein.</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">2. Recht auf Berichtigung:</font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Sie haben das Recht, von uns die Berichtigung Sie betreffender unrichtiger bzw.
          unvollständiger personenbezogener Daten gemäß Art. 16 DSGVO zu verlangen.</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">3. Recht auf Löschung: </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Sie haben das Recht bei Vorliegen der in Art. 17 DSGVO genannten Voraussetzungen, die
          Löschung Ihrer Daten zu verlangen. Danach können Sie beispielsweise die Löschung Ihrer
          Daten verlangen, soweit diese für die Zwecke, für die sie erhoben wurden, nicht mehr
          notwendig sind. Außerdem können Sie Löschung verlangen, wenn wir Ihre Daten auf der
          Grundlage Ihrer Einwilligung verarbeiten und Sie diese Einwilligung widerrufen.</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >4. Recht auf Einschränkung der Verarbeitung:
        </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Sie haben das Recht die Einschränkung der Verarbeitung der Sie betreffenden Daten zu
          verlangen, wenn die Voraussetzungen des Art. 18 DSGVO vorliegen. Dies ist beispielsweise
          der Fall, wenn Sie die Richtigkeit Ihrer Daten bestreiten. Für die Dauer der Überprüfung
          der Richtigkeit der Daten können Sie dann die Einschränkung der Verarbeitung
          verlangen.</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt">5. Recht auf Datenübertragbarkeit: </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Sofern die Datenverarbeitung auf der Grundlage einer Einwilligung oder einer
          Vertragserfüllung beruht und diese zudem unter Einsatz einer automatisierten Verarbeitung
          erfolgt, haben Sie das Recht gemäß Art. 20 DSGVO, Ihre Daten in einem strukturierten,
          gängigen und maschinenlesbaren Format zu erhalten und diese an einen Anderen zu
          übermitteln.</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >6. Widerspruchsrecht nach Art. 21 DSGVO:
        </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art.
          6 Abs. S. 1 lit. f) DSGVO verarbeitet werden haben Sie gemäß Art. 21 DSGVO das Recht,
          Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, wenn dafür
          Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben. Soweit sich Ihr
          Widerspruch gegen die Verarbeitung Ihrer Daten zu Zwecken der Direktwerbung richtet,
          besteht das Widerrufsrecht unbeschränkt. Das gilt auch für Profiling, soweit es mit der
          Direktwerbung zusammenhängt. In diesem Fall werden wir die Daten ohne Ihre vorherige
          Angabe zu einer besonderen Situation nicht mehr für diesen Zweck verarbeiten.
        </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >7. Widerrufsrecht nach Art. 7 Abs. 3 DSGVO:
        </font></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Sofern die Datenverarbeitung auf einer von Ihnen erteilten Einwilligung beruht, haben Sie
          das Recht, die Datenverarbeitung im Rahmen einer Einwilligung mit Wirkung für die Zukunft
          jederzeit kostenlos widerrufen. Durch den Widerruf wird die Rechtmäßigkeit unserer
          Datenverarbeitung, die aufgrund Ihrer Einwilligung bis zum Eingang des Widerrufs erfolgt
          ist, nicht berührt. Nach Eingang des Widerrufs wird die Datenverarbeitung, die
          ausschließlich auf Ihrer Einwilligung beruhte, eingestellt.</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Sie haben gemäß Art. 77 DSGVO das Recht, sich bei einer zuständigen Aufsichtsbehörde (z.
          B. beim Landesbeauftragten für den Datenschutz) über unsere Verarbeitung Ihrer Daten zu
          beschweren. Eine Liste der Aufsichtsbehörden mit Anschrift finden Sie unter:</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          ><b>VI. Fragen, Stand und Aktualisierung dieser Datenschutzerklärung</b></font
        ></font
      >
    </p>
    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Für Fragen zu sämtlichen Angelegenheiten der Verarbeitung Ihrer personenbezogenen Daten,
          insbesondere im Zusammenhang mit den von uns eingesetzten Auftragsverarbeitern und
          Dienstleistern, können Sie uns jederzeit unter den zu Beginn der Datenschutzerklärung
          angegebenen Daten kontaktieren.</font
        ></font
      >
    </p>

    <p class="western" align="justify" style="margin-bottom: 0in; line-height: 130%">
      <font face="Verdana, serif"
        ><font size="2" style="font-size: 9pt"
          >Diese Datenschutzerklärung hat den Stand vom September 2021. Wir behalten uns vor, die
          Datenschutzerklärung zu gegebener Zeit zu aktualisieren, um den Datenschutz zu verbessern
          und/oder an geänderte Behördenpraxis oder Rechtsprechung anzupassen.</font
        ></font
      >
    </p>

    <div>
      <p class="western" align="center">
        <font face="Verdana, serif"
          ><font size="2" style="font-size: 9pt"
            ><u><b>Einwilligungserklärung in die Datennutzung</b></u></font
          ></font
        >
      </p>
      <p class="western" align="justify">
        <font face="Verdana, serif"
          ><font size="2" style="font-size: 9pt"
            >Wenn Sie mit der vorstehenden Nutzung Ihrer Daten einverstanden sind, kreuzen Sie diese
            bitte entsprechend an.
          </font></font
        >
      </p>

      <p class="western" align="justify">
        <font face="MS Gothic, serif"><font size="2" style="font-size: 9pt">[ ]</font></font
        ><font face="Verdana, serif"
          ><font size="2" style="font-size: 9pt">
            Ich willige ein, dass die unter der angegebenen Mailadresse erreichbare Werkstatt</font
          ></font
        >
      </p>
      <p class="western" align="justify">
        <font face="Verdana, serif"
          ><font size="2" style="font-size: 9pt">
            meine personenbezogenen Daten, allgemeine und technische Fahrzeug,- Geräte-, Sensor-
            Betriebs- und Zustandsdaten (sowie ggf. Positions- und Bewegungsdaten) im
            vorbeschriebenen Umfang selbst nutzen und verarbeiten darf und erteile hierzu meine
            widerrufliche Einwilligung.</font
          ></font
        >
      </p>
      <p
        class="western"
        align="justify"
        style="
          margin-left: 0.49in;
          text-indent: -0.49in;
          margin-bottom: 0in;
          padding: 0.01in 0.06in;
          line-height: 130%;
        "
      >
        <font face="MS Gothic, serif"><font size="2" style="font-size: 9pt">[ ] </font></font
        ><font face="Verdana, serif"
          ><font size="2" style="font-size: 9pt">Ich willige ein, dass </font></font
        ><font face="Verdana, serif"
          ><font size="2" style="font-size: 9pt">meine personenbezogenen Daten, </font></font
        ><font face="Verdana, serif"
          ><font size="2" style="font-size: 9pt"
            >allgemeine und technische Fahrzeug,- Geräte-, Sensor- Betriebs- und Zustandsdaten
            (sowie ggf. Positions- und Bewegungsdaten)</font
          ></font
        ><font face="Verdana, serif"
          ><font size="2" style="font-size: 9pt"
            >, die im Zusammenhang mit der Nutzung des
          </font></font
        ><font face="Verdana, serif"
          ><font size="2" style="font-size: 9pt">hoots one Systems</font></font
        ><font face="Verdana, serif"
          ><font size="2" style="font-size: 9pt"><i> </i></font></font
        ><font face="Verdana, serif"
          ><font size="2" style="font-size: 9pt">erhoben werden, an den Hersteller (</font></font
        ><font face="Verdana, serif"
          ><font size="2" style="font-size: 9pt"
            >hoots classic GmbH, Sebnitzer Straße 53, 01099 Dresden)</font
          ></font
        ><font face="Verdana, serif"
          ><font size="2" style="font-size: 9pt">
            übermittelt und von diesem gemäß der jeweiligen Produktdatenschutzerklärung des
            Herstellers gespeichert und verarbeitet werden
          </font></font
        ><font face="Verdana, serif"
          ><font size="2" style="font-size: 9pt"
            >und erteile hierzu meine widerrufliche Einwilligung.</font
          ></font
        >
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "DatenschutzErklaerung2021",
};
</script>
