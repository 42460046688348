<template>
  <v-dialog :value="showDialogue" max-width="600">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("ui.device.dialog.register.title") }}</span>
      </v-card-title>

      <v-card-text>
        <v-form v-model="formValid">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="serial"
                  :rules="[required, serialPolicy]"
                  prepend-icon="router"
                  name="serial"
                  :label="$t('ui.device.serial')"
                  type="text"
                  placeholder="123-45678"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="password"
                  :rules="[required, passwordPolicy, passwordMin, passwordMax]"
                  prepend-icon="lock"
                  name="password"
                  :label="$t('ui.device.dialog.register.password')"
                  type="password"
                  placeholder="Passwort123"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">{{ $t("ui.action.cancel") }} </v-btn>
        <v-btn @click="register" color="green" :loading="loading" :disabled="!formValid"
          >{{ $t("ui.action.register") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

type ValidatorFunction = (value: string) => boolean | TranslateResult;

export default Vue.extend({
  name: "register-device-modal",
  data() {
    return {
      serial: "",
      password: "",
      loading: false,
      formValid: false,
      passwordPolicyRegExp: /^(?=.*[a-z]*)(?=.*[A-Z])(?=.*[0-9])(?=.{8})/,
      serialRegExp: /^[0-9]{3}-[0-9]{5}$/,
    };
  },
  props: {
    showDialogue: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    required(): ValidatorFunction {
      return (value: string) => !!value || this.$t("ui.rule.required");
    },
    passwordMin(): ValidatorFunction {
      return (value) => value.length >= 7 || this.$t("ui.rule.passwordMin");
    },
    passwordMax(): ValidatorFunction {
      return (value) => value.length <= 80 || this.$t("ui.rule.length80");
    },
    passwordPolicy(): ValidatorFunction {
      return (value) => this.passwordPolicyRegExp.test(value) || this.$t("ui.rule.passwordPolicy");
    },
    serialPolicy(): ValidatorFunction {
      return (value) => this.serialRegExp.test(value) || this.$t("ui.rule.serial");
    },
  },
  methods: {
    async register(): Promise<void> {
      const payload = {
        serial: this.serial,
        password: this.password,
      };

      try {
        this.loading = true;
        await this.$store.dispatch("device/registerDevice", payload);
        this.$emit("success");
      } catch (err) {
        this.$emit("error", (err as Error).message);
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.$emit("close");
    },
  },
});
</script>
