import {
  measurementUnitMap,
  plotlyDataConfig,
  SensorPlotData,
} from "@/charts/config/config.data.plotly";
import { colorMap, PlotColor } from "@/charts/config/config.style.plotly";
import { defaultPlotConfig, PlotConfiguration } from "@/charts/config/config.plots.plotly";
import { defaultLayout } from "@/charts/config/config.layout.plotly";
import { merge } from "lodash";
import Plotly, { Layout, PlotData } from "plotly.js";
import { YAxis } from "@/helpers/plotly/relayout.utils";
import i18n from "@/plugins/i18n.plugin";
import { TranslateResult } from "vue-i18n";
const colors: PlotColor[] = ["blue", "orange", "green", "yellow"];

const legendSizeInPx = "8px";
const textColor = "#ececec";

const basePlotDataConfig: Partial<Plotly.PlotData> = {
  type: "scatter",
  mode: "lines",
  line: {
    width: 1.0,
  },
  fill: "tonexty",
  opacity: 0.5,
};

const baseAxisConfig = {
  font: {
    size: legendSizeInPx,
    color: textColor,
  },
};

export type HootsChart = {
  id: string;
  data: Partial<PlotData>[];
  layout: Partial<Layout>;
};

type ShortAxis = "y1" | "y2" | "y3" | "y4";

type MeasurementType = keyof typeof measurementUnitMap;

function getMeasurementTypeForSensorProperty(
  sensor: keyof SensorPlotData,
  property: string
): MeasurementType {
  return (plotlyDataConfig as any)[sensor][property].type;
}

function getMeasurementNameForSensorProperty(
  sensor: keyof SensorPlotData,
  property: string
): string {
  const propertyConfig = (plotlyDataConfig as any)[sensor][property];
  const positionString = propertyConfig.position ? ` ${propertyConfig.position}` : "";
  return i18n.t(propertyConfig.name) + positionString;
}

function resolvePlotTitle(
  meta: typeof defaultPlotConfig[string]["meta"]
): string | TranslateResult {
  if (meta.type === "default") return i18n.t(meta.name);
  else return meta.name;
}

function getYAxisForSensorProperty(
  measurementTypeAxisMap: Partial<Record<MeasurementType, number>>
): (sensor: keyof SensorPlotData, property: string) => ShortAxis {
  return (sensor, property) => {
    const measurementType = getMeasurementTypeForSensorProperty(sensor, property);

    if (measurementTypeAxisMap[measurementType] == undefined) {
      measurementTypeAxisMap[measurementType] = Object.keys(measurementTypeAxisMap).length + 1;
    }

    return `y${measurementTypeAxisMap[measurementType] as 1 | 2 | 3 | 4}`;
  };
}

function getHoverTemplateForProperty(sensor: keyof SensorPlotData, property: string) {
  const measurementType = getMeasurementTypeForSensorProperty(sensor, property);
  const { unit, accuracy } = measurementUnitMap[measurementType];
  return `%{y:.${accuracy}f} ${unit}`;
}

function getAxisNameForProperty(sensor: keyof SensorPlotData, property: string): string {
  const measurementType = (plotlyDataConfig as any)[sensor][property]
    .type as keyof typeof measurementUnitMap;
  const title = i18n.t(measurementUnitMap[measurementType].title);
  const unit = measurementUnitMap[measurementType].unit;
  return title + " " + unit;
}

function getPlotConfigForSensorProperty(
  data: SensorPlotData,
  sensor: keyof SensorPlotData,
  property: string,
  yaxis: ShortAxis,
  hovertemplate: string,
  name: string,
  index: number
): Partial<Plotly.PlotData> {
  return {
    ...basePlotDataConfig,
    x: data[sensor]["ts"],
    y: (data as any)[sensor][property],
    hovertemplate,
    yaxis,
    name,
    line: {
      color: colorMap[colors[index]].line,
    },
    fillcolor: colorMap[colors[index]].fill,
  };
}

function removeSourcesAndPlotConfigsWithNoData(
  plotConfig: PlotConfiguration,
  data: SensorPlotData
): PlotConfiguration {
  const validatedPlotConfig = structuredClone(plotConfig);

  Object.keys(plotConfig).map((plotId) => {
    validatedPlotConfig[plotId]["sources"] = validatedPlotConfig[plotId]["sources"].filter(
      ({ sensor, property }) => (data as never)[sensor]?.[property] !== undefined
    );

    if (validatedPlotConfig[plotId]["sources"].length == 0) {
      delete validatedPlotConfig[plotId];
    }
  });

  return validatedPlotConfig;
}

function convertPlotDataAxisToRangeAxis(plotAxis: string) {
  const plotAxisNumber = plotAxis[1] == "1" ? "" : plotAxis[1];
  return `yaxis${plotAxisNumber}` as YAxis;
}

function buildPlotsWithValidConfig(plotConfig: PlotConfiguration, sensorData: SensorPlotData) {
  const plots: HootsChart[] = [];

  for (const [plotId, config] of Object.entries(plotConfig)) {
    const yAxisForSensorProperty = getYAxisForSensorProperty({});

    const axisLayout: Partial<Record<YAxis, any>> = {};

    const data = config.sources.map(({ sensor, property }, index) => {
      const hoverTemplate = getHoverTemplateForProperty(sensor, property);
      const yaxis = yAxisForSensorProperty(sensor, property);
      const name = getMeasurementNameForSensorProperty(sensor, property);

      const layoutAxis = convertPlotDataAxisToRangeAxis(yaxis);
      const measurementAxisLabel = getAxisNameForProperty(sensor, property);
      axisLayout[layoutAxis] = { title: measurementAxisLabel, ...baseAxisConfig };

      return getPlotConfigForSensorProperty(
        sensorData,
        sensor,
        property,
        yaxis,
        hoverTemplate,
        name,
        index
      );
    });

    const layout = merge({}, defaultLayout, axisLayout, {
      title: { text: resolvePlotTitle(config.meta) },
    });

    plots.push({ id: plotId, layout, data });
  }

  return plots;
}

function buildCharts(plotConfig: PlotConfiguration, sensorData: SensorPlotData): HootsChart[] {
  const validPlotConfig = removeSourcesAndPlotConfigsWithNoData(plotConfig, sensorData);
  return buildPlotsWithValidConfig(validPlotConfig, sensorData);
}

export { buildCharts };
