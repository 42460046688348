<template>
  <v-container fluid grid-list-md>
    <v-toolbar flat dark color="rgba(0, 0, 0, 0.0)">
      <v-toolbar-title>{{ $t("ui.me.title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-layout row>
        <v-card max-width="600">
          <v-form v-model="valid" class="px-3">
            <v-card-title class="headline">{{ $t("ui.dialog.password.title") }}</v-card-title>
            <v-container>
              <v-flex xs12>
                <v-text-field
                  v-model="password"
                  :append-icon="show1 ? 'visibility' : 'visibility_off'"
                  :label="$t('ui.dialog.password.label.new')"
                  :type="show1 ? 'text' : 'password'"
                  :rules="[required, passwordPolicy, passwordMin, passwordMax]"
                  prepend-icon="lock"
                  required
                  autocomplete="new-password"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="confirmPassword"
                  :append-icon="show2 ? 'visibility' : 'visibility_off'"
                  :type="show2 ? 'text' : 'password'"
                  :rules="[required, match]"
                  :label="$t('ui.dialog.password.label.confirm')"
                  prepend-icon="verified_user"
                  required
                  autocomplete="new-password"
                  @click:append="show2 = !show2"
                ></v-text-field>
              </v-flex>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" @click="navigateToHome">{{
                $t("ui.action.cancel")
              }}</v-btn>
              <v-btn color="green darken-1" @click="resetPassword" :disabled="!valid">{{
                $t("ui.action.confirm")
              }}</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-layout>
    </v-toolbar>
    <v-snackbar :value="errorMessage" :bottom="true" :multi-line="true" color="red darken-2"
      >Error: {{ errorMessage }}</v-snackbar
    >
    <v-snackbar :value="successMessage" :bottom="true" :multi-line="true" color="green darken-2"
      >Success: {{ successMessage }}</v-snackbar
    >
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";

import i18n from "@/plugins/i18n.plugin";
import { timeout } from "@/utils/timing/timing.utils";

export default Vue.extend({
  data: () => {
    return {
      show1: false,
      show2: false,
      valid: false,

      password: "",
      confirmPassword: "",
      passwordPolicyRegExp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8})/,

      errorMessage: null as string | null,
      successMessage: null as string | null,
    };
  },

  async mounted() {
    if (this.token) {
      try {
        const checkToken = await this.$auth.checkResetPasswordToken(this.token);
        if (!checkToken.wasSuccessful()) {
          this.navigateToHome();
        }
      } catch (e) {
        console.error(e);
        await this.displayMessage("error", String((e as Error).message));
      }
    } else {
      this.navigateToHome();
    }
  },

  computed: {
    required() {
      return (value: string) => !!value || this.$t("ui.rule.required");
    },
    passwordMin() {
      return (value: string) => value.length >= 7 || this.$t("ui.rule.passwordMin");
    },
    passwordMax() {
      return (value: string) => value.length <= 80 || this.$t("ui.rule.length80");
    },
    passwordPolicy() {
      return (value: string) =>
        this.passwordPolicyRegExp.test(value) || this.$t("ui.rule.passwordPolicy");
    },
    match() {
      return (value: string) => value === this.password || this.$t("ui.rule.mismatch");
    },
    userID(): string | null {
      return this.$auth.getUserID();
    },
    token(): string | null {
      return this.$route.params.token;
    },
  },
  methods: {
    async displayMessage(type: "success" | "error", message: string) {
      this[type === "success" ? "successMessage" : "errorMessage"] = message;
      await timeout(2000);
      this[type === "success" ? "successMessage" : "errorMessage"] = null;
    },

    navigateToHome(): void {
      this.$router.push("/login");
    },

    async resetPassword(): Promise<void> {
      if (!this.userID) {
        await this.displayMessage("error", "Missing User ID");
      } else if (!this.token) {
        await this.displayMessage("error", "Missing Token");
      } else {
        const passwordResetAction = await this.$auth.resetPasswordWithToken(
          this.userID,
          this.password,
          this.token
        );

        if (passwordResetAction.wasSuccessful()) {
          await this.displayMessage(
            "success",
            i18n.t("ui.dialog.login.passwordReset.successful").toString()
          );
          this.navigateToHome();
        } else {
          await this.displayMessage("error", passwordResetAction.getMessage() || "Unknown Error");
        }
      }
    },
  },
});
</script>

<style scoped></style>
