export type Async<T> = Promise<T>;

export class Response<DataType = undefined> {
  constructor(
    private readonly successful: boolean,
    private readonly message?: string,
    private readonly data?: DataType
  ) {}

  wasSuccessful(): boolean {
    return this.successful;
  }

  getMessage(): string | undefined {
    return this.message;
  }

  getData(): DataType | undefined {
    return this.data;
  }
}
