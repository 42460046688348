import type { FetchProvider } from "@/service/fetch/fetch.interface";
import { Response } from "@/@types/helper/api.types";
import type { Session, SessionGeoData, SessionData } from "@/@types/data/session.types";
import type { GeoJSON } from "leaflet";
import type { Point } from "geojson";
import { convertDeviceIDToStringForm } from "@/utils/data/devices.utils";
import { SessionApiDataV2 } from "./sessions.provider.types";

type SessionWithDeviceIDNumber = Session & { device_id: number };

function processSessionList(sessions: SessionWithDeviceIDNumber[]): Session[] {
  return sessions.map((session) => {
    return {
      ...session,
      device_id: convertDeviceIDToStringForm(session.device_id),
    };
  });
}

function removeCoordinateDataFromSensorData(
  sessionSensorData: SessionApiDataV2
): Omit<SessionApiDataV2, "coordinates"> {
  delete sessionSensorData.coordinates;
  return sessionSensorData;
}

function processSensorData(
  sessionSensorData: SessionApiDataV2
): Omit<SessionApiDataV2, "coordinates"> {
  return removeCoordinateDataFromSensorData(sessionSensorData);
}

function createRouteFromCoordinates(
  coordinates: Required<SessionApiDataV2>["coordinates"]
): SessionGeoData["route"] {
  const coordinatesSimple: [number, number][] = [];

  for (let i = 0; i < coordinates.ts.length; i++) {
    coordinatesSimple.push([coordinates.lon[i], coordinates.lat[i]]);
  }

  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: coordinatesSimple,
        },
        properties: {},
      },
    ],
  };
}

function createPointsFromCoordinates(
  coordinates: Required<SessionApiDataV2>["coordinates"]
): SessionGeoData["points"] {
  const pointFeatures: GeoJSON.Feature<Point>[] = [];

  for (let i = 0; i < coordinates.ts.length; i++) {
    pointFeatures.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [coordinates.lon[i], coordinates.lat[i]],
      },
      properties: {
        ts: coordinates.ts[i],
        v_kmh: coordinates.v_kmh?.[i] || 0,
      },
    });
  }

  return {
    type: "FeatureCollection",
    features: pointFeatures,
  };
}

function getStartAndEndCoordinate(
  coordinates: Required<SessionApiDataV2>["coordinates"]
): SessionGeoData["coordinates"] {
  const numCoords = coordinates.lat.length - 1;

  const start = { lat: coordinates.lat[0], lng: coordinates.lon[0] };
  const end = { lat: coordinates.lat[numCoords], lng: coordinates.lon[numCoords] };

  return { start, end };
}

function processGeoData(coordinates: SessionApiDataV2["coordinates"]): SessionGeoData | null {
  if (!!coordinates) {
    const points = createPointsFromCoordinates(coordinates);
    const route = createRouteFromCoordinates(coordinates);
    const { start, end } = getStartAndEndCoordinate(coordinates);

    return { points, route, coordinates: { start, end } };
  } else {
    return null;
  }
}

function convertSecondTimestampsToMilliseconds(data: SessionApiDataV2): SessionApiDataV2 {
  for (const sensor of Object.keys(data)) {
    if (!!data[sensor as keyof SessionApiDataV2]?.["ts"]) {
      // @ts-expect-error Complicated in typescript
      data[sensor]["ts"] = data[sensor]["ts"].map((ts) => ts * 1000);
    }
  }

  return data;
}

export class SessionsProvider {
  fetch: FetchProvider;
  baseURL: string;

  constructor(fetch: FetchProvider, baseURL: string) {
    this.fetch = fetch;
    this.baseURL = baseURL;
  }

  async loadSessionList(minimumDistance = 0.8): Promise<Response<Session[]>> {
    const request = await this.fetch.get<SessionWithDeviceIDNumber[]>(
      this.baseURL + `/sessions/v2?distance=${minimumDistance}`
    );
    if (request.wasSuccessful()) {
      const processedSessionList = processSessionList(request.getData() || []);
      return new Response(true, request.getMessage(), processedSessionList);
    } else {
      return new Response(false, request.getMessage());
    }
  }

  async loadSessionData(session: Session): Promise<Response<SessionData>> {
    try {
      const request = await this.fetch.get<SessionApiDataV2>(
        this.baseURL + `/session/v4/by-id/` + session.id
      );

      let sessionData = request.getData();

      if (!sessionData) return new Response(false, request.getMessage());

      Object.entries(sessionData).forEach(([key, value]) => {
        if (Object.keys(value).length === 0) {
          // @ts-expect-error Complicated in typescript
          delete sessionData[key as keyof SessionApiDataV2];
        }
      });

      sessionData = convertSecondTimestampsToMilliseconds(sessionData);

      const geoData = processGeoData(sessionData.coordinates);
      const sensorData = processSensorData(sessionData);

      return new Response(true, request.getMessage(), { sensorData, geoData });
    } catch (error) {
      return new Response(false, (error as Error).message);
    }
  }
}
