<template>
  <l-marker
    :lat-lng="position"
    :title="title"
    :draggable="false"
    :ref="markerRef"
    :icon="icon"
    @add="openPopup"
  >
    <l-popup :content="text" :options="popupOptions" ref="popup" />
  </l-marker>
</template>

<script lang="ts">
import Vue from "vue";

import { LMarker, LPopup } from "vue2-leaflet";

export default Vue.extend({
  name: "MarkerPopup",
  components: {
    LMarker,
    LPopup,
  },
  props: {
    text: {
      type: String,
      default: "",
    },
    position: {
      type: Object,
    },
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: Object,
      default: () =>
        window.L.AwesomeMarkers.icon({
          icon: "car",
          prefix: "fa",
          markerColor: "blue",
        }),
    },
    markerRef: {
      type: String,
      default: "markerRef",
    },
    autoClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popupOptions: {
        autoClose: false,
      },
    };
  },
  methods: {
    openPopup: function () {
      (this.$refs.markerRef as LMarker).mapObject.openPopup();
    },
  },
});
</script>

<style>
/* first table column: icons */
.popup td {
  color: rgb(68, 68, 68);
  font-size: 14px;
  padding: 4px;
  text-align: center;
  /*    background: rgba(0, 255, 0, 0.65);  */
  border-collapse: collapse;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #c6c6c6;
}

/* second and further table columns */
.popup td:nth-child(2) {
  border-collapse: collapse;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #c6c6c6;
  color: black;
  font-size: 12px;
  padding: 4px;
  text-align: left;
  /*    background: rgba(255, 0, 0, 0.65); */
}

.leaflet-popup-content-wrapper {
  border-radius: 0 !important;
  background: rgba(255, 255, 255, 0.75);
}

.leaflet-popup-content {
  max-width: 400px !important;
  /*width: auto !important; */
  overflow: auto;
}
</style>
