// Workaround due to typescript not inferring Object.keys type
function keys<O>(o: O) {
  return Object.keys(o) as (keyof O)[];
}

type ErrorCodes = string | number;

type ErrorCheckerFunction<DataType> = (value: DataType) => boolean;

type ErrorCheckerMap<DataType, Errors extends ErrorCodes> = {
  [Error in Errors]: ErrorCheckerFunction<DataType>;
};

type ErrorStringMap<Errors extends ErrorCodes> = {
  [Error in Errors]: string;
};

type ErrorChecker<DataType, Error extends ErrorCodes> = (value: DataType) => true | Error;

function buildErrorChecker<DataType, Errors extends ErrorCodes>(
  errorCheckerMap: ErrorCheckerMap<DataType, Errors>
): ErrorChecker<DataType, Errors> {
  return (value: DataType) => {
    for (const error of keys(errorCheckerMap)) {
      const hasError = errorCheckerMap[error];
      if (hasError(value)) return error;
    }
    return true;
  };
}

function convertErrorCheckerOutputToStrings<Errors extends ErrorCodes>(
  isValid: true | Errors,
  errorStringMap: ErrorStringMap<Errors>
): true | string {
  if (isValid == true) return true;
  else return String(errorStringMap[isValid]);
}

export { ErrorCheckerMap, ErrorStringMap, buildErrorChecker, convertErrorCheckerOutputToStrings };
