import Vue from "vue";
import { HootsUserProvider } from "@/service/user/hootsuser/hootsuser.provider";

export function getVueUserPlugin(
  userProviderInstance: HootsUserProvider
): Vue.PluginObject<HootsUserProvider> {
  return {
    install() {
      Object.defineProperty(Vue.prototype, "$user", {
        get() {
          return userProviderInstance;
        },
      });
    },
  };
}
