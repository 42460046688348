<template>
  <v-container fluid grid-list-md>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t("ui.device.dialog.edit.title") }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-text-field
                    v-model="editedItem.id"
                    :label="$t('ui.device.serial')"
                    prepend-icon="fingerprint"
                    autofocus
                    autocomplete="off"
                    text
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-text-field
                    v-model="editedItem.registered_at"
                    :label="$t('ui.device.dialog.edit.registeredAt')"
                    prepend-icon="mdi-clock-outline"
                    autofocus
                    autocomplete="off"
                    text
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-text-field
                    v-model="editedItem.mac"
                    :label="$t('device.properties.bluetooth.macAddress')"
                    prepend-icon="mdi-bluetooth-settings"
                    autofocus
                    autocomplete="off"
                    text
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="editedItem.name"
                    :items="devices"
                    :label="$t('ui.device.dialog.edit.rename')"
                    :placeholder="$t('ui.device.dialog.edit.placeholder')"
                    prepend-icon="router"
                    autofocus
                    autocomplete="off"
                    text
                    maxlength="80"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">{{ $t("ui.action.cancel") }}</v-btn>
            <v-btn color="blue darken-1" text @click="save">{{ $t("ui.action.save") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <register-device-modal
        :show-dialogue="registerDeviceDialog"
        @error="showError"
        @close="closeRegisterForm"
        @success="closeRegisterForm"
      />
    </v-row>

    <v-toolbar flat dark color="rgba(0, 0, 0, 0.0)">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab small color="primary" v-on="on" @click="registerDeviceDialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("ui.device.dialog.register.title") }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-container fluid>
      <v-row>
        <v-col v-for="device in devices" :key="device.id" cols="12" xs="12" sm="6" md="6" lg="6">
          <v-card>
            <v-img :src="device.image" aspect-ratio="3.5">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
              <v-sheet tile color="rgba(0, 0, 0, 0.5)">
                <v-card-title class="justify-center"
                  >{{ $t("ui.device.serial") }} {{ device.id }}</v-card-title
                >
              </v-sheet>
            </v-img>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn @click="editItem(device)" v-on="on" text>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("ui.device.title") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="unregister(device)"
                    v-on="on"
                    color="error"
                    text
                    :disabled="devices.length === 1"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ deleteBoxText }}</span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar
      v-model="error"
      :timeout="6000"
      :bottom="true"
      :multi-line="true"
      color="red darken-2"
      >{{ errortext }}</v-snackbar
    >
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import i18n from "@/plugins/i18n.plugin";
import RegisterDeviceModal from "@/views/dashboard/device/components/RegisterDeviceModal";

export default {
  components: { RegisterDeviceModal },
  data: () => ({
    dialog: false,
    registerDeviceDialog: false,
    valid: false,
    error: false,
    errortext: "",
    editedIndex: -1,
    editedItem: {
      id: "",
      name: "",
      registered_at: "",
      updated_at: "",
    },
    serial: "",
    password: "",

    loadingState: false,
    loading: false,
  }),

  computed: {
    ...mapState("device", ["devices"]),

    title() {
      return this.devices.length > 1
        ? this.$t("ui.device.my") + " (" + this.devices.length + ")"
        : this.$t("ui.device.my") + " (" + this.devices.length + ")";
    },

    deleteBoxText() {
      return i18n.t("ui.common.button.deleteEntity", {
        entity: "hoots|one",
      });
    },
  },

  mounted() {
    this.loadDevices();
  },

  methods: {
    async loadDevices() {
      try {
        this.loading = true;
        await this.$store.dispatch("device/fetchAllDevices");
      } catch (error) {
        this.errortext = error.message;
        this.error = true;
      } finally {
        this.loading = false;
      }
    },

    editItem(item) {
      this.editedIndex = this.devices.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    save() {
      this.update();
      this.close();
    },

    update() {
      const device = this.editedItem;
      this.$store
        .dispatch("device/updateDevice", device)
        .then(() => {
          this.devices[this.editedIndex].name = device.name;
        })
        .catch((err) => {
          this.error = true;
          // gRPC codes.InvalidArgument
          if (err.response.data.code === 3) {
            this.errortext = this.$t("ui.error.device.noupdate");
          } else {
            this.errortext = err.response.data.message;
          }
        });
    },

    showError(errorMessage) {
      this.errortext = errorMessage;
      this.error = true;
    },

    closeRegisterForm() {
      this.registerDeviceDialog = false;
      this.loadDevices();
    },

    async unregister(device) {
      const index = this.devices.indexOf(device);
      const serial = device.id;

      if (confirm(this.$t("ui.error.device.confirmDelete")) && this.devices.splice(index, 1)) {
        try {
          this.loadingState = true;
          await this.$store.dispatch("device/unregisterDevice", serial);
        } catch (err) {
          this.errortext = err.getMessage();
          this.error = true;
        } finally {
          this.loadingState = false;
        }
      }
    },
  },
};
</script>
