import { FetchProvider } from "@/service/fetch/fetch.interface";
import { Response } from "@/@types/helper/api.types";

type Config = {
  entitySlug: string;
  multiEntitySlug?: string;
  baseUrl: string;
};

export class EntityProvider<BaseEntity, FullEntity> {
  constructor(private config: Config, private fetchProvider: FetchProvider) {}

  async getAll(): Promise<Response<FullEntity[]>> {
    const entitySlug = this.config.multiEntitySlug || this.config.entitySlug;
    return this.fetchProvider.get<FullEntity[]>(`${this.config.baseUrl}/${entitySlug}`);
  }

  async get(id: string): Promise<Response<FullEntity>> {
    return this.fetchProvider.get<FullEntity>(
      `${this.config.entitySlug}/${this.config.entitySlug}/${id}`
    );
  }

  async create(entity: BaseEntity): Promise<Response> {
    const createRequest = await this.fetchProvider.post<BaseEntity, FullEntity>(
      `${this.config.baseUrl}/${this.config.entitySlug}`,
      entity
    );
    return new Response(createRequest.wasSuccessful(), createRequest.getMessage());
  }

  async update(entity: BaseEntity): Promise<Response> {
    const updateRequest = await this.fetchProvider.put<BaseEntity, FullEntity>(
      `${this.config.baseUrl}/${this.config.entitySlug}`,
      entity
    );

    return new Response(updateRequest.wasSuccessful(), updateRequest.getMessage());
  }

  async delete(id: string): Promise<Response> {
    const deleteRequest = await this.fetchProvider.delete(
      `${this.config.baseUrl}/${this.config.entitySlug}/${id}`
    );

    return new Response(deleteRequest.wasSuccessful(), deleteRequest.getMessage());
  }
}
