<template>
  <v-dialog
    :value="showDialog"
    fullscreen
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <edit-sensor v-bind:showDialog="sensorDialog" v-bind:sensor="sensor" @close="sensorClose" />

    <v-toolbar dark color="primary darken-2">
      <v-toolbar-title
        >{{ $t("ui.picker.sensors.detectedSensors") }}: {{ sensorCount }}</v-toolbar-title
      >
      <v-spacer />
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card color="rgba(0, 0, 0, 0.85)">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="sensorInfo in sensorMap"
            :key="sensorInfo.id"
            cols="12"
            xs="12"
            sm="6"
            md="6"
            lg="2"
          >
            <complex-button
              step="0"
              :icon="sensorIcon(sensorInfo.id)"
              :title="tileTitle(sensorInfo)"
              :tooltip="sensorType(sensorInfo.id)"
              @triggered="select(sensorInfo.id)"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

import ComplexButton from "@/components/ComplexButton";

import EditSensor from "@/components/EditSensor";
import { isEmpty } from "lodash";

export default {
  name: "VehiclePicker",
  props: ["showDialog", "vehicle"],
  components: {
    EditSensor,
    ComplexButton,
  },
  data: () => ({
    menu: false,
    sensorID: 0,
    sensorDialog: false,
  }),

  computed: {
    ...mapState("sensor", ["sensor"]),

    title() {
      return isEmpty(this.sensor) ? "" : this.sensor.name;
    },

    ids() {
      return this.vehicle.sensorids;
    },

    sensorMap() {
      return this.vehicle.sensor_map;
    },

    sensorCount() {
      return isEmpty(this.vehicle.sensor_map) ? 0 : this.vehicle.sensor_map.length;
    },
  },

  methods: {
    select(id) {
      this.$store.dispatch("sensor/get", { sensor_id: id }).then(() => {
        this.sensorDialog = true;
      });
    },

    sensorIcon(id) {
      // "battery sensor"
      if (id >= 0 && id < 262144) return "battery_charging_full";
      if (id >= 262144 && id <= 262744) return "mdi-thermostat";
      if (id >= 589824 && id <= 590424) return "mdi-gauge";
      if (id >= 851968 && id <= 852568) return "fa-thermometer-half";
      if (id >= 1179648 && id <= 1180248) return "mdi-map-marker";
      if (id >= 1310720 && id <= 1311320) return "fa-thermometer-half";
      if (id >= 1572864 && id <= 1638399) return "mdi-lambda";
      if (id >= 1835008 && id <= 1900543) return "fa-thermometer-half";
    },

    tileTitle(sensorInformation) {
      return this.sensorType(sensorInformation.id) + " " + sensorInformation.counter;
    },

    sensorType(id) {
      // "battery sensor"
      if (id >= 0 && id < 262144) return this.$t("ui.sensors.voltage.title");
      if (id >= 262144 && id <= 262744) return this.$t("ui.sensors.rpm.title");
      if (id >= 589824 && id <= 590424) return this.$t("ui.sensors.oil.title");
      if (id >= 851968 && id <= 852568) return this.$t("ui.sensors.cool.title");
      if (id >= 1179648 && id <= 1180248) return this.$t("ui.sensors.gps.title");
      if (id >= 1310720 && id <= 1311320) return this.$t("ui.sensors.comp.title");
      if (id >= 1572864 && id <= 1638399) return this.$t("ui.sensors.lambda.title");
      if (id >= 1835008 && id <= 1900543) return this.$t("ui.sensors.hot.title");
    },

    sensorClose() {
      this.sensorDialog = false;
    },

    close() {
      // emit close-event to parent component
      this.$emit("close");
    },
  },
};
</script>
