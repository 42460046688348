export type PlotColor = "orange" | "blue" | "green" | "yellow";
type ColorTypes = { line: string; fill: string };

export const colorMap: Record<PlotColor, ColorTypes> = {
  orange: {
    line: "rgb(255, 127, 14)",
    fill: "rgba(255, 127, 14, 0.1)",
  },
  blue: {
    line: "rgb(31, 119, 180)",
    fill: "rgba(31, 119, 180, 0.1)",
  },
  green: {
    line: "rgb(44, 160, 44)",
    fill: "rgba(44, 160, 44, 0.1)",
  },
  yellow: {
    line: "rgb(255, 229, 94)",
    fill: "rgba(255, 229, 94, 0.1)",
  },
} as const;
