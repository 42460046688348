<template>
  <v-card>
    <v-card-title v-if="title" class="headline">{{ title }}</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6">
              <v-text-field
                v-model="editVehicle.make"
                :label="$t('ui.vehicle.dialog.edit.manufacturer')"
                :placeholder="$t('ui.common.placeholder.car.brand')"
                required
                :counter="80"
                :rules="[required, length]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6">
              <v-text-field
                v-model="editVehicle.model"
                :label="$t('ui.vehicle.dialog.edit.model')"
                placeholder="T1"
                :counter="80"
                required
                :rules="[required, length]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6">
              <v-text-field
                v-model="editVehicle.name"
                :label="$t('ui.vehicle.dialog.edit.name.label')"
                :placeholder="$t('ui.vehicle.dialog.edit.name.placeholder')"
                :counter="80"
                :rules="[required, length]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6">
              <v-select
                :items="deviceItems"
                item-text="name"
                item-value="value"
                v-model="editVehicle.device_id"
                :label="$t('ui.vehicle.dialog.edit.device')"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6">
              <v-select
                v-model="editVehicle.combustion"
                :items="fuelItems"
                item-text="name"
                item-value="value"
                :label="$t('ui.vehicle.dialog.edit.fuel')"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6">
              <v-text-field
                v-model="editVehicle.construction_year"
                :label="$t('ui.vehicle.dialog.edit.yoc')"
                placeholder="1911"
                type="decimal"
                mask="####"
                :rules="[required, yearOfManufactureValidator]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6">
              <v-select
                v-model="editVehicle.stroke"
                :items="strokeItems"
                item-text="name"
                item-value="value"
                :label="$t('ui.vehicle.dialog.edit.stroke')"
                :rules="[required]"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6">
              <v-text-field
                v-model="editVehicle.capacity"
                :rules="[required, displacement]"
                :label="$t('ui.vehicle.dialog.edit.displacement')"
                type="decimal"
                mask="#####"
                placeholder="1999"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6">
              <v-text-field
                v-model="editVehicle.power"
                :rules="[required, power]"
                :label="$t('ui.vehicle.dialog.edit.power')"
                type="decimal"
                mask="####"
                placeholder="110"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6">
              <v-select
                v-model="editVehicle.cylinders"
                :items="[1, 2, 3, 4, 5, 6, 8, 10, 12, 16]"
                :label="$t('ui.vehicle.dialog.edit.cylinder')"
                :rules="[required]"
                s
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-row>
        <!-- Image upload field -->
        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
          <v-file-input
            :rules="[filesize]"
            accept="image/png, image/jpeg, image/jpg"
            :label="$t('ui.vehicle.dialog.edit.image.label')"
            :placeholder="$t('ui.vehicle.dialog.edit.image.placeholder')"
            prepend-icon="mdi-camera"
            :show-size="1024"
            @change="onImageUpload"
          ></v-file-input>
        </v-col>

        <!-- Shows image if uploaded-->
        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
          <v-img :src="imagePreview"></v-img>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" @click="close" text>{{ $t("ui.action.cancel") }} </v-btn>
      <v-btn color="blue darken-1" @click="saveVehicle" text :disabled="!valid"
        >{{ $t("ui.action.save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import type { BaseVehicle } from "@/@types/data/vehicle.types";
import type { Device } from "@/@types/data/device.types";

import { mapGetters, mapState } from "vuex";
import { isValidYearOfManufacture } from "@/validators/vehicle.validators";
import i18n from "@/plugins/i18n.plugin";

type VSelectItems<ValueType> = {
  name: string;
  value: ValueType;
}[];

type VInputValidator<InputType = string> = (value: InputType) => boolean | string;

export default Vue.extend({
  name: "vehicle-editor",
  props: {
    title: {
      type: String,
      required: false,
    },
    initialVehicle: {
      type: Object as PropType<BaseVehicle>,
      required: true,
    },
  },
  data() {
    return {
      valid: false,

      editVehicle: this.initialVehicle,
      imagePreview: this.initialVehicle.image,

      fuelItems: [
        {
          name: i18n.t("ui.vehicle.fuel.gasoline"),
          value: 0,
        },
        {
          name: i18n.t("ui.vehicle.fuel.diesel"),
          value: 1,
        },
      ] as VSelectItems<number>,
      strokeItems: [
        {
          name: "2-" + i18n.t("ui.vehicle.dialog.edit.stroke"),
          value: 1,
        },
        {
          name: "4-" + i18n.t("ui.vehicle.dialog.edit.stroke"),
          value: 2,
        },
      ] as VSelectItems<number>,
    };
  },
  computed: {
    ...mapState("device", ["devices"]),
    ...mapGetters("vehicle", ["getVehicleByID"]),

    deviceItems(): VSelectItems<string> {
      return [
        ...this.devices.map((device: Device) => {
          return {
            name: device.id,
            value: device.id,
          };
        }),
        {
          name: i18n.t("ui.vehicle.nodevice"),
          value: null,
        },
      ];
    },

    required(): VInputValidator {
      return (value) => !!value || (i18n.t("ui.rule.required") as string);
    },
    length(): VInputValidator {
      return (value: string) =>
        (value && value.length < 80) || (i18n.t("ui.rule.length80") as string);
    },
    yearOfManufactureValidator(): VInputValidator {
      return (year: string) => {
        const yearInt = parseInt(year, 10);
        const isValidYear = isValidYearOfManufacture(yearInt);
        return isValidYear || (i18n.t("ui.rule.yoc") as string);
      };
    },
    displacement(): VInputValidator {
      return (value: string) =>
        (parseInt(value, 10) >= 49 && parseInt(value, 10) < 50000) ||
        (i18n.t("ui.rule.displacement") as string);
    },
    power(): VInputValidator {
      return (value: string) =>
        (parseInt(value, 10) > 0 && parseInt(value, 10) <= 1000) ||
        (i18n.t("ui.rule.power") as string);
    },
    filesize(): VInputValidator<File> {
      return (value: File) =>
        !value || value.size < 2 * 1024 * 1024 || (i18n.t("ui.rule.filesize") as string);
    },
    imageChanged(): boolean {
      return this.imagePreview !== this.editVehicle.image;
    },
  },
  methods: {
    setImagePreviewToCurrentImage(): void {
      this.imagePreview = this.editVehicle.image;
    },

    onImageUpload(file?: File) {
      if (file) {
        const reader = new FileReader();
        reader.addEventListener("load", (ev) => {
          const fileReadResult = ev?.target?.result;
          if (fileReadResult) this.imagePreview = fileReadResult as string;
        });
        reader.readAsDataURL(file);
      } else {
        this.setImagePreviewToCurrentImage();
      }
    },

    saveVehicle(): void {
      if (this.imageChanged) this.editVehicle.image = this.imagePreview;
      this.$emit("save-vehicle", this.editVehicle);
    },
    close(): void {
      this.$emit("close");
    },
  },
});
</script>
