<template>
  <div>
    <hoots-map-control-toolbar :center-map-last-location-enabled="true" />
    <v-card class="map-card" flat height="450px" color="rgba(0,0,0,0)">
      <div v-if="activeTrip && !activeTrip.points" class="no-data-overlay">
        <p>{{ $t("ui.dashboard.info.noMapData") }}</p>
      </div>
      <leaflet-map
        class="leaflet-map"
        mapstyle="z-index: 0; height: 100%; width: 100%"
        :trip="activeTrip"
        :last-location="activeVehicleLastLocation"
      />
    </v-card>
    <hoots-map-trip-summary-bar :activeSession="activeSession" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

import type { Session } from "@/@types/data/session.types";
import type { LeafletMap as LeafletMapTypes } from "@/@types/ui/map/leafletmap.types";

import LeafletMap from "@/components/base/map/LeafletMap.vue";
import HootsMapControlToolbar from "@/components/dashboard/charts/HootsMapControlToolbar.vue";
import HootsMapTripSummaryBar from "@/components/dashboard/map/HootsMapTripSummaryBar.vue";

export default Vue.extend({
  name: "dashboard-map",
  components: { HootsMapTripSummaryBar, LeafletMap, HootsMapControlToolbar },
  props: {
    activeSession: {
      type: Object as PropType<Session>,
      required: false,
    },
    activeTrip: {
      type: Object as PropType<LeafletMapTypes.Trip>,
      required: false,
    },
    activeVehicleLastLocation: {
      type: Object as PropType<LeafletMapTypes.LastLocation>,
      required: false,
    },
  },
});
</script>

<style scoped lang="scss">
.map-card {
  height: 450px;
  position: relative;
}

.no-data-overlay {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  background-color: rgba(0, 0, 0, 0.5);
}

.leaflet-map {
  z-index: 0;
}
</style>
