import { createEventBus } from "@/packages/event-bus";
import { Coordinates } from "@/@types/data/geo.types";

type Empty = Record<string, never>;

export enum DashboardEventID {
  LOADING_STATE_CHANGED = "updated:loading",
  LAYOUT_CHANGED = "updated:layout",
  MAP_CENTER_MOVED = "updated:map:center",
}

export enum DashboardCommandID {
  CENTER_MAP_TO_LAST_LOCATION = "set:map:center:last-location",
}

export type DashboardEvents = {
  [DashboardEventID.LOADING_STATE_CHANGED]: { loading: boolean };
  [DashboardEventID.LAYOUT_CHANGED]: Empty;
  [DashboardEventID.MAP_CENTER_MOVED]: { to: Coordinates };
  [DashboardCommandID.CENTER_MAP_TO_LAST_LOCATION]: Empty;
};

const dashboardEventBus = createEventBus<DashboardEvents>();

export { dashboardEventBus };
