<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" persistent scrollable max-width="700px">
      <vehicle-editor
        :title="dialogueTitle"
        :initial-vehicle="initialVehicle"
        @save-vehicle="saveVehicle"
        @close="close"
      />
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
import Vue from "vue";
import type { BaseVehicle } from "@/@types/data/vehicle.types";

import { mapGetters } from "vuex";
import VehicleEditor from "@/components/shared/vehicle/VehicleEditor.vue";

export default Vue.extend({
  name: "create-vehicle",
  components: { VehicleEditor },
  data() {
    return {
      showDialog: true,
    };
  },
  computed: {
    ...mapGetters("vehicle", ["getVehicleByID"]),
    dialogueTitle(): string {
      return this.$t("ui.vehicle.new") as string;
    },
    initialVehicle(): BaseVehicle {
      return {
        name: "",
        make: "",
        device_id: null,
        model: "",
        stroke: 2,
        cylinders: 4,
        combustion: 1,
        construction_year: 1911,
        capacity: 2000,
        power: 110,
        image: "",
      };
    },
  },
  methods: {
    async saveVehicle(newVehicle: BaseVehicle): Promise<void> {
      const createRequest = await this.$store.dispatch("vehicle/create", newVehicle);
      if (createRequest.wasSuccessful()) this.close();
      else this.$emit("error", createRequest.getMessage());
    },
    close(): void {
      this.$emit("close");
    },
  },
});
</script>
