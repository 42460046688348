<template>
  <v-row justify="center">
    <v-dialog :value="showDialog" persistent scrollable max-width="700px">
      <v-card>
        <v-card-title class="headline">Nutzungsbedingungen</v-card-title>
        <!--<v-card-title class="headline">hoots|online Datenschutzerklärung und AGB</v-card-title>-->
        <v-card-text class="scroll-y">
          <b>1. Geltungsbereich, Vertragspartner, Kundendienst</b>
          <br />
          <br />1.1. Für die von der hoots classic GmbH (nachfolgend Anbieter genannt) insbesondere
          über <br />den Online-Shop (erreichbar über
          <a href="https://www.hoots.de/">www.hoots.de.</a> sowie
          <a href="https://hoots.store/">hoots.store)</a> an Verbraucher (§ 13 BGB) <br />und
          Unternehmer (§ 14 BGB) offerierten Angebote, Kundenbestellungen und die nachfolgende
          <br />Geschäftsbeziehung gelten ausschließlich die nachfolgenden Allgemeinen
          <br />Geschäftsbedingungen des Anbieters in ihrer zum Zeitpunkt der Bestellung gültigen
          Fassung. <br />Abweichende, entgegenstehende oder ergänzende Allgemeine
          Geschäftsbedingungen des <br />Bestellers werden nicht Vertragsbestandteil, auch wenn der
          Anbieter ihnen nicht ausdrücklich <br />widerspricht oder der Besteller bei der Bestellung
          auf sie hinweist. Vertragsbedingungen des <br />Bestellers gelten auch dann nicht, wenn
          der Anbieter in Kenntnis dieser Vertragsbedingungen die <br />Leistungen des Bestellers
          vorbehaltlos annimmt, sondern nur, wenn der Anbieter ihrer Geltung <br />ausdrücklich
          schriftlich zugestimmt hat.
          <br />
          <br />1.2. Soweit nicht ausdrücklich anders angegeben ist Anbieter und Vertragspartner die
          <br />
          <br />
          <b>
            hoots classic GmbH, Sebnitzer Str. 53, 01099 Dresden (Handelsregister: Amtsgericht
            <br />Dresden, HRB 36838)
          </b>
          <br />
          <br />Für sämtliche Fragen, Reklamationen und Beanstandungen erreichen Sie unseren
          Kundendienst <br />montags bis freitags von 9-16 Uhr unter der Telefonnummer (0351) 810
          810 27 sowie per E-Mail <br />unter
          <a href="mailto:info@hoots.de">info@hoots.de.</a>
          <br />
          <br />
          <b>2. Vertragsschluss</b>
          <b></b>
          <br />
          <br />2.1. Die Darstellung der Produkte im Online-Shop stellt kein rechtlich bindendes
          Angebot zum <br />Abschluss eines Vertrages, sondern einen unverbindlichen Online-Katalog
          dar.
          <br />
          <br />2.2. Durch das Anklicken des Buttons "IN WARENKORB LEGEN” kann der Besteller das
          <br />ausgewählte Produkt in den virtuellen Warenkorb legen. Dieser Vorgang ist
          unverbindlich. Der <br />Besteller kann die im Warenkorb angezeigten Produkte durch
          Anklicken des "Entfernen- Buttons" <br />wieder aus dem Warenkorb entfernen. Mit dem
          Einleiten des Bestellvorganges über den Button <br />"WEITER ZUR KASSE" oder den BUTTON
          "BESTELLEN" wird der Besteller zur Eingabe der <br />persönlichen Daten sowie ggf. Auswahl
          der Zahlungsart aufgefordert. Die lediglich optionalen <br />Angaben sind entsprechend
          gekennzeichnet. Der Besteller kann sämtliche seiner Daten <br />einschließlich der Waren
          aus dem Warenkorb in den jeweiligen Eingabefeldern bzw. über den <br />Zurück-Button
          seines Browsers korrigieren. Mit Betätigung des Buttons "ZAHLUNGSPFLICHTIG <br />BESTELLEN
          " gibt der Besteller ein verbindliches Kaufangebot für die im Warenkorb enthaltenen
          <br />Artikel ab (Kaufangebot/ Bestellung).
          <b></b>
          <br />
          <br />2.3. <b></b>Der Anbieter wird dem Besteller den Zugang seines verbindlichen
          Kaufangebots/ <br />Bestellung unverzüglich per E-Mail bestätigen (Zugangsbestätigung).
          Diese Zugangsbestätigung <br />stellt noch keine verbindliche Annahme der Bestellung des
          Kunden dar. Ein Vertragsabschluss <br />mit dem Anbieter und damit eine vertragliche
          Bindung über die einzelnen Leistungen kommt, <br />abhängig von der gewählten Zahlungsart,
          zustande wenn: <br />a) bei der gewählten Zahlungsart <i>"Vorkasse"</i>: der Anbieter das
          Angebot des Bestellers binnen 7 <br />Werktagen nach Zugang der Bestellung ausdrücklich
          durch Versand einer Annahme,- oder <br />Bestellbestätigung (i.d.R. per E-Mail), in
          welchem dem Besteller die Bankverbindung zur Zahlung <br />des Kaufpreises mitgeteilt
          wird, angenommen hat, oder <br />b) bei der gewählten Zahlungsart <i>"PayPal(Plus)"</i>:
          der Besteller auf den Webseiten des <br />Zahlungsdienstleisters PayPal nach Eingabe
          seiner Zahlungs,- und ggf. Zugangsdaten die
          <br />
          Zahlungsanweisung an PayPal bestätigt hat, oder
          <br />c) bei der gewählten Zahlungsart <i>"Kreditkarte"</i>: der Besteller nach Eingabe
          seiner <br />Kreditkartendaten und ggf. weiterer Daten zu seiner Legitimierung die
          Zahlungsanweisung an <br />das Kreditkartenunternehmen bestätigt hat oder der Anbieter das
          Angebot des Bestellers binnen <br />7 Werktagen nach Zugang der Bestellung ausdrücklich
          durch Versand einer Annahme,- oder <br />Bestellbestätigung (i.d.R. per E-Mail) oder durch
          die tatsächliche Lieferung der Ware an den <br />Besteller angenommen hat (maßgeblich für
          den Zeitpunkt der Annahme ist die erste eingetretene <br />Alternative); andernfalls gilt
          das Angebot als abgelehnt und der Besteller ist daran nicht länger <br />gebunden. Ein
          Anspruch auf Vertragsabschluss besteht nicht.
          <br />
          <br />
          <b>3. Speicherung des Vertragstextes, Vertragssprache</b>
          <br />
          <br />3.1. Der Vertragstext wird vom Anbieter gespeichert und dem Besteller, inkl. seiner
          <br />Bestelldaten und der AGB per E-Mail zugesendet. Die AGB können Sie jederzeit auch
          unter <br />www.hoots.de/agb einsehen. Sofern der Besteller bei der Bestellung sein
          Benutzerkonto für die <br />Webseiten des Anbieters genutzt hat, kann der Besteller den
          Vertragstext und sämtliche Daten <br />seiner Bestellung und vergangenen Bestellungen
          zusätzlich dort einsehen. Im Übrigen sind die <br />Bestelldaten aus Sicherheitsgründen
          nicht mehr über das Internet zugänglich.
          <br />
          <br />3.2. Für die Bestellung und als Vertragssprache steht ausschließlich deutsch zur
          Verfügung.
          <b>
            <br />
            <br />4.Widerrufsrecht für Verbraucher
          </b>
          <br />
          <br />Verbrauchern steht das gesetzliche Widerrufsrecht, wie in der Widerrufsbelehrung
          beschrieben, <br />zu. Die Widerrufsbelehrung ist unter hoots.store/widerruf.aspx
          einzusehen. Unternehmern wird <br />kein freiwilliges Widerrufsrecht eingeräumt.
          <b></b>
          <br />
          <br />
          <b>5. Preise, Versandkosten, Fälligkeit, Zahlungsmodalitäten</b>
          <br />
          <br />5.1. Die auf den Produktseiten angegebenen Preise sind Endverbraucherpreise und
          <br />beinhalten die jeweils gültigen gesetzlichen Mehrwertsteuersätze und verstehen sich
          <br />grundsätzlich zzgl. Versandkosten. Soweit in der Produktbeschreibung oder dem
          Bestellvorgang <br />nicht anders angegeben, fallen pro Bestellung einmalig folgende
          Versandkostenpauschalen an: <br />a) Versand innerhalb Deutschlands und innerhalb der EU:
          kostenfrei <br />b) Versand in alle sonstigen Länder (International):€ 30,00
          <br />
          <br />Bei Teillieferungen fällt die Pauschale jeweils nur einmal an. Besteht ein
          Widerrufsrecht und wird <br />von diesem Gebrauch gemacht, trägt der Kunde die Kosten der
          Rücksendung.
          <br />
          <br />5.2. Die bei Vertragsschluss vereinbarten Entgelte (Kaufpreis) werden, sofern
          nachfolgend <br />nicht abweichend angegeben, unmittelbar mit Vertragsschluss fällig und
          sind ohne Abzug <br />zahlbar.
          <br />
          <br />5.3. Soweit beim Bestellvorgang nicht anders angegeben, kann der Kaufpreises
          wahlweise <br />per Vorkasse, Kreditkarte, oder PayPal (Plus) bezahlt werden. Der Anbieter
          behält sich vor, für <br />ausgewählte Produkte nur ausgewählte Bezahlmethoden anzubieten,
          beispielweise zur <br />Absicherung unseres Kreditrisikos nur Vorkasse.
          <br />
          <br />5.4. Soweit im Bestellvorgang oder der Zugangsbestätigung nicht abweichend
          angegeben, gilt <br />folgendes:
          <br />
          <br />a) Bei Zahlung mit Vorkasse ist der Kaufpreis innerhalb von 7 Tagen nach
          Vertragsschluss (vgl. <br />Ziffer 2.3 dieser AGB) auf das in der Annahme,- oder
          Bestellbestätigung benannte Konto zu <br />überweisen. Die Lieferung der bestellten Ware
          erfolgt nach Zahlungseingang gemäß Ziffer 6
          <br />
          dieser AGB.
          <br />b) Bei Zahlung per Kreditkarte übermitteln Sie uns mit Abgabe der Bestellung
          gleichzeitig ihre <br />Kreditkartendaten. Nach Ihrer Legitimation als rechtmäßiger
          Kontoinhaber fordern wir ihr <br />Kreditkartenunternehmen unmittelbar nach Bestellung der
          Ware zur Einleitung der <br />Zahlungstransaktion auf. Durch das Kreditkartenunternehmen
          wird die Zahlungstransaktion <br />automatisch durchgeführt und Ihr Konto belastet. Im
          Fall einer Zurückweisung der <br />Kreditkartenabbuchung verpflichtet sich der Besteller,
          nach Erhalt der Leistung innerhalb von 10 <br />Tagen den Kaufpreis zzgl. eventuell
          angefallener Kosten zu zahlen. Zu diesen Kosten gehören <br />u.a. die Kosten, die
          aufgrund des Widerrufs der Kreditkartenabbuchung entstehen. <br />c) Im Rahmen der
          Zahlungsmöglichkeit "PayPal Plus" (PayPal Europe S.à.r.l. et Cie, S.C.A., 22- <br />24
          Boulevard Royal, L-2449 Luxembourg) kann der Besteller zwischen den Zahlungsmethoden
          <br />"PayPal" oder "Kreditkarte" als PayPal-Services wählen, ohne dass er sich dafür bei
          PayPal mit <br />einem PayPal- Kundenkonto registrieren muss (ausgenommen Zahlung per
          PayPal). Bei <br />Auswahl von PayPal (Plus) wird der Besteller im Bestellprozess auf die
          Webseite des <br />Zahlungsdienstleisters "PayPal" weitergeleitet, die automatisiert den
          Zahlungsvorgang <br />übernimmt. Dort kann der Besteller nach Eingabe seiner Zahlungs,-
          und ggf. Zugangsdaten und <br />weiterer Daten zu seiner Legitimierung die
          Zahlungsanweisung an PayPal und die Verwendung <br />seiner Daten durch PayPal bestätigen.
          Für die Zahlungsabwicklung über PayPal gelten
          <br />
          <br />– ergänzend zu unseren AGB – die Nutzungsbedingungen und die Datenschutzerklärung
          von <br />PayPal, einsehbar unter
          <a href="https://www.paypal.com/">www.paypal.com.</a>
          <br />
          <br />
          <i>- PayPal Plus: PayPal</i> Bei Auswahl der Zahlungsart PayPal muss der Besteller, um den
          <br />Rechnungsbetrag über sein PayPal-Konto bezahlen zu können, dort registriert sein
          bzw. sich erst <br />registrieren und mit seinen Zugangsdaten legitimieren. Die
          Zahlungstransaktion wird durch <br />PayPal automatisch durchgeführt.
          <br />
          <br />
          <i>- PayPal Plus: Kreditkarte</i> Die Zahlungstransaktion wird unmittelbar nach
          Bestätigung der <br />Zahlungsanweisung und nach Ihrer Legitimation als rechtmäßiger
          Karteninhaber von Ihrem <br />Kreditkartenunternehmen auf Aufforderung von PayPal
          durchgeführt und Ihre Karte belastet.
          <br />
          <br />5.5. Kommt der Besteller in Zahlungsverzug, so ist der Anbieter berechtigt, die
          gesetzlichen <br />Verzugszinsen gemäß § 288 BGB zu fordern. Für jede Mahnung, die nach
          Verzugseintritt an den <br />Besteller versandt wird, kann dem Besteller eine Mahngebühr
          in Höhe von 2,50,- Euro in <br />Rechnung gestellt werden. Dem Besteller steht der
          Nachweis frei, dass dem Anbieter keine oder <br />nur wesentlich geringere Kosten
          entstanden sind. Falls der Anbieter ein höherer Verzugsschaden <br />nachweisbar
          entstanden ist, ist der Anbieter berechtigt, diesen geltend zu machen.
          <br />
          <br />5.6. Der Besteller stimmt der Übersendung der Rechnung als elektronische Rechnung
          <br />(Rechnung, die in einem elektronischen Format ausgestellt und empfangen wird, z.B.
          als PDF- <br />Dokument) per E-Mail zu senden. Der Anbieter kann nach eigenem Ermessen die
          Rechnung <br />auch auf Papier an den Besteller übersenden.
          <b></b>
          <br />
          <br />
          <b>
            6.Versendung der Ware, Selbstbelieferungsvorbehalt mit Rücktrittsrecht,
            <br />Transportschäden
          </b>
          <br />
          <br />6.1. Sofern in der Produktbeschreibung oder dem Bestellvorgang nicht anders
          angegeben, <br />erfolgt die Lieferung der Waren an die vom Besteller angegebene
          Lieferadresse (innerhalb <br />Deutschlands) spätestens innerhalb von 10 Werktagen,
          (außerhalb Deutschlands innerhalb von <br />spätestens 15 Werktagen). Die Frist beginnt
          bei Zahlung per Vorkasse am Tag nach Erteilung <br />des Zahlungsauftrags an das
          überweisende Kreditinstitut bzw. bei anderen Zahlungsarten am <br />Tag nach
          Vertragsschluss zu laufen und endet mit dem Ablauf des letzten Tages der Frist. Fällt
          <br />der letzte Tag der Frist auf einen Samstag, Sonntag oder einen am Lieferort
          staatlich <br />anerkannten allgemeinen Feiertag, so tritt an die Stelle eines solchen
          Tages der nächste
          <br />
          Werktag.
          <br />
          <br />6.2. Die Lieferung von digitalen Inhalten erfolgt durch Übermittlung des digitalen
          Inhalts an die <br />vom Besteller mitgeteilte (E-Mail-)Adresse oder durch dessen
          Bereitstellung zum Download bzw. <br />durch Übermittlung der jeweiligen Zugangsdaten.
          <br />
          <br />6.3. Sollten nicht alle bestellten Waren vorrätig sein, ist der Anbieter zu
          Teillieferungen <br />berechtigt, soweit dies dem Besteller zumutbar ist. Sollte ein
          bestellter Artikel ausnahmsweise <br />nicht lieferbar sein, weil der Anbieter von seinem
          Lieferanten ohne Verschulden trotz dessen <br />vertraglicher Verpflichtung nicht
          beliefert worden ist, ist der Anbieter zum Rücktritt von dem <br />Vertrag berechtigt. In
          diesem Fall wird der Anbieter den Besteller unverzüglich darüber <br />informieren, dass
          die bestellte Ware nicht mehr verfügbar ist und etwaige schon erbrachte <br />Leistungen
          unverzüglich erstatten.
          <br />
          <br />6.4. Für Verbraucher gilt: Werden Waren mit offensichtlichen Transportschäden
          angeliefert <br />wird der Besteller gebeten, dies möglichst sofort beim jeweiligen
          Zusteller zu reklamieren und <br />nachfolgend mit dem Anbieter Kontakt aufzunehmen. Die
          Versäumung einer Reklamation oder <br />Kontaktaufnahme hat für die gesetzlichen Ansprüche
          des Bestellers und deren Durchsetzung, <br />insbesondere Gewährleistungsrechte, keinerlei
          Konsequenzen.
          <br />
          <br />6.5. Kauft der Besteller als Unternehmer, geht die Gefahr des zufälligen Untergangs
          und der <br />zufälligen Verschlechterung auf diesen über, sobald die Sache dem Spediteur,
          dem Frachtführer <br />oder der sonst zur Ausführung der Versendung bestimmten Person oder
          Anstalt übergeben <br />wurde. Unter Kaufleuten gilt die in § 377 HGB geregelte
          Untersuchungs- und Rügepflicht es sei <br />denn, dass es sich um einen Mangel handelt,
          der bei der Untersuchung nicht erkennbar war. <br />Dies gilt nicht, falls der Anbieter
          den Mangel arglistig verschwiegen hat.
          <br />
          <br />
          <b>7. Eigentumsvorbehalt</b>
          <br />
          <br />Die Ware bleibt bis zur vollständigen Bezahlung im Eigentum des Anbieters.
          <b>
            <br />
            <br />8. Gewährleistung
          </b>
          <br />
          <br />8.1. Ist die gelieferte Ware mangelhaft, richtet sich die Gewährleistung des
          Anbieters, soweit <br />nicht ausdrücklich anderes vereinbart, nach den gesetzlichen
          Bestimmungen (§§ 433 ff. BGB).
          <br />
          <br />8.2 Kauft der Besteller als Unternehmer im Sinne von § 14 BGB, ist die
          Gewährleistung auf <br />1 Jahr begrenzt und der Anbieter ist berechtigt, die Ware nach
          seiner Wahl nachzubessern oder <br />neu zu liefern. Gegenüber Unternehmern gelten nur
          solche Angaben und Produktbeschreibungen <br />des Anbieters als Vereinbarung über die
          Beschaffenheit der Ware, die in den Vertrag einbezogen <br />wurden; für öffentliche
          Äußerungen des Anbieters oder sonstige Werbeaussagen übernimmt der <br />Anbieter keine
          Haftung.
          <br />
          <br />
          <b>9. Haftung</b>
          <br />
          <br />9.1. Bei der Verletzung von Pflichten, deren Einhaltung für die Erreichung des
          <br />Vertragszweckes von besonderer Bedeutung ist (Kardinalspflichten) haftet der
          Anbieter für <br />verschuldete Schäden unbeschränkt. Gleiches gilt für die Haftung für
          Schäden wegen des <br />Fehlens zugesicherter Eigenschaften, bei der Verletzung des
          Lebens, des Körpers oder der <br />Gesundheit sowie bei Ansprüchen nach dem
          Produkthaftungsgesetz. Im Übrigen haftet der <br />Anbieter nur bei Vorsatz und grober
          Fahrlässigkeit und soweit der Anbieter eine entsprechende <br />Garantie abgegeben hat.
          <br />
          <br />9.2. Im Falle einer Schadensersatzpflicht wegen der fahrlässigen Verletzung von
          <br />
          Kardinalpflichten ist die Haftung der Höhe nach auf den Schaden begrenzt, mit dessen
          Entstehen
          <br />der Anbieter nach den bei Vertragsschluss bekannten Umständen typischerweise rechnen
          <br />musste. Schäden, die nicht an der Ware selbst entstanden sind, insbesondere
          entgangener <br />Gewinn oder sonstige Vermögensschäden, werden in diesem Fall
          typischerweise nicht ersetzt.
          <br />
          <br />9.3. Die vorstehenden Haftungsausschlüsse und -begrenzungen gelten auch zugunsten
          von <br />Mitarbeitern, Erfüllungsgehilfen und sonstigen Dritter, deren sich der Anbieter
          zur <br />Vertragserfüllung bedient.
          <br />
          <br />
          <b>10. Aufrechnung, Zurückbehaltungsrecht</b>
          <br />
          <br />10.1. Ein Recht zur Aufrechnung steht dem Besteller nur zu, wenn seine
          Gegenansprüche <br />rechtskräftig festgestellt oder unbestritten sind.
          <br />
          <br />10.2. Der Besteller ist zur Ausübung eines Zurückbehaltungsrechts gegenüber dem
          Anbieter <br />nur insoweit befugt, als sein Gegenanspruch auf dem gleichen
          Vertragsverhältnis beruht.
          <br />
          <br />10.3. Die Beschränkungen nach Ziffer 10.1. und 10.2. gelten nicht für Ansprüche des
          Bestellers <br />gegenüber dem Anbieter auf Rückabwicklung des Vertrages, die dem
          Besteller nach Ausübung <br />seines gesetzlichen Widerrufsrechts zustehen.
          <br />
          <br />
          <b>11. Alternative Streitbelegung</b>
          <br />
          <br />11.1 Die EU-Kommission stellt eine Plattform für außergerichtliche Streitschlichtung
          bereit. <br />Verbrauchern gibt dies die Möglichkeit, Streitigkeiten im Zusammenhang mit
          Ihrer Online- <br />Bestellung zunächst ohne die Einschaltung eines Gerichts zu klären.
          Die Streitbeilegungs- <br />Plattform ist unter dem externen Link
          <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a
          >erreichbar.
          <br />
          <br />11.2 Wir sind gesetzlich verpflichtet Sie auf unsere E-Mail-Adresse hinzuweisen.
          Diese <br />lautet: <a href="mailto:info@hoots.de">info@hoots.de.</a> Wir sind bemüht,
          eventuelle Meinungsverschiedenheiten aus unserem <br />Vertrag einvernehmlich beizulegen.
          Darüber hinaus sind wir zu einer Teilnahme an einem <br />Schlichtungsverfahren nicht
          verpflichtet und können Ihnen die Teilnahme an einem solchen <br />Verfahren leider auch
          nicht anbieten.
          <br />
          <br />
          <b>12. Anwendbares Recht, Salvatorische Klausel</b>
          <br />
          <br />12.1. Ist der Besteller Unternehmer findet auf die Geschäftsbeziehung zwischen dem
          Anbieter <br />und dem Besteller ausschließlich deutsches Recht unter Ausschluss des
          UN-Kaufrechts <br />Anwendung.
          <br />
          <br />12.2. Gegenüber Kaufleuten im Sinne des Handelsgesetzbuches, einer juristischen
          Person des <br />öffentlichen Rechts oder öffentlich-rechtlichem Sondervermögen, ist
          ausschließlicher <br />Gerichtsstand für alle Streitigkeiten aus dem Vertragsverhältnissen
          zwischen dem Anbieter und <br />dem Besteller der Geschäftssitz des Anbieters
          <br />
          <br />12.3. Sollten eine oder mehrere Bestimmungen dieser AGB unwirksam sein oder werden,
          wird <br />dadurch die Wirksamkeit der übrigen Bestimmungen nicht berührt. Die unwirksame
          oder <br />undurchführbare Bestimmung soll in diesem Fall von den Vertragspartnern durch
          eine wirksame <br />Regelung ersetzt werden, welche dem wirtschaftlichen Zweck der
          unwirksamen oder <br />undurchführbaren Bestimmung möglichst nahe kommt. Gleiches gilt im
          Fall einer <br />Regelungslücke.
          <br />
          <br />Stand: Oktober 2019
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="close" text>Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["showDialog"],
  data() {
    return {};
  },
  methods: {
    close() {
      // emit close-event to parent component
      this.$emit("close");
    },
  },
};
</script>
