import Vue from "vue";

import { mean, sum } from "mathjs";
import format from "date-fns/format";
import fromUnixTime from "date-fns/fromUnixTime";
import cloneDeep from "lodash/cloneDeep";

import { AxiosProvider } from "@/service/fetch/axios.provider";
import { DevicesProvider } from "@/provider/data/devices.provider";
import { SessionsProvider } from "@/provider/data/sessions.provider";
import { LocalStorageProvider } from "@/service/storage/localstorage.provider";
import { ReverseGeoLookupProvider } from "@/service/geo/reverseGeoLookup.provider";

import { categorizeSessionsByVehicleId } from "@/utils/data/sessions.utils";

const deviceApiUrl = process.env.VUE_APP_DEVICES_API_URL;
const sessionApiUrl = process.env.VUE_APP_SESSION_API_URL;
const reverseGeoApiKey = process.env.VUE_APP_HERE_API_KEY;

const fetchProvider = new AxiosProvider();
const devicesProvider = new DevicesProvider(fetchProvider, deviceApiUrl);
const sessionsProvider = new SessionsProvider(fetchProvider, sessionApiUrl);
const reverseGeoProvider = new ReverseGeoLookupProvider(reverseGeoApiKey);
const localStorageProvider = new LocalStorageProvider();

const LOCALSTORAGE_KEYS = {
  SELECTED_VEHICLE_ID: "device:selected-vehicle-id",
};

const initialState = {
  loading: false,
  error: false,
  errortext: "",

  // V2
  isInitialized: false,

  activeSessionID: null,
  activeVehicleID: null,

  devices: [],
  sessions: [],

  sessionsByVehicleID: {},

  sensorDataBySessionID: {},
  geoDataBySessionID: {},

  lastDeviceLocationByDeviceID: {},

  // Temporary: This should be backend responsibility,
  // extracted into different objects to call reverse geo service only when necessary.
  sessionAddressDataBySessionID: {},
  lastDeviceAddressByDeviceID: {},
};

// initial state
const state = cloneDeep(initialState);

const getters = {
  avgMileage: (state, getters) => {
    const vehicleSessions = getters.getAllSessionsByActiveVehicle;
    return vehicleSessions.length > 0 ? mean(vehicleSessions.map((a) => a.distance)) : 0;
  },

  mileageTotal: (state, getters) => {
    const vehicleSessions = getters.getAllSessionsByActiveVehicle;
    return sum(vehicleSessions.map((session) => session.distance));
  },

  avgOperatingMinutes: (state, getters) => {
    const vehicleSessions = getters.getAllSessionsByActiveVehicle;
    return vehicleSessions.length > 0
      ? mean(vehicleSessions.map((session) => session.finished_at - session.started_at)) / 60
      : 0;
  },

  operatingHours: (state, getters) => getters.avgOperatingMinutes / 60,

  monthSessionCount: (state, getters) => {
    const vehicleSessions = getters.getAllSessionsByActiveVehicle;
    const sessionsInCurrentMonth = vehicleSessions.filter((session) => {
      const startedAtDate = fromUnixTime(session.started_at);
      const todayDate = new Date();
      const correctYear = startedAtDate.getFullYear() === todayDate.getFullYear();
      const correctMonth = startedAtDate.getMonth() === todayDate.getMonth();
      return correctYear && correctMonth;
    });
    return sessionsInCurrentMonth.length;
  },

  calendarSessionEvents: (state, getters) => {
    const vehicleSessions = getters.getAllSessionsByActiveVehicle;
    const sessionSet = vehicleSessions.reduce((set, session) => {
      set.add(format(fromUnixTime(session.started_at), "yyyy-MM-dd"));
      return set;
    }, new Set());
    return Array.from(sessionSet);
  },

  getSessionByID: (state) => (sessionID) =>
    state.sessions.find((session) => session.id === sessionID) || null,

  getActiveSessionID: (state) => state.activeSessionID,
  getActiveVehicleID: (state) => state.activeVehicleID,

  getNextSessionID: (state, getters) => {
    const vehicleSessions = getters.getAllSessionsByActiveVehicle;
    const currentSessionIndex = vehicleSessions.findIndex(
      (session) => session.id === state.activeSessionID
    );
    return currentSessionIndex !== -1 && currentSessionIndex < vehicleSessions.length - 1
      ? vehicleSessions[currentSessionIndex + 1].id
      : null;
  },
  getPrevSessionID: (state, getters) => {
    const vehicleSessions = getters.getAllSessionsByActiveVehicle;

    const currentSessionIndex = vehicleSessions.findIndex(
      (session) => session.id === state.activeSessionID
    );
    return currentSessionIndex !== -1 && vehicleSessions && currentSessionIndex > 0
      ? vehicleSessions[currentSessionIndex - 1].id
      : null;
  },
  getFirstSessionID: (state, getters) => {
    const vehicleSessions = getters.getAllSessionsByActiveVehicle;
    return vehicleSessions?.length > 0 ? vehicleSessions[0].id : null;
  },
  getLastSessionID: (state, getters) => {
    const vehicleSessions = getters.getAllSessionsByActiveVehicle;
    return vehicleSessions?.length > 0 ? vehicleSessions[vehicleSessions.length - 1].id : null;
  },

  getLastDeviceLocationByDeviceID: (state) => (deviceID) =>
    state.lastDeviceLocationByDeviceID[deviceID] || null,

  getAllSessionsByActiveVehicle: (state) => {
    return state.sessionsByVehicleID[state.activeVehicleID] || [];
  },

  getSessionSensorDataBySessionID: (state) => (sessionID) => {
    return state.sensorDataBySessionID[sessionID];
  },

  getActiveSessionSensorData: (state, getters) => {
    return getters.getSessionSensorDataBySessionID(state.activeSessionID) || {};
  },

  getAllVehicleIDsWithSessions: (state) => {
    return Object.entries(state.sessionsByVehicleID)
      .filter((vehicleSessionPair) => vehicleSessionPair[1]?.length > 0)
      .map((vehicleSessionPair) => Number(vehicleSessionPair[0]));
  },
  /**
   * @returns { (sessionID) => SessionGeoData | undefined}
   */
  getSessionGeoDataBySessionID: (state) => (sessionID) => state.geoDataBySessionID[sessionID],

  /**
   * @returns { SessionGeoData | null}
   */
  getActiveSessionGeoData: (state, getters) =>
    state.activeSessionID !== null
      ? getters.getSessionGeoDataBySessionID(state.activeSessionID) || null
      : null,

  /**
   * @returns { GeoJSON.FeatureCollection | null}
   */
  getActiveGeoRoute: (state, getters) => {
    const activeGeoData = getters.getActiveSessionGeoData || null;
    return activeGeoData !== null ? activeGeoData.route : null;
  },

  /**
   * @returns { GeoJSON.FeatureCollection | null}
   */
  getActiveGeoPoints: (state, getters) => {
    const activeGeoData = getters.getActiveSessionGeoData;
    return activeGeoData !== null ? activeGeoData.points : null;
  },

  getSessionAddressDataBySessionID: (state) => (sessionID) => {
    return state.sessionAddressDataBySessionID[sessionID] || null;
  },

  getLastDeviceAddressDataByDeviceID: (state) => (deviceID) => {
    return state.lastDeviceAddressByDeviceID[deviceID] || null;
  },
};

const actions = {
  async initialize({ state, commit, dispatch }) {
    if (!state.isInitialized) {
      commit("LOADING_STATE", true);

      await dispatch("setInitialVehicleAndSession");

      if (state.activeSessionID !== null) {
        await dispatch("setActiveSession", state.activeSessionID);
        await dispatch("loadAllDevicesLastKnownLocation");
        await dispatch("loadAllDevicesLastKnownAddress");
      }

      commit("SET_INITIALIZED", true);
      commit("LOADING_STATE", false);
    }
  },

  clearData({ commit }) {
    commit("CLEAR_DATA");
  },

  async setInitialVehicleAndSession({ state, commit, getters, dispatch }) {
    await dispatch("fetchAllDevices");
    await dispatch("loadSessionList", 0.8);

    const existingSelectedVehicleID = +localStorageProvider.getItem(
      LOCALSTORAGE_KEYS.SELECTED_VEHICLE_ID
    );

    const vehicleHasSessions =
      existingSelectedVehicleID &&
      getters.getAllVehicleIDsWithSessions.includes(existingSelectedVehicleID);

    if (vehicleHasSessions) {
      commit("SET_ACTIVE_VEHICLE", +existingSelectedVehicleID);
      commit("SET_ACTIVE_SESSION", getters.getLastSessionID);
    } else if (state.sessions.length > 0) {
      const lastSession = state.sessions[state.sessions.length - 1];
      commit("SET_ACTIVE_VEHICLE", lastSession.vehicle_id || null);
      commit("SET_ACTIVE_SESSION", lastSession.id || null);
    }
  },

  async fetchAllDevices({ commit }) {
    commit("LOADING_STATE", true);
    const fetchDevices = await devicesProvider.fetchAllDevices();
    if (fetchDevices.wasSuccessful()) {
      const devices = fetchDevices.getData();
      commit("SET_DEVICES", devices);
    } else {
      commit("SET_DEVICES", []);
    }
    commit("LOADING_STATE", false);
  },

  async updateDevice({ dispatch }, device) {
    const updateRequest = await devicesProvider.updateDevice(device);
    if (updateRequest.wasSuccessful()) {
      dispatch("fetchAllDevices");
    }
  },

  async registerDevice({ commit }, device) {
    commit("LOADING_STATE", true);

    const registerRequest = await devicesProvider.registerDevice(device);

    if (registerRequest.wasSuccessful()) {
      commit("LOADING_STATE", false);
      return Promise.resolve();
    } else {
      commit("LOADING_STATE", false);
      return Promise.reject(registerRequest.getMessage());
    }
  },

  async unregisterDevice({ commit }, serial) {
    commit("LOADING_STATE", true);

    const unregisterRequest = await devicesProvider.unregisterDevice({
      serial,
    });

    if (unregisterRequest.wasSuccessful()) {
      commit("LOADING_STATE", false);
    } else {
      commit("LOADING_STATE", false);
      return new Error(unregisterRequest.getMessage());
    }
  },

  async loadSessionList({ commit }, distance = 0.8) {
    commit("LOADING_STATE", true);

    const sessionListRequest = await sessionsProvider.loadSessionList(distance);

    if (sessionListRequest.wasSuccessful()) {
      const sessionList = sessionListRequest.getData();
      const sessionsByVehicle = categorizeSessionsByVehicleId(sessionList);
      commit("SET_SESSION_LIST", sessionList);
      commit("SET_SESSIONS_BY_VEHICLE", sessionsByVehicle);
    }

    commit("LOADING_STATE", false);
  },

  async loadSessionSensorAndGeoData({ getters, commit }, sessionID) {
    const dataAlreadyLoaded = getters.getSessionSensorDataBySessionID(sessionID) !== undefined;
    if (!dataAlreadyLoaded) {
      try {
        commit("LOADING_STATE", true);
        const session = getters.getSessionByID(sessionID);
        const fetchSensorData = await sessionsProvider.loadSessionData(session);
        if (fetchSensorData.wasSuccessful()) {
          const { sensorData, geoData } = fetchSensorData.getData();
          commit("SET_SESSION_SENSOR_DATA", { sessionID, sensorData });
          commit("SET_SESSION_GEO_DATA", { sessionID, geoData });
        }
      } catch (error) {
        console.error(error);
      } finally {
        commit("LOADING_STATE", false);
      }
    }
  },

  async setActiveVehicle({ getters, commit, dispatch }, vehicleID) {
    commit("SET_ACTIVE_VEHICLE", vehicleID);
    const lastSessionID = getters.getLastSessionID;
    if (lastSessionID) await dispatch("setActiveSession", lastSessionID);
    else commit("SET_ACTIVE_SESSION", null);
  },

  async setActiveSession({ commit, dispatch }, sessionID) {
    await dispatch("loadSessionSensorAndGeoData", sessionID);
    await dispatch("loadAddressesForSession", sessionID);
    commit("SET_ACTIVE_SESSION", sessionID);
  },

  async loadAllDevicesLastKnownLocation({ state, commit }) {
    commit("LOADING_STATE", true);

    const deviceIDs = state.devices.map((device) => device.id);

    const lastLocationsRequest = deviceIDs.map((deviceID) =>
      devicesProvider.fetchDeviceLastKnownLocation(deviceID)
    );

    const deviceLastLocationRequests = await Promise.all(lastLocationsRequest);

    deviceLastLocationRequests.forEach((request, index) => {
      if (request.wasSuccessful()) {
        const deviceID = state.devices[index].id;
        const lastKnownLocation = request.getData();
        commit("SET_DEVICE_LAST_KNOWN_LOCATION", {
          deviceID,
          lastKnownLocation,
        });
      }
    });

    commit("LOADING_STATE", false);
  },

  async loadAllDevicesLastKnownAddress({ state, dispatch }) {
    const deviceIDsWithLastLocation = Object.keys(state.lastDeviceLocationByDeviceID);
    const adressesFetchRequests = deviceIDsWithLastLocation.map((deviceID) => {
      dispatch("loadAddressForDeviceLastLocation", deviceID);
    });
    await Promise.all(adressesFetchRequests);
  },

  async loadAddressesForSession({ getters, commit }, sessionID) {
    const sessionGeoData = getters.getSessionGeoDataBySessionID(sessionID);

    if (!!sessionGeoData && sessionGeoData.coordinates) {
      commit("LOADING_STATE", true);

      const requestList = [];

      if (sessionGeoData.coordinates.start !== undefined) {
        requestList.push(
          reverseGeoProvider.lookupAdressForCoordinate({
            lat: sessionGeoData.coordinates.start.lat,
            lng: sessionGeoData.coordinates.start.lng,
          })
        );
      }

      if (sessionGeoData.coordinates.start !== undefined) {
        requestList.push(
          reverseGeoProvider.lookupAdressForCoordinate({
            lat: sessionGeoData.coordinates.end.lat,
            lng: sessionGeoData.coordinates.end.lng,
          })
        );
      }

      const [startAddress, endAddress] = await Promise.all(requestList);

      commit("SET_SESSION_ADDRESSES", {
        sessionID,
        startAddress: startAddress?.[0],
        endAddress: endAddress?.[0],
      });

      commit("LOADING_STATE", false);
    }
  },

  async loadAddressForDeviceLastLocation({ getters, commit }, deviceID) {
    const deviceLastLocation = getters.getLastDeviceLocationByDeviceID(deviceID);
    if (deviceLastLocation !== null) {
      commit("LOADING_STATE", true);
      const lastLocAddress = await reverseGeoProvider.lookupAdressForCoordinate({
        lat: deviceLastLocation.latitude,
        lng: deviceLastLocation.longitude,
      });
      commit("SET_DEVICE_LAST_KNOWN_ADDRESS", {
        deviceID,
        lastAddress: lastLocAddress?.[0],
      });
      commit("LOADING_STATE", false);
    }
  },
};

const mutations = {
  SET_INITIALIZED(state, isInitialized) {
    state.isInitialized = isInitialized;
  },
  LOADING_STATE(state, loading) {
    state.loading = loading;
  },
  SET_SESSION_LIST(state, payload) {
    state.sessions = payload;
  },
  SET_SESSIONS_BY_VEHICLE(state, sessionsByVehicle) {
    state.sessionsByVehicleID = sessionsByVehicle;
  },
  SET_ACTIVE_VEHICLE(state, vehicleID) {
    localStorageProvider.addItem(LOCALSTORAGE_KEYS.SELECTED_VEHICLE_ID, vehicleID);
    state.activeVehicleID = vehicleID;
  },
  SET_ACTIVE_SESSION(state, sessionID) {
    state.activeSessionID = sessionID;
  },
  SET_DEVICES(state, deviceIDs) {
    state.devices = deviceIDs;
  },
  SET_SESSION_SENSOR_DATA(state, { sessionID, sensorData }) {
    Vue.set(state.sensorDataBySessionID, sessionID, sensorData);
  },
  SET_SESSION_GEO_DATA(state, { sessionID, geoData }) {
    Vue.set(state.geoDataBySessionID, sessionID, geoData);
  },
  SET_SESSION_ADDRESSES(state, { sessionID, startAddress, endAddress }) {
    Vue.set(state.sessionAddressDataBySessionID, sessionID, {
      startAddress,
      endAddress,
    });
  },
  SET_DEVICE_LAST_KNOWN_LOCATION(state, { deviceID, lastKnownLocation }) {
    Vue.set(state.lastDeviceLocationByDeviceID, deviceID, lastKnownLocation);
  },
  SET_DEVICE_LAST_KNOWN_ADDRESS(state, { deviceID, lastAddress }) {
    Vue.set(state.lastDeviceAddressByDeviceID, deviceID, lastAddress);
  },

  CLEAR_DATA(state) {
    Object.entries(cloneDeep(initialState)).forEach(([key, value]) => {
      Vue.set(state, key, value);
    });
  },

  ERROR_STATE(state, error) {
    state.error = error;
  },
  SET_ERROR_TEXT(state, errortext) {
    state.errortext = errortext;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
