<template>
  <v-container fluid fill-height class="welcome" style="max-height: 100vh">
    <v-row justify="center">
      <v-card color="rgba(0, 0, 0, 0.66)">
        <v-card-title class="text-center justify-center font-weight-bold">{{
          $t("ui.welcome.title")
        }}</v-card-title>

        <v-stepper v-model="e6">
          <v-stepper-header>
            <v-stepper-step :complete="e6 > 1" step="1">{{
              $t("ui.welcome.step.privacyTerms")
            }}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e6 > 2" step="2">{{
              $t("ui.welcome.step.account")
            }}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e6 > 3" step="3">{{
              $t("ui.welcome.step.vehicle")
            }}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e6 > 4" step="4">{{
              $t("ui.welcome.step.finish")
            }}</v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12">
                <v-card-title>{{ $t("ui.welcome.step.legal") }}</v-card-title>
                <v-card-text>
                  <v-row justify="center" align="center">
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-checkbox v-model="privacy" color="primary">
                        <template v-slot:label>
                          {{ $t("ui.welcome.privacyPre") }}
                          &nbsp;
                          <a @click.prevent.stop="showPrivacyPolicy = true">
                            {{ $t("ui.welcome.privacy") }}
                          </a>
                          &nbsp;
                          {{ $t("ui.welcome.privacyPost") }}
                        </template>
                      </v-checkbox>
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                      <v-checkbox v-model="termsAndConditions" color="primary">
                        <template v-slot:label>
                          {{ $t("ui.welcome.termsPre") }}
                          &nbsp;
                          <a @click.prevent.stop="showTermsAndCondPolicy = true">
                            {{ $t("ui.welcome.terms") }}
                          </a>
                          &nbsp;
                          {{ $t("ui.welcome.termsPost") }}
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn @click="logout" text>{{ $t("ui.action.cancel") }}</v-btn>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    @click="e6 = 2"
                    :disabled="!(privacy && termsAndConditions)"
                    >{{ $t("ui.action.continue") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <!--  Username & Password-->
            <v-stepper-content step="2">
              <v-card class="mb-12">
                <v-card-text>
                  <v-form
                    v-model="userValid"
                    class="px-3"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                  >
                    <v-container>
                      <v-row>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                          <v-text-field
                            v-model="user.email"
                            :label="$t('ui.me.dialog.edit.email')"
                            placeholder="max@mustermann.de"
                            prepend-icon="email"
                            :rules="[required, emailValid]"
                            required
                            autofocus
                            autocomplete="off"
                            text
                            maxlength="80"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                          <v-text-field
                            v-model="user.phone"
                            :label="$t('ui.me.dialog.edit.phone')"
                            :placeholder="$t('ui.common.placeholder.user.phone')"
                            prepend-icon="phone"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                          <v-text-field
                            v-model="user.password"
                            :append-icon="showPassword1 ? 'visibility' : 'visibility_off'"
                            :label="$t('ui.dialog.password.label.new')"
                            :type="showPassword1 ? 'text' : 'password'"
                            :rules="[required, passwordValid]"
                            prepend-icon="lock"
                            required
                            @click:append="showPassword1 = !showPassword1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                          <v-text-field
                            v-model="confirmPassword"
                            :append-icon="showPassword2 ? 'visibility' : 'visibility_off'"
                            :type="showPassword2 ? 'text' : 'password'"
                            :rules="[required, passwordsMatch]"
                            :label="$t('ui.dialog.password.label.confirm')"
                            prepend-icon="verified_user"
                            required
                            @click:append="showPassword2 = !showPassword2"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn text @click="e6 = 1">{{ $t("ui.action.back") }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="e6 = 3" :disabled="!userValid">{{
                    $t("ui.action.continue")
                  }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <!--  Vehicle Settings -->
            <v-stepper-content step="3">
              <v-card class="mb-12">
                <v-card-text>
                  <v-form v-model="vehicleValid" class="px-3">
                    <v-container>
                      <v-row>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                          <v-text-field
                            v-model="vehicle.make"
                            :label="$t('ui.vehicle.dialog.edit.manufacturer')"
                            :placeholder="$t('ui.common.placeholder.car.brand')"
                            :counter="80"
                            :rules="[required, maxLength80]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                          <v-text-field
                            v-model="vehicle.model"
                            :label="$t('ui.vehicle.dialog.edit.model')"
                            placeholder="T1"
                            :counter="80"
                            :rules="[required, maxLength80]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                          <v-text-field
                            v-model="vehicle.name"
                            :label="$t('ui.vehicle.dialog.edit.name.label')"
                            :placeholder="$t('ui.vehicle.dialog.edit.name.placeholder')"
                            :counter="80"
                            :rules="[required, maxLength80]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                          <v-select
                            :items="vehicle.device_id ? [vehicle.device_id] : []"
                            v-model="vehicle.device_id"
                            :label="$t('ui.vehicle.dialog.edit.device')"
                            read-only
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                          <v-select
                            v-model="vehicle.combustion"
                            :items="fuelItems"
                            item-text="name"
                            item-value="value"
                            :label="$t('ui.vehicle.dialog.edit.fuel')"
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                          <v-text-field
                            v-model="vehicle.construction_year"
                            :label="$t('ui.vehicle.dialog.edit.yoc')"
                            placeholder="1911"
                            type="decimal"
                            mask="####"
                            :rules="[required, yoc]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                          <v-select
                            v-model="vehicle.stroke"
                            :items="strokeItems"
                            item-text="name"
                            item-value="value"
                            :label="$t('ui.vehicle.dialog.edit.stroke')"
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                          <v-text-field
                            v-model="vehicle.capacity"
                            :rules="[required, displacement]"
                            :label="$t('ui.vehicle.dialog.edit.displacement')"
                            type="decimal"
                            mask="#####"
                            placeholder="1999"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                          <v-text-field
                            v-model="vehicle.power"
                            :rules="[required, power]"
                            :label="$t('ui.vehicle.dialog.edit.power')"
                            type="decimal"
                            mask="####"
                            placeholder="110"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                          <v-select
                            v-model="vehicle.cylinders"
                            :items="[1, 2, 3, 4, 5, 6, 8, 10, 12, 16]"
                            :label="$t('ui.vehicle.dialog.edit.cylinder')"
                            required
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn text @click="e6 = 2">{{ $t("ui.action.back") }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="createEntities" :disabled="!vehicleValid"
                    >{{ $t("ui.action.continue") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-card class="mb-12">
                <v-card-text>
                  <v-card-title>{{ $t("ui.welcome.setupDone") }}</v-card-title>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="navigateToDashboard">{{
                    $t("ui.action.run")
                  }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
          <v-snackbar
            v-model="error"
            :timeout="6000"
            :bottom="true"
            :multi-line="true"
            color="red darken-2"
            >{{ errortext }}
          </v-snackbar>
        </v-stepper>

        <privacy :showDialog="showPrivacyPolicy" @close="showPrivacyPolicy = false" />
        <terms :showDialog="showTermsAndCondPolicy" @close="showTermsAndCondPolicy = false" />
      </v-card>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import Privacy from "@/components/PrivacyPolicy.vue";
import Terms from "@/components/TermsAndConditions.vue";

import { isValidYearOfManufacture } from "@/validators/vehicle.validators";
import {
  convertErrorCheckerOutputToStrings,
  ErrorStringMap,
} from "@/validators/generic.validators";
import {
  EMAIL_VALIDATION_ERROR,
  isEmailValid,
  isPasswordValid,
  PASSWORD_VALIDATION_ERROR,
} from "@/validators/auth.validators";
import i18n from "@/plugins/i18n.plugin";
import { isNotEmpty } from "@/validators/string.validator";

type VSelectItems<ValueType> = {
  name: string;
  value: ValueType;
}[];

const fuelItems: VSelectItems<number> = [
  {
    name: i18n.t("ui.vehicle.fuel.gasoline") as string,
    value: 0,
  },
  {
    name: i18n.t("ui.vehicle.fuel.diesel") as string,
    value: 1,
  },
];

const strokeItems: VSelectItems<number> = [
  {
    name: "2-" + i18n.t("ui.vehicle.dialog.edit.stroke"),
    value: 1,
  },
  {
    name: "4-" + i18n.t("ui.vehicle.dialog.edit.stroke"),
    value: 2,
  },
];

const emailErrorToStringMap: ErrorStringMap<EMAIL_VALIDATION_ERROR> = {
  [EMAIL_VALIDATION_ERROR.TOO_LONG]: String(i18n.t("ui.rule.length80")),
  [EMAIL_VALIDATION_ERROR.NO_REGEX_MATCH]: String(i18n.t("ui.rule.emailInvalid")),
};

const passwordErrorToStringMap: ErrorStringMap<PASSWORD_VALIDATION_ERROR> = {
  [PASSWORD_VALIDATION_ERROR.TOO_LONG]: String(i18n.t("ui.rule.length80")),
  [PASSWORD_VALIDATION_ERROR.TOO_SHORT]: String(i18n.t("ui.rule.passwordMin")),
  [PASSWORD_VALIDATION_ERROR.NO_REGEX_MATCH]: String(i18n.t("ui.rule.passwordPolicy")),
};

export default Vue.extend({
  components: {
    privacy: Privacy,
    terms: Terms,
  },
  data() {
    return {
      //////// COMMON ////////

      e6: 1,
      error: false,
      errortext: "",

      //////// TERMS & PRIVACY ////////

      showPrivacyPolicy: false,
      showTermsAndCondPolicy: false,

      privacy: false,
      termsAndConditions: false,

      //////// USER ////////

      user: {
        id: "",
        email: "",
        phone: "",
        password: "",
      },

      userValid: false,

      showPassword1: false,
      showPassword2: false,

      confirmPassword: "",

      //////// VEHICLE ////////

      vehicle: {
        name: "",
        make: "",
        model: "",
        stroke: 4,
        cylinders: 4,
        combustion: fuelItems[0].value,
        construction_year: "" as string | number,
        capacity: "" as string | number,
        power: "" as string | number,
        device_id: "",
        image: "",
      },

      vehicleValid: false,

      fuelItems,
      strokeItems,
    };
  },

  computed: {
    required() {
      return (string: string) => {
        return isNotEmpty(string) ? true : this.$t("ui.rule.required");
      };
    },

    emailValid() {
      return (email: string) =>
        convertErrorCheckerOutputToStrings(isEmailValid(email), emailErrorToStringMap);
    },

    passwordValid() {
      return (password: string) =>
        convertErrorCheckerOutputToStrings(isPasswordValid(password), passwordErrorToStringMap);
    },

    passwordsMatch() {
      return (password: string) => password === this.user.password || this.$t("ui.rule.mismatch");
    },

    maxLength80() {
      return (input: string) => (input && input.length < 80) || this.$t("ui.rule.length80");
    },

    yoc() {
      return (year: string) => {
        const yearInt = parseInt(year, 10);
        const isValidYear = isValidYearOfManufacture(yearInt);
        return isValidYear || this.$t("ui.rule.yoc");
      };
    },
    displacement() {
      return (value: string) =>
        (parseInt(value, 10) >= 49 && parseInt(value, 10) < 50000) ||
        this.$t("ui.rule.displacement");
    },
    power() {
      return (value: string) =>
        (parseInt(value, 10) > 0 && parseInt(value, 10) <= 1000) ||
        (i18n.t("ui.rule.power") as string);
    },
    filesize() {
      return (value: File) =>
        !value || value.size < 2 * 1024 * 1024 || (i18n.t("ui.rule.filesize") as string);
    },
  },
  created() {
    this.vehicle.device_id = this.$route.params.serial;
  },
  methods: {
    async logout(): Promise<void> {
      await this.$auth.logout();
      await this.$router.push("/");
    },

    async createUserAccount(): Promise<void> {
      const userId = this.$auth.getUserID();
      const deviceId = this.vehicle.device_id;

      if (userId && deviceId) {
        await this.$user.createUser({ ...this.user, id: userId }, deviceId);
      }
    },

    async createVehicle(): Promise<void> {
      const preparedVehicle = { ...this.vehicle };

      preparedVehicle.construction_year = +preparedVehicle.construction_year;
      preparedVehicle.capacity = +preparedVehicle.capacity;
      preparedVehicle.power = +preparedVehicle.power;

      await this.$store.dispatch("vehicle/create", preparedVehicle);
    },

    async createEntities(): Promise<void> {
      await this.createUserAccount();
      await this.createVehicle();
      this.e6 = 4;
    },

    async navigateToDashboard(): Promise<void> {
      this.$router.push("/");
    },
  },
});
</script>

<style scoped>
.welcome {
  background: url("https://hoots.de/wp-content/uploads/2019/01/hoots-header-start.jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
}
</style>
