<template>
  <v-footer
    height="32px"
    :color="isProduction ? undefined : 'orange darken-4'"
    app
    v-if="$route.name !== 'map'"
  >
    <span
      class="font-weight-light copyright"
      style="color: white; font-size: 0.7em"
    >
      <a href="https://hoots.de" style="color: white; text-decoration: none">
        {{ $t("hoots.company.copyright", { version, year }) }}
      </a>
    </span>
    <v-spacer />
  </v-footer>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    year: new Date().getFullYear(),
  }),
  computed: {
    isProduction(): boolean {
      return process.env.VUE_APP_VERCEL_ENV === "production";
    },
    isDevelopment(): boolean {
      return process.env.VUE_APP_VERCEL_ENV === "development";
    },
    version(): string {
      if (this.isProduction) return process.env.VUE_APP_VERSION || "";
      else if (this.isDevelopment) return "Develop";
      else return process.env.VUE_APP_VERCEL_GIT_COMMIT_REF || "";
    },
  },
});
</script>
