<template>
  <v-card>
    <v-card-text>
      <v-form v-model="validSignup">
        <v-text-field
          v-model="serial"
          :rules="[required, serialPolicy]"
          prepend-icon="router"
          name="serial"
          :label="$t('ui.dialog.signup.serial')"
          type="text"
          placeholder="123-45678"
          error-count="2"
        ></v-text-field>
        <v-text-field
          v-model="boxPassword"
          :rules="[required, passwordPolicy, passwordFirst]"
          prepend-icon="lock"
          :append-icon="showBoxPassword ? 'visibility' : 'visibility_off'"
          :type="showBoxPassword ? 'text' : 'password'"
          name="boxPassword"
          :label="$t('ui.dialog.signup.password')"
          @click:append="showBoxPassword = !showBoxPassword"
          error-count="3"
          @keyup.enter="signup"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="signup" color="green" :loading="loading" :disabled="!validSignup"
        >{{ $t("ui.action.signup") }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { isNotEmpty } from "@/validators/string.validator";
import { isPasswordValid } from "@/validators/auth.validators";

export default Vue.extend({
  name: "SignUpWithSerialCode",
  data: () => {
    return {
      serial: "",
      boxPassword: "",
      showBoxPassword: false,
      serialRegExp: /^[0-9]{3}-[0-9]{5}$/,
      validSignup: false,
      loading: false,
    };
  },
  computed: {
    required() {
      return (string: string) => (isNotEmpty(string) ? true : this.$t("ui.rule.required"));
    },
    serialPolicy() {
      return (serial: string) => this.serialRegExp.test(serial) || this.$t("ui.rule.serial");
    },
    passwordPolicy() {
      return (password: string) =>
        isPasswordValid(password) ? true : this.$t("ui.rule.passwordPolicy");
    },
    //initial Password must be 8 characters long
    passwordFirst() {
      return (password: string) => password.length === 8 || this.$t("ui.rule.passwordFirst");
    },
  },
  methods: {
    async navigateToWelcomeScreen() {
      try {
        await this.$router.push({
          name: "welcome",
          params: { serial: this.serial },
        });
      } catch (error) {
        this.$emit("error", error);
      }
    },
    async signup() {
      try {
        this.loading = true;
        const signUpAction = await this.$auth.signUpWithSerialAndPassword(
          this.serial,
          this.boxPassword
        );

        if (signUpAction.wasSuccessful()) await this.navigateToWelcomeScreen();
        else this.$emit("error", signUpAction.getMessage());
      } catch (error) {
        this.$emit("error", (error as Error).message);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
