<template>
  <l-layer-group layer-type="overlay" style="position: relative">
    <marker-popup
      v-if="startLocation !== null"
      :position="startLocation"
      :text="startMarkerHTML"
      :title="startMarkerHTML"
      :icon="srcIcon"
    />

    <marker-popup
      v-if="endLocation !== null"
      :position="endLocation"
      :text="endMarkerHTML"
      :title="startMarkerHTML"
      :icon="dstIcon"
    />

    <marker-popup
      :visible="showMaxPopup"
      :position="maxMarkerLocation"
      :text="maxText"
      :icon="maxIcon"
    />

    <marker-popup v-if="hoverLocation !== null" :position="hoverLocation" :icon="hoverIcon" />

    <l-geo-json :geojson="trip.route" :options-style="styleFunction" ref="geojay" />
    <l-geo-json :geojson="trip.points" :options="options2" />
  </l-layer-group>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

import type { LeafletMap } from "@/@types/ui/map/leafletmap.types";
import type { LatLng, GeoJSONOptions, Layer } from "leaflet";
import type { Position } from "geojson";
import type { ReverseGeo } from "@/@types/data/geo.types";
import type { UnixTimeStamp } from "@/@types/generic/datetime.types";
import type * as geojson from "geojson";
import type { Feature } from "geojson";

import { LLayerGroup, LGeoJson } from "vue2-leaflet";
import { mapIcons } from "@/maps/leaflet/leaflet.config";
import MarkerPopup from "@/components/base/map/LeafletMapMarkerWithPopup.vue";

import format from "date-fns/format";
import fromUnixTime from "date-fns/fromUnixTime";
import i18n from "@/plugins/i18n.plugin";

import { circleMarker, latLng } from "leaflet";
import { generateTripMarkerCustomHTML } from "@/helpers/ui/maps/generateMarkerCustomHTML";
import { chartEventBus, ChartEventID } from "@/events/chart.channel";

export default Vue.extend({
  name: "leaflet-map-route-layer",
  components: { LLayerGroup, LGeoJson, MarkerPopup },
  props: {
    trip: {
      type: Object as PropType<LeafletMap.Trip>,
      required: true,
    },
  },
  data() {
    return {
      showHoverIcon: false,

      hoverLocation: null as LatLng | null,

      showMaxPopup: false,
      maxLat: 0,
      maxLon: 0,
      maxText: "",
      maxTooltip: {},

      srcIcon: mapIcons.srcIcon,
      dstIcon: mapIcons.dstIcon,
      hoverIcon: mapIcons.hoverIcon,
      maxIcon: mapIcons.maxIcon,
    };
  },

  computed: {
    firstPointInRoute(): Position | null {
      return this.trip.route?.features[0].geometry.coordinates[0] || null;
    },

    lastPointInRoute(): Position | null {
      const numPoints = this.trip.route?.features[0].geometry.coordinates.length;
      return (
        (numPoints && this.trip.route?.features[0].geometry.coordinates[numPoints - 1]) || null
      );
    },

    startLocation(): LatLng | null {
      const startPoint = this.firstPointInRoute;
      return startPoint !== null ? latLng(startPoint[1], startPoint[0]) : null;
    },

    endLocation(): LatLng | null {
      const endPoint = this.lastPointInRoute;
      return endPoint !== null ? latLng(endPoint[1], endPoint[0]) : null;
    },

    startMarkerHTML(): string {
      const addressString =
        this.trip.start.address !== undefined
          ? this.generateAddressStringFrom(this.trip.start.address)
          : "Unknown Location";

      const coordinateString =
        this.firstPointInRoute !== null
          ? this.generateCoordinateStringFrom(this.firstPointInRoute)
          : "Unknown Coordinate";

      const timeString =
        this.trip.start.time !== undefined
          ? this.generateTimeStringFrom(this.trip.start.time)
          : "Unknown Time";

      return generateTripMarkerCustomHTML(
        i18n.t("ui.map.beginTrip") as string,
        addressString,
        coordinateString,
        timeString
      );
    },

    endMarkerHTML(): string {
      const addressString =
        this.trip.end.address !== undefined
          ? this.generateAddressStringFrom(this.trip.end.address)
          : "Unknown Location";

      const coordinateString =
        this.lastPointInRoute !== null
          ? this.generateCoordinateStringFrom(this.lastPointInRoute)
          : "Unknown Coordinate";

      const timeString =
        this.trip.end.time !== undefined
          ? this.generateTimeStringFrom(this.trip.end.time)
          : "Unknown Time";

      return generateTripMarkerCustomHTML(
        i18n.t("ui.map.endTrip") as string,
        addressString,
        coordinateString,
        timeString
      );
    },

    maxMarkerLocation(): LatLng {
      return latLng(this.maxLat, this.maxLon);
    },

    styleFunction(): GeoJSONOptions["style"] {
      return () => {
        return {
          weight: 8,
          color: "#1f77b4",
          opacity: 0.7,
        };
      };
    },

    options2(): GeoJSONOptions {
      return {
        onEachFeature: this.onEachFeatureFunction2,
        pointToLayer: (feature, latlng) =>
          circleMarker(latlng, {
            radius: 8,
            weight: 0,
            fillOpacity: 0,
          }),
      };
    },

    onEachFeatureFunction2(): GeoJSONOptions["onEachFeature"] {
      return (feature: Feature<geojson.LineString>, layer) => {
        if (feature.properties?.["ts"] !== undefined) {
          const featureTimestamp: UnixTimeStamp = feature.properties["ts"];
          this.createTooltipContentAndBindToLayer(
            featureTimestamp,
            feature.properties["v_kmh"],
            layer
          );
          this.bindLayerEventsToEventbus(featureTimestamp, layer);
          // this.bindEventbusEventsToLayer(featureTimestamp, feature, layer);
        }
      };
    },
  },

  methods: {
    generateAddressStringFrom(reverseGeoItem: ReverseGeo.Item): string {
      const street = reverseGeoItem.address.street;
      const city = reverseGeoItem.address.city;
      return `${street}, ${city}`;
    },

    generateCoordinateStringFrom(position: Position): string {
      return `${position[1]}, ${position[0]}`;
    },

    generateTimeStringFrom(unixTimeStamp: UnixTimeStamp): string {
      return format(
        fromUnixTime(unixTimeStamp),
        i18n.locale == "de" ? "dd.MM.yyyy HH:mm:ss" : "MM/dd/yyyy HH:mm:ss"
      );
    },

    getClosestCoordinateToTimestamp(timestamp: UnixTimeStamp): LatLng | null {
      let closestDistance = Infinity;

      const featureIndex = this.trip.points.features.reduce((prev, curr, index) => {
        const distance = Math.abs(curr.properties?.["ts"] - timestamp);
        if (distance < closestDistance) {
          closestDistance = distance;
          return index;
        } else return prev;
      }, -1);

      return featureIndex > -1
        ? latLng(
            this.trip.points.features[featureIndex].geometry.coordinates[1],
            this.trip.points.features[featureIndex].geometry.coordinates[0]
          )
        : null;
    },

    createTooltipContentAndBindToLayer(timeStamp: UnixTimeStamp, speed: number, layer: Layer) {
      const timeString = `${i18n.t("ui.map.time")} ${format(
        fromUnixTime(timeStamp / 1000),
        "HH:mm:ss"
      )}`;
      const speedString = `${i18n.t("ui.map.speed")} ${speed} km/h`;
      layer.bindTooltip(`<div>${timeString}</div><div>${speedString}</div>`, {
        permanent: false,
        sticky: true,
      });
    },

    bindLayerEventsToEventbus(timeStamp: number, layer: Layer) {
      layer.on("mousemove", () => {
        chartEventBus.dispatch(ChartEventID.HOVER, { master: "map", xValue: timeStamp });
      });
    },

    handleChartHover(event: { master?: string; xValue: number }) {
      if (event.master !== "map")
        this.hoverLocation = this.getClosestCoordinateToTimestamp(event.xValue);
    },

    handleChartUnHover(event: unknown) {
      this.hoverLocation = null;
    },
  },

  mounted() {
    chartEventBus.subscribe(ChartEventID.HOVER, this.handleChartHover);
    chartEventBus.subscribe(ChartEventID.UNHOVER, this.handleChartUnHover);
  },

  beforeDestroy() {
    chartEventBus.unsubscribe(ChartEventID.HOVER, this.handleChartHover);
    chartEventBus.unsubscribe(ChartEventID.UNHOVER, this.handleChartUnHover);
  },
});
</script>
