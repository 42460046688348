<template>
  <div @click="handleClick" class="chart-container" ref="chartContainer">
    <div v-if="isFrozen" class="hover-catcher" :style="hoverCatcherDimensions" />
    <vue-plotly
      :id="chart.id"
      :data="chart.data"
      :layout="chart.layout"
      :options="plotlyOptions"
      :watch-shallow="false"
      class="plotly-chart"
      @relayout="emitRelayout"
      @hover="emitHover"
      @unhover="emitUnhover"
      @afterplot="handleAfterPlot"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import VuePlotly from "@statnett/vue-plotly";
import Plotly from "plotly.js";

import { chartEventBus, ChartEventID as Event, LayoutEvent } from "@/events/chart.channel";
import { HootsChart } from "@/charts/processor.plotly";
import { defaultPlotConfig } from "@/charts/config/config.plots.plotly";

import {
  getNewLayoutFor,
  getUnixTimestampsFromRelayoutEvent,
  getYAxisAutoRangeLayout,
} from "@/helpers/plotly/relayout.utils";
import { plotlyOptions } from "@/charts/config/config.layout.plotly";
import { uiEventBus, UIEventID } from "@/events/ui.channel";

// Plotly seems to add a timezone offset to the unix timestamps,
// so we have to remove and add the timezone offset for that date when using the event bus
const removeTimezoneOffset = (ts: number) => ts - new Date(ts).getTimezoneOffset() * 60 * 1000;
const addTimezoneOffset = (ts: number) => ts + new Date(ts).getTimezoneOffset() * 60 * 1000;

const defaultHoverCatcherDimensions: Partial<CSSStyleDeclaration> = {
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
};

export default Vue.extend({
  components: { VuePlotly },
  data() {
    return {
      isFrozen: false,
      handlingReset: false,
      defaultPlotConfig,
      plotlyOptions,

      timer: undefined as NodeJS.Timeout | undefined,
      clicks: 0,
      delay: 200,
      hoverAt: undefined as number | undefined,

      hoverCatcherDimensions: defaultHoverCatcherDimensions,
    };
  },
  props: {
    chart: { type: Object as PropType<HootsChart>, required: true },
    // hoverAt: { type: Number as PropType<num>, required: false },
  },
  computed: {},
  methods: {
    emitHover(data: { xvals: number[] }) {
      chartEventBus.dispatch(
        Event.HOVER,
        {
          master: this.chart.id,
          xValue: addTimezoneOffset(data.xvals[0]),
        },
        {
          retain: true,
        }
      );
    },

    emitUnhover(data: { event: { type: string } }) {
      if (data.event.type === "mouseout" && !this.isFrozen) {
        chartEventBus.dispatch(Event.UNHOVER, { master: this.chart.id });
      }
    },

    emitRelayout(data: Plotly.PlotRelayoutEvent) {
      if (this.handlingReset) return;
      if (data["xaxis.range[0]"] && data["xaxis.range[1]"]) {
        const relayoutBounds = getUnixTimestampsFromRelayoutEvent(data);
        chartEventBus.dispatch(Event.RELAYOUT, { master: this.chart.id, bounds: relayoutBounds });
      }
    },

    toggleFreeze() {
      this.isFrozen = !this.isFrozen;
      chartEventBus.dispatch(Event.FREEZE, { isFrozen: this.isFrozen }, { retain: true });
    },

    emitReset() {
      chartEventBus.dispatch(Event.RESET, { master: this.chart.id });
    },

    handleClick(): void {
      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          this.toggleFreeze();
          this.clicks = 0;
        }, this.delay);
      } else {
        this.timer && clearTimeout(this.timer);
        this.emitReset();
        this.clicks = 0;
      }
    },

    computeHoverCatcherDimensions() {
      const container = this.$refs.chartContainer as HTMLDivElement;
      const bgLayer = container.querySelector<SVGGElement>(`g.bglayer`);

      if (bgLayer) {
        const { x, y, width, height } = bgLayer.getBBox();

        this.hoverCatcherDimensions = {
          top: `${y}px`,
          left: `${x}px`,
          width: `${width}px`,
          height: `${height}px`,
        };
      }
    },

    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    handleLayoutEvent(event: LayoutEvent) {
      const layout = getNewLayoutFor(this.chart, event.bounds);
      Plotly.update(this.chart.id, {}, layout);
    },

    handleHover(event: { master?: string; xValue: number }) {
      //@ts-expect-error Plotly missing FX type
      Plotly.Fx.hover(this.chart.id, { xval: removeTimezoneOffset(event.xValue) }, "xy", true);
      this.hoverAt = event.xValue;
    },

    handleUnhover() {
      if (!this.isFrozen) {
        this.hoverAt = undefined;
        //@ts-expect-error Plotly missing FX type
        Plotly.Fx.hover(this.chart.id, [], "xy", true);
      }
    },

    async handleReset() {
      this.handlingReset = true;
      const resetLayout = getYAxisAutoRangeLayout(this.chart);
      await Plotly.relayout(this.chart.id, resetLayout);
      this.handlingReset = false;
    },

    handleFreeze(event: { isFrozen: boolean }) {
      this.isFrozen = event.isFrozen;
      if (this.isFrozen && this.hoverAt != undefined) this.updateIndicator();
      setTimeout(this.updateIndicator, 500);
    },

    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    updateIndicator() {
      // @ts-expect-error Plotly missing FX type
      Plotly.Fx.hover(
        this.chart.id,
        this.hoverAt ? { xval: removeTimezoneOffset(this.hoverAt) } : [],
        "xy",
        true
      );
    },

    handleWindowResize(event: unknown) {
      Plotly.Plots.resize(this.chart.id);
    },

    handleAfterPlot() {
      if (this.isFrozen) this.updateIndicator();
      this.computeHoverCatcherDimensions();
    },

    handleCarouselChartVisibleUpdate(event: { chartId: string }) {
      if (this.chart.id === event.chartId) Plotly.Plots.resize(this.chart.id);
    },

    handleLocaleUpdate() {
      Plotly.redraw(this.chart.id);
    },

    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    subscribeToEvents() {
      chartEventBus.subscribe(Event.HOVER, this.handleHover);
      chartEventBus.subscribe(Event.UNHOVER, this.handleUnhover);
      chartEventBus.subscribe(Event.RELAYOUT, this.handleLayoutEvent);
      chartEventBus.subscribe(Event.RESET, this.handleReset);
      chartEventBus.subscribe(Event.FREEZE, this.handleFreeze);

      uiEventBus.subscribe(UIEventID.CAROUSEL_CHART_VISIBLE, this.handleCarouselChartVisibleUpdate);
      uiEventBus.subscribe(UIEventID.UPDATE_LOCALE, this.handleLocaleUpdate);

      window.addEventListener("resize", this.handleWindowResize);
    },

    unsubscribeFromEvents() {
      chartEventBus.unsubscribe(Event.HOVER, this.handleHover);
      chartEventBus.unsubscribe(Event.UNHOVER, this.handleUnhover);
      chartEventBus.unsubscribe(Event.RELAYOUT, this.handleLayoutEvent);
      chartEventBus.unsubscribe(Event.RESET, this.handleReset);
      chartEventBus.unsubscribe(Event.FREEZE, this.handleFreeze);

      uiEventBus.unsubscribe(
        UIEventID.CAROUSEL_CHART_VISIBLE,
        this.handleCarouselChartVisibleUpdate
      );
      uiEventBus.unsubscribe(UIEventID.UPDATE_LOCALE, this.handleLocaleUpdate);

      window.removeEventListener("resize", this.handleWindowResize);
    },
  },
  created() {
    this.$nextTick(() => {
      Plotly.relayout(this.chart.id, {});
    });
  },
  mounted(): void {
    this.subscribeToEvents();
    this.computeHoverCatcherDimensions();
  },
  beforeDestroy() {
    this.unsubscribeFromEvents();
  },
});
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.hover-catcher {
  position: absolute;
  inset: 2rem;
  z-index: 2;
}

.plotly-chart {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}
</style>
