import { Async, Response } from "@/@types/helper/api.types";
import { Request, AuthProvider } from "@/service/auth/auth.interface";
import { SynchronousStorageInterface } from "@/service/storage/storage.interface";
import { FetchProvider } from "@/service/fetch/fetch.interface";
import { HootsAuthService } from "./hootsauth.types";
import { isExpired } from "./hootsauth.utils";

export class HootsAuthProvider implements AuthProvider {
  private readonly LOCAL_ID_STORAGE_KEY = "id";
  private readonly LOCAL_TOKEN_STORAGE_KEY = "token";

  private token: string | null = null;
  private userId: string | null = null;

  private readonly config: HootsAuthService.Config;
  private storageProvider: SynchronousStorageInterface<string, string>;
  private fetchProvider: FetchProvider;

  constructor(
    authConfig: HootsAuthService.Config,
    storageProvider: SynchronousStorageInterface<string, string>,
    fetchProvider: FetchProvider
  ) {
    this.storageProvider = storageProvider;
    this.fetchProvider = fetchProvider;
    this.config = authConfig;
  }

  async initialize(): Promise<void> {
    this.userId = this.loadExistingId();
    this.token = this.loadExistingToken();

    if (this.tokenValid()) {
      this.addAuthorizationTokenToFetchProvider();
      await this.runHooks("onLogin");
    } else {
      await this.logout();
    }
  }

  isLoggedIn(): boolean {
    return this.tokenValid();
  }

  getUserID(): string | null {
    return this.userId;
  }

  getSessionToken(): string | null {
    return this.token;
  }

  registerOnLoginHook(hook: () => void): void {
    this.config.hooks.onLogin.push(hook);
  }

  registerOnLogoutHook(hook: () => void): void {
    this.config.hooks.onLogout.push(hook);
  }

  async runHooks(action: keyof HootsAuthService.Config["hooks"]): Promise<void> {
    await Promise.all(this.config.hooks[action].map((hook) => hook()));
  }

  async loginWithEmailAndPassword(credentials: Request.EmailPasswordCredentials): Async<Response> {
    const loginResponse = await this.fetchProvider.post<
      Request.EmailPasswordCredentials,
      HootsAuthService.Response.LoginSuccess | HootsAuthService.Response.LoginError
    >(`${this.config.endpoint}/login`, credentials);

    if (loginResponse.wasSuccessful()) {
      const { id, token } = loginResponse.getData() as HootsAuthService.Response.LoginSuccess;
      this.setUserId(id);
      this.setSessionToken(token);
      this.addAuthorizationTokenToFetchProvider();
      await this.runHooks("onLogin");
      return new Response(true, loginResponse.getMessage());
    } else {
      await this.logout();
      return new Response(false, loginResponse.getMessage());
    }
  }

  async logout(): Async<Response> {
    try {
      this.userId = null;
      this.token = null;
      this.removeLocalId();
      this.removeLocalToken();
      this.removeAuthorizationTokenFromFetchProvider();
      await this.runHooks("onLogout");
      return new Response(true);
    } catch (e) {
      console.log(e);
      return new Response(false);
    }
  }

  async signUpWithSerialAndPassword(serial: string, password: string): Async<Response> {
    const url = `${this.config.endpoint}/signup`;

    const signUpResponse = await this.fetchProvider.post<
      HootsAuthService.Request.SignUpSerialPassword,
      | HootsAuthService.Response.SignUpDeviceCodeSuccess
      | HootsAuthService.Response.SignUpDeviceCodeError
    >(url, { serial, password });

    if (signUpResponse.wasSuccessful()) {
      const responseData =
        signUpResponse.getData() as HootsAuthService.Response.SignUpDeviceCodeSuccess;
      this.setUserId(responseData.id);
      this.setSessionToken(responseData.token);
      await this.runHooks("onLogin");
      return new Response(true, signUpResponse.getMessage());
    } else {
      const responseData =
        signUpResponse.getData() as HootsAuthService.Response.SignUpDeviceCodeError;
      return new Response(false, responseData.message);
    }
  }

  async checkResetPasswordToken(token: string): Async<Response> {
    const checkTokenEndpoint = `${this.config.endpoint}/password/reset/${token}`;

    const checkTokenAction = await this.fetchProvider.get<
      | HootsAuthService.Response.ResetPasswordTokenCheckSuccess
      | HootsAuthService.Response.ResetPasswordTokenCheckFailure
    >(checkTokenEndpoint);

    if (checkTokenAction.wasSuccessful()) {
      const { user_id } =
        checkTokenAction.getData() as HootsAuthService.Response.ResetPasswordTokenCheckSuccess;
      this.setUserId(user_id);
      return new Response(true, checkTokenAction.getMessage());
    } else {
      return new Response(false, checkTokenAction.getMessage());
    }
  }

  async sendResetPasswordMail(email: string): Async<Response> {
    const url = `${this.config.endpoint}/password/forget`;

    const sendResetPasswordEmailRequest = await this.fetchProvider.post<
      HootsAuthService.Request.SendResetPasswordEmail,
      | HootsAuthService.Response.PasswordResetMailSentSuccess
      | HootsAuthService.Response.PasswordResetMailSentFailure
    >(url, { email });

    return new Response(
      sendResetPasswordEmailRequest.wasSuccessful(),
      sendResetPasswordEmailRequest.getMessage()
    );
  }

  async resetPasswordWithToken(userId: string, password: string, token: string): Async<Response> {
    const url = `${this.config.endpoint}/password/reset`;

    const resetPasswordRequest = await this.fetchProvider.put<
      HootsAuthService.Request.ResetPassword,
      | HootsAuthService.Response.ResetPasswordSuccess
      | HootsAuthService.Response.ResetPasswordFailure
    >(url, { user_id: userId, password, resetToken: token });

    return new Response(resetPasswordRequest.wasSuccessful(), resetPasswordRequest.getMessage());
  }

  private tokenValid(): boolean {
    return this.token != null && !isExpired(this.token);
  }

  private setUserId(id: string): void {
    this.userId = id;
    this.storageProvider.addItem(this.LOCAL_ID_STORAGE_KEY, id);
  }

  private setSessionToken(token: string): void {
    this.token = token;
    this.storageProvider.addItem(this.LOCAL_TOKEN_STORAGE_KEY, token);
    this.addAuthorizationTokenToFetchProvider();
  }

  private loadExistingToken(): string | null {
    return this.storageProvider.getItem(this.LOCAL_TOKEN_STORAGE_KEY);
  }

  private removeLocalToken(): void {
    this.storageProvider.deleteItem(this.LOCAL_TOKEN_STORAGE_KEY);
  }

  private loadExistingId(): string | null {
    return this.storageProvider.getItem(this.LOCAL_ID_STORAGE_KEY);
  }

  private removeLocalId(): void {
    this.storageProvider.deleteItem(this.LOCAL_ID_STORAGE_KEY);
  }

  private addAuthorizationTokenToFetchProvider() {
    this.fetchProvider.addHTTPHeaderField("Authorization", "Bearer " + this.token);
  }

  private removeAuthorizationTokenFromFetchProvider() {
    this.fetchProvider.removeHTTPHeaderField("Authorization");
  }
}
