<template>
  <v-toolbar dense flat>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" :disabled="!selectFirstTripEnabled" @click="selectFirstTrip">
          <v-icon>mdi-page-first</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("ui.dashboard.tooltip.firstTrip") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="trip-location">
          <p v-if="addressData">{{ startStreetNumber }}</p>
        </div>
      </template>
      <span>{{ startLocation }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" :disabled="!selectPrevTripEnabled" @click="selectPrevTrip">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("ui.dashboard.tooltip.prevTrip") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          v-on="on"
          :disabled="!centerMapLastLocationEnabled"
          @click="centerMapToLastLocation"
        >
          <v-icon>my_location</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("ui.dashboard.tooltip.lastLocation") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" :disabled="!selectNextTripEnabled" @click="selectNextTrip">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("ui.dashboard.tooltip.nextTrip") }}</span>
    </v-tooltip>

    <v-spacer />

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="trip-location">
          <p v-if="addressData">{{ endStreetNumber }}</p>
        </div>
      </template>
      <span>{{ endLocation }}</span>
    </v-tooltip>

    <v-spacer />

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" :disabled="!selectLastTripEnabled" @click="selectLastTrip">
          <v-icon>mdi-page-last</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("ui.dashboard.tooltip.lastTrip") }}</span>
    </v-tooltip>
  </v-toolbar>
</template>
<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import type { Session, SessionAddressData } from "@/@types/data/session.types";

import {
  DashboardCommandID,
  DashboardEventID,
  dashboardEventBus,
} from "@/events/dashboard.channel";

export default Vue.extend({
  name: "hoots-map-control-toolbar",
  props: {
    centerMapLastLocationEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("device", [
      "getActiveSessionID",
      "getFirstSessionID",
      "getPrevSessionID",
      "getNextSessionID",
      "getLastSessionID",
      "getSessionAddressDataBySessionID",
    ]),

    addressData(): SessionAddressData | null {
      return this.getSessionAddressDataBySessionID(this.getActiveSessionID);
    },

    startStreetNumber(): string {
      const address = this.addressData?.startAddress?.address;
      return `${address?.street || ""}`;
    },

    startLocation(): string {
      const address = this.addressData?.startAddress?.address;
      return `${address?.street}, ${address?.postalCode} ${address?.city}, ${address?.countryName}`;
    },

    endStreetNumber(): string {
      const address = this.addressData?.endAddress?.address;
      return `${address?.street || ""}`;
    },

    endLocation(): string {
      const address = this.addressData?.endAddress?.address;
      return `${address?.street}, ${address?.postalCode} ${address?.city}, ${address?.countryName}`;
    },

    selectFirstTripEnabled(): boolean {
      return this.getFirstSessionID !== null && this.getFirstSessionID !== this.getActiveSessionID;
    },
    selectPrevTripEnabled(): boolean {
      return this.getPrevSessionID !== null;
    },
    selectNextTripEnabled(): boolean {
      return this.getNextSessionID !== null;
    },

    selectLastTripEnabled(): boolean {
      return this.getLastSessionID !== null && this.getLastSessionID !== this.getActiveSessionID;
    },
  },
  methods: {
    selectFirstTrip(): void {
      this.setActiveSession(this.getFirstSessionID);
    },
    selectPrevTrip(): void {
      this.setActiveSession(this.getPrevSessionID);
    },
    selectNextTrip(): void {
      this.setActiveSession(this.getNextSessionID);
    },
    selectLastTrip(): void {
      this.setActiveSession(this.getLastSessionID);
    },

    async setActiveSession(sessionID: Session["id"]): Promise<void> {
      dashboardEventBus.dispatch(DashboardEventID.LOADING_STATE_CHANGED, {
        loading: true,
      });
      await this.$store.dispatch("device/setActiveSession", sessionID);
      dashboardEventBus.dispatch(DashboardEventID.LOADING_STATE_CHANGED, {
        loading: false,
      });
    },

    centerMapToLastLocation() {
      dashboardEventBus.dispatch(DashboardCommandID.CENTER_MAP_TO_LAST_LOCATION, {});
    },
  },
});
</script>

<style scoped>
.trip-location {
  display: flex;
  align-content: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}

.trip-location p {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: default;
}
</style>
