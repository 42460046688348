import { SensorTypePlotMap } from "@/charts/config/config.data.plotly";

export interface PlotConfiguration {
  [plotId: string]: {
    meta: {
      type: "default" | "custom";
      name: string;
    };
    sources: {
      sensor: keyof SensorTypePlotMap;
      property: string;
    }[];
  };
}

export const defaultPlotConfig: PlotConfiguration = {
  oil1: {
    meta: {
      type: "default",
      name: "ui.charts.oil.title",
    },
    sources: [
      { sensor: "oil", property: "p_bar1" },
      { sensor: "oil", property: "t_c1" },
    ],
  },
  oil2: {
    meta: {
      type: "default",
      name: "ui.charts.oil2.title",
    },
    sources: [
      { sensor: "oil", property: "p_bar2" },
      { sensor: "oil", property: "t_c2" },
    ],
  },
  comp: {
    meta: {
      type: "default",
      name: "ui.charts.comp.title",
    },
    sources: [
      { sensor: "comp", property: "t_c1" },
      { sensor: "comp", property: "t_c2" },
    ],
  },
  cool: {
    meta: {
      type: "default",
      name: "ui.charts.cool.title",
    },
    sources: [
      { sensor: "cool", property: "t_c1" },
      { sensor: "cool", property: "t_c2" },
    ],
  },
  gps: {
    meta: {
      type: "default",
      name: "ui.charts.gps.title",
    },
    sources: [{ sensor: "gps", property: "v_kmh" }],
  },
  hot: {
    meta: {
      type: "default",
      name: "ui.charts.hot.title",
    },
    sources: [
      { sensor: "hot", property: "t_c1" },
      { sensor: "hot", property: "t_c2" },
    ],
  },

  lambda: {
    meta: {
      type: "default",
      name: "ui.charts.lambda.title",
    },
    sources: [
      { sensor: "lambda", property: "v_l1" },
      { sensor: "lambda", property: "v_l2" },
    ],
  },
  rpm: {
    meta: {
      type: "default",
      name: "ui.charts.rpm.title",
    },
    sources: [{ sensor: "rpm", property: "rpm" }],
  },
  imu: {
    meta: {
      type: "default",
      name: "ui.charts.imu.title",
    },
    sources: [
      { sensor: "imu", property: "ax" },
      { sensor: "imu", property: "ay" },
      { sensor: "imu", property: "az" },
    ],
  },
  voltages: {
    meta: {
      type: "default",
      name: "ui.charts.voltage.title",
    },
    sources: [
      { sensor: "voltages", property: "v_int" },
      { sensor: "voltages", property: "v_ext" },
    ],
  },
  ambient: {
    meta: {
      type: "default",
      name: "ui.charts.ambient.title",
    },
    sources: [
      { sensor: "ambient", property: "h_amb" },
      { sensor: "ambient", property: "p_amb" },
      // { sensor: "ambient", property: "t_amb" },
    ],
  },
};
