<template>
  <v-card>
    <v-card-text>
      <v-form v-model="validForgot">
        <v-text-field
          v-model="email"
          :rules="[required, emailValid]"
          prepend-icon="email"
          name="login"
          :label="$t('ui.dialog.login.email')"
          type="text"
          data-test="email"
          error-count="2"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="passwordReset"
        color="primary"
        :loading="loading"
        :disabled="!validForgot"
        data-test="reset"
        >{{ $t("ui.action.send") }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text small @click="cancel">{{ $t("ui.action.cancel") }} </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import { isNotEmpty } from "@/validators/string.validator";
import { EMAIL_VALIDATION_ERROR, isEmailValid } from "@/validators/auth.validators";
import {
  convertErrorCheckerOutputToStrings,
  ErrorStringMap,
} from "@/validators/generic.validators";
import i18n from "@/plugins/i18n.plugin";

export default Vue.extend({
  name: "ForgotPassword",
  props: {},
  data: () => {
    return {
      validForgot: false,
      email: "",
      loading: false,
    };
  },
  computed: {
    required() {
      return (string: string) => {
        return isNotEmpty(string) ? true : this.$t("ui.rule.required");
      };
    },
    emailValid() {
      const emailErrorToStringMap: ErrorStringMap<EMAIL_VALIDATION_ERROR> = {
        [EMAIL_VALIDATION_ERROR.TOO_LONG]: String(i18n.t("ui.rule.length80")),
        [EMAIL_VALIDATION_ERROR.NO_REGEX_MATCH]: String(i18n.t("ui.rule.emailInvalid")),
      };

      return (email: string) =>
        convertErrorCheckerOutputToStrings(isEmailValid(email), emailErrorToStringMap);
    },
  },
  methods: {
    async passwordReset(): Promise<void> {
      this.loading = true;
      const resetPasswordAction = await this.$auth.sendResetPasswordMail(this.email);

      this.loading = false;
      if (resetPasswordAction.wasSuccessful())
        this.$emit("success", i18n.t("ui.dialog.login.passwordReset.emailSent"));
      else this.$emit("error", resetPasswordAction.getMessage());
    },
    cancel() {
      this.$emit("cancel");
    },
  },
});
</script>
