export const universalBtoa = (str: string) => {
  try {
    return window.btoa(str);
  } catch (err) {
    return Buffer.from(str).toString("base64");
  }
};

export const universalAtob = (b64Encoded: string) => {
  try {
    return window.atob(b64Encoded);
  } catch (err) {
    return Buffer.from(b64Encoded, "base64").toString();
  }
};

interface JwtHeader {
  alg: string | Algorithm;
  typ?: string | undefined;
  cty?: string | undefined;
  crit?: Array<string | Exclude<keyof JwtHeader, "crit">> | undefined;
  kid?: string | undefined;
  jku?: string | undefined;
  x5u?: string | string[] | undefined;
  "x5t#S256"?: string | undefined;
  x5t?: string | undefined;
  x5c?: string | string[] | undefined;
}

export interface JwtPayload {
  [key: string]: unknown;
  iss?: string | undefined;
  sub?: string | undefined;
  aud?: string | string[] | undefined;
  exp?: number | undefined;
  nbf?: number | undefined;
  iat?: number | undefined;
  jti?: string | undefined;
}

export interface Jwt {
  header: JwtHeader;
  payload: JwtPayload;
  signature?: string;
}

function getTokenPayload(token: string): JwtPayload | null {
  if (token.includes(".")) {
    try {
      const payloadString = token.split(".")[1];
      return JSON.parse(universalAtob(payloadString));
    } catch (e) {
      console.error(e);
    }
  }

  return null;
}

const dummySignature = "kjsdfbiubiosdvuhsdvosdvnsodubvoosdjvb";

export function encodeDummyJwt(token: Jwt): string {
  const encodedHeader = universalBtoa(JSON.stringify(token.header));
  const encodedPayload = universalBtoa(JSON.stringify(token.payload));
  return `${encodedHeader}.${encodedPayload}.${dummySignature}`;
}

export function isExpired(token: string): boolean {
  const payload = getTokenPayload(token);
  if (payload == null || payload.exp == undefined) return true;
  else return payload.exp < Math.floor(Date.now() / 1000);
}
