<template>
  <v-container fluid fill-height class="login" style="max-height: 100vh">
    <v-row justify="center">
      <v-card color="rgba(0, 0, 0, 0.66)" width="700px" max-width="360px">
        <v-card-title class="text-center justify-center font-weight-bold">{{
          $t("ui.dialog.login.welcome")
        }}</v-card-title>

        <v-tabs v-model="activeTab" centered background-color="transparent" grow>
          <v-tab v-if="activeScreen === ViewScreen.LOGIN_SIGNUP_SCREEN" :key="ViewTab.LOGIN_TAB">{{
            $t("ui.action.login")
          }}</v-tab>
          <v-tab v-if="activeScreen === ViewScreen.LOGIN_SIGNUP_SCREEN" :key="ViewTab.SIGNUP_TAB">{{
            $t("ui.action.signup")
          }}</v-tab>
          <v-tab
            v-if="activeScreen === ViewScreen.FORGOT_PASSWORD_SCREEN"
            :key="ViewTab.FORGOT_PASSWORD_TAB"
            >{{ $t("ui.dialog.login.resetPassword") }}</v-tab
          >

          <v-tabs-items v-model="activeTab">
            <v-tab-item
              v-if="activeScreen === ViewScreen.LOGIN_SIGNUP_SCREEN"
              :key="ViewTab.LOGIN_TAB"
            >
              <LoginWithEmailPassword
                @forgot-password="openForgotPasswordScreen"
                @error="displayError"
              />
            </v-tab-item>
            <v-tab-item
              v-if="activeScreen === ViewScreen.LOGIN_SIGNUP_SCREEN"
              :key="ViewTab.SIGNUP_TAB"
            >
              <SignUpWithSerialCode @error="displayError" />
            </v-tab-item>
            <v-tab-item
              v-if="activeScreen === ViewScreen.FORGOT_PASSWORD_SCREEN"
              :key="ViewTab.FORGOT_PASSWORD_TAB"
            >
              <ForgotPassword
                @cancel="showLoginSignupScreen"
                @error="displayError"
                @success="passwordResetSuccess"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card>
    </v-row>

    <img class="image" src="/img/hoots-online-bg-50.png" alt="" />

    <v-snackbar :value="errorMessage" absolute color="red darken-2">
      {{ errorMessage }}
    </v-snackbar>
    <v-snackbar :value="successMessage" absolute color="green darken-2">
      Success: {{ successMessage }}
    </v-snackbar>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import LoginWithEmailPassword from "@/components/auth/login/LoginWithEmailPassword.vue";
import SignUpWithSerialCode from "@/components/auth/login/SignUpWithSerialCode.vue";
import ForgotPassword from "@/components/auth/login/ForgotPassword.vue";
import { timeout } from "@/utils/timing/timing.utils";

enum ViewScreen {
  LOGIN_SIGNUP_SCREEN = "loginSignUpScreen",
  FORGOT_PASSWORD_SCREEN = "forgotPasswordScreen",
}

enum ViewTab {
  LOGIN_TAB = "loginTab",
  SIGNUP_TAB = "signupTab",
  FORGOT_PASSWORD_TAB = "forgotPasswordTab",
}

export default Vue.extend({
  components: { ForgotPassword, SignUpWithSerialCode, LoginWithEmailPassword },
  data() {
    return {
      activeScreen: ViewScreen.LOGIN_SIGNUP_SCREEN as ViewScreen,
      activeTab: ViewTab.LOGIN_TAB as ViewTab,

      errorMessage: null as string | null,
      successMessage: null as string | null,

      ViewScreen,
      ViewTab,
    };
  },

  methods: {
    async displayMessage(type: "success" | "error", message: string) {
      this[type === "success" ? "successMessage" : "errorMessage"] = message;
      await timeout(5000);
      this[type === "success" ? "successMessage" : "errorMessage"] = null;
    },

    displayError(msg: string) {
      this.displayMessage("error", msg);
    },

    async passwordResetSuccess(msg: string) {
      await this.displayMessage("success", msg);
      this.showLoginSignupScreen();
    },

    showLoginSignupScreen() {
      this.activeScreen = ViewScreen.LOGIN_SIGNUP_SCREEN;
      this.activeTab = ViewTab.LOGIN_TAB;
    },

    openForgotPasswordScreen() {
      this.activeScreen = ViewScreen.FORGOT_PASSWORD_SCREEN;
      this.activeTab = ViewTab.FORGOT_PASSWORD_TAB;
    },
  },
});
</script>

<style scoped lang="scss">
.login {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.image {
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  object-fit: cover;
  z-index: -1;
}
</style>
