<template>
  <v-row justify="center">
    <v-dialog :value="showDialog" persistent scrollable max-width="700px">
      <v-card>
        <v-card-title class="headline">Datenschutzrichtlinie</v-card-title>

        <v-card-text>
          <b>Rechtliche Hinweise zum (Produkt-)Datenschutz</b>
          <br />
          <b></b>
          <br />im Zusammenhang mit der Bestellung und Nutzung unserer Produkte
          <br />
          <i>"hoots one box", "hoots App", "hoots cloud", "hoots GPS"</i>
          <b>
            <br />
            <br />Überblick
            <br />
          </b>
          <br />Mit der nachfolgenden Datenschutzerklärung möchten wir Sie über Art, Umfang und
          Zweck der <br />Erhebung, Speicherung und Verwendung (Verarbeitung) Ihrer
          personenbezogenen und sonstigen <br />Daten informieren, die wir im Zusammenhang mit dem
          Vertragsschluss oder der Nutzung unserer <br />Produkte " <i>hoots one box</i>",
          <i>"hoots App"</i>, <i>"hoots cloud", "hoots GPS"</i> verarbeiten.
          <br />
          <br />Als hoots-Kunde entscheiden Sie grundsätzlich selbst, ob Sie die von Ihrer
          <i>hoots one box</i> erfassten Betriebs- und Zustandsdaten (sowie ggf. Positions- und
          Bewegungsdaten) an Ihre <i>"hoots cloud"</i>übertragen oder ob Sie diese nur lokal und
          zeitlich beschränkt und damit ausschließlich auf Ihren eigenen Endgeräten speichern
          möchten. In diesem Fall werden wir die von Ihrer
          <i>hoots one box</i>
          erfassten Betriebs- und Zustandsdaten (sowie ggf. Positions- und Bewegungsdaten) ohne
          gesonderte Einwilligung weder speichern noch verarbeiten.
          <br />Nähere Angaben zur Datenverarbeitung bei der Nutzung unserer Produkte "
          <i>hoots one box</i>", <i>"hoots App"</i>, <i>"hoots cloud", "hoots GPS"</i>finden Sie
          nachfolgend unter Ziffer III. ausführlich beschrieben.
          <br />
          <br />Für die Datenverarbeitung bei Nutzung unserer Internetseiten, Onlinepräsenzen sowie
          die <br />Kommunikation außerhalb unserer Onlineangebote (z.B. per E-Mail, Fax etc.)
          gelten ergänzend die unter
          <a href="https://hoots.de/datenschutzerklaerung/">www.hoots.de/datenschutzerklaerung/</a
          >abrufbaren Datenschutzhinweise.
          <br />
          <br />
          <br />
          <b>I. Verantwortliche Stelle</b>
          <br />Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-
          Datenschutzgrundverordnung (DSGVO), ist:
          <br />
          <b>
            <br /> </b
          >hoots classic GmbH <br />Sebnitzer Straße 53 <br />01099 Dresden
          <br />
          <br />Tel.: 0049 (0) 351 810 810 25 <br />Email: info@hoots.de
          <br />
          <br />
          <b> <br />II. </b>
          <b>Datenverarbeitung bei Ihrer Produktanfrage oder Bestellung</b>
          <br />
          <br />1. Welche Daten verarbeiten wir? <br />Bei der Bearbeitung Ihrer Anfrage oder im
          Zusammenhang mit Ihrer Bestellung werden ggf. nachfolgende, personenbezogene sowie
          sonstige Vertrags- und Fahrzeugdaten, die wir bei Ihrer Anfrage, Ihrer Bestellung oder
          ggf. im weiteren Verlauf der Vertragsbeziehung von Ihnen erhalten, gespeichert und
          verarbeitet:
          <br />
          <br />
          <i>
            Persönliche Daten (Name, Anschrift etc.)
            <br />Kontaktdaten (E-Mail, Telefon etc.) <br />Daten zur Abwicklung des
            Zahlungsverkehrs <br />Allgemeine und technische Fahrzeugdaten
          </i>
          <br />
          <br />Die für die Vertragsabwicklung notwendigen Pflichtangaben sind in den
          Vertragsdokumenten jeweils gesondert markiert, alle weiteren Angaben sind freiwillig.
          <br />
          <br />2. Zweck und Rechtsgrundlage für die Verarbeitung Ihrer Daten: <br />Die
          Datenverarbeitung dient dem Zweck, Ihnen und uns die Bearbeitung Ihrer Anfrage, den
          Abschlusses eines Vertrages oder die Durchführung der vertraglichen Leistungen – insb.
          Durchführung der Lieferung und Verbuchung Ihrer Zahlung – an Sie zu ermöglichen (Art. 6
          Abs. 1 lit. b. DSGVO). <br />Darüber hinaus verarbeiten wir Ihre persönlichen Daten, wenn
          dies zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist
          und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an
          der Nichtweitergabe Ihrer Daten haben (Art. 6 Abs. 1 S. 1 lit. f DSGVO) sowie zur
          Erfüllung unserer gesetzlicher Aufbewahrungs- oder sonstiger Pflichten (Art. 6 Abs. 1 S. 1
          lit. c DSGVO).
          <br />
          <br />3. Wie lange werden die Daten gespeichert? <br />Ihre Daten werden regelmäßig mit
          Wegfal der Erforderlichkeit für die Durchführung des Vertrages und Ablauf ggf. darüber
          hinausgehender gesetzlicher (Aufbewahrungs-)Fristen (z.B. bis zu 10 Jahre nach
          Handelsgesetzbuch bzw. Abgabenordnung) gelöscht. Daten deren weitere Aufbewahrung zu
          Beweiszwecken erforderlich ist, sind von der Löschung ausgenommen bis zur endgültigen
          Klärung des jeweiligen Vorfalls. Werden Daten zu mehreren Zwecken verarbeitet so werden
          die Daten automatisch gelöscht oder in einer auf Sie nicht direkt rückführbaren Form
          gespeichert, sobald der letzte angegebene Zweck erfüllt wurde.
          <br />
          <br />4. Empfänger der Daten ? <br />>Empfänger der im Zusammenhang mit der Bearbeitung
          Ihrer Anfrage oder im Zusammenhang mit Ihrer Bestellung verarbeiteten Daten sind die
          Verantwortliche Stelle, unsere IT-Dienstleister (z.B. Rechenzentrumsbetreiber), unsere
          Logistikpartner zwecks Auslieferung Ihrer Bestellung sowie Zahlungsdienstleister und
          Kreditinstitute zur Abwicklung Ihrer Zahlung.
          <br />
          <br />Soweit wir uns hierzu Auftragsverarbeitern bedienen, wurden diese von uns sorgfältig
          ausgewählt und im Rahmen eines Auftragsverarbeitungsvertrages gemäß Art. 28 DSGVO strikt
          zur Einhaltung der (datenschutz-)rechtlichen Bestimmungen sowie an unsere sonstigen
          Weisungen gebunden. Einzelheiten zu den beauftragten Auftragsverarbeitern, deren Pflichten
          und die von diesen zum Schutz Ihrer Daten ergriffenen technischen- und organisatorischen
          Maßnahmen, stellen wir Ihnen auf Anfrage gern zur Verfügung.
          <br />
          <br />Bei besonderem Anlass übermitteln wir Ihre Daten zur Erfüllung unserer vertraglichen
          oder gesetzlichen Pflichten, zur Durchsetzung unserer Rechte oder zur Aufklärung und
          Verfolgung von Straftaten ggf. auch an weitere externe Empfänger folgender Kategorien:
          Steuerberater, Wirtschaftsprüfer, Inkassounternehmen, Rechtsanwälte, Gutachter, Gerichte,
          Behörden oder sonstige öffentliche Stellen.
          <br />
          <br />In allen Fällen der Weitergabe Ihrer personenbezogenen Daten beschränkt sich der
          Zweck und der Umfang der übermittelten Daten auf das Minimum, was zur Erfüllung unserer
          jeweiligen, rechtmäßigen Verarbeitungszwecke erforderlich ist.
          <br />
          <br />
          <br />
          <b>
            III. Datenverarbeitung bei der Nutzung Ihres
            <i>hoots one Systems</i>
          </b>
          <br />1. Welche Daten verarbeiten wir? <br />Im Zusammenhang mit der Nutzung unserer
          Produkte " <i>hoots one box</i>", <i>"hoots App"</i>,
          <i>
            "hoots cloud"
            <br />sowie "hoots GPS"
          </i>
          werden je nach Umfang des von Ihnen gebuchten Leistungspaketes ggf. nachfolgende,
          personenbezogene Daten und sonstige allgemeine und technische Fahrzeugdaten, die Sie uns
          zur Durchführung des Vertrages mitgeteilt haben, sowie Daten über die in Ihrem Fahrzeug
          verwendete <i>hoots one box</i> (Gerätedaten) erfasst, gespeichert und verarbeitet:
          <br />
          <br />Persönliche Daten: Benutzername, E-Mailadresse, Zugangspasswort, Telefonnummer
          <br />allgemeine Fahrzeugdaten: Marke, Modellbezeichnung <br />technische Fahrzeugdaten:
          Zylinderanzahl, Taktzahl, Verbrennungsprinzip, Baujahr, Hubraum <br />und Leistung Ihres
          Fahrzeugs <br />Gerätedaten: Geräte-ID und Seriennummer der <i>hoots one box</i>,
          Seriennummer <br />der <i>hoots one Sensoren</i>, IMEI und SIM-Nummer des GSM-Moduls,
          verwendete Softwareversionen.
          <br />
          <br />Wenn Sie uns mit dem Produkt <i>hoots-cloud</i> mit der Speicherung von Daten
          beauftragt haben, <br />werden zudem die von den in der <i>hoots one box</i> integrierten
          Sensoren erfassten Betriebs- und <br />Zustandsdaten (sowie im Zusammenhang mit der
          Nutzung des Produktes " <i>hoots GPS"</i> Positions- <br />und Bewegungsdaten) Ihres
          Fahrzeugs erfasst, gespeichert und verarbeitet:
          <br />
          <br />Betriebs- und Zustandsdaten: <br />Drehzahl, Öltemperatur, Öldruck,
          Batteriespannung, <br />Umgebungsbedingungen, Beschleunigungen, Gierraten, <br />sonstige
          Bauteiltemperaturen. <br />Positions- und Bewegungsdaten: <br />Längen- und Breitengrad,
          Geschwindigkeit.
          <br />
          <br />Wenn Sie (z.B. über die <i>hoots App</i> oder durch Einloggen auf dem
          <i>hoots-cloud Portal</i>) auf ihre <br />hoots-cloud zugreifen, werden neben den
          betreffenden Formulardaten (d.h. der Inhalt Ihrer <br />Übermittlung bzw. Abfrage) durch
          Ihr Endgerät automatisch Protokol daten an den Server gesendet <br />und dort zeitlich
          begrenzt in einer Datei (Logfile) gespeichert. Ohne Ihr Zutun werden dabei u.a.
          <br />folgende Informationen übertragen und bis zur automatischen Löschung gespeichert:
          <br />
          <br />- eine anonyme Session-ID, die keinen Rückschluss auf Ihre IP-Adresse ermöglicht,
          <br />- das von Ihnen genutzte Betriebssystem, den von Ihnen genutzten Web-Browser
          <br />(einschließlich installierter Add-Ons (z. B. für den Flash Player) und die von Ihnen
          <br />eingestellte Bildschirmauflösung, <br />- die anonymisierte IP-Adresse Ihres
          zugreifenden Endgeräts (z. B. PC oder Smartphone), <br />- das Datum und die Uhrzeit Ihres
          Besuchs, <br />- Beginn und Ende der Session <br />- die Größe der übertragenen Datenmenge
          und <br />- den http-Status-Code (z. B. "Anfrage erfolgreich" oder "angeforderte Datei
          nicht <br />gefunden")
          <br />
          <br />Des Weiteren verwenden wir wenige Cookies, wie nachstehend beschrieben:
          <br />
          <br />1. ___cfduid <br />Dieses Cookie wird vom CloudFlare-Dienst zum Identifizieren von
          vertrauenswürdigem Web- <br />Datenverkehr genutzt. Das Cookie speichert bzw. verarbeitet
          keine personenbezogenen Daten.
          <br />
          <br />2. XSRF-TOKEN und 3. hoots_session <br />Diese Cookies werden benutzt, um das
          sessionbasierte Loginsystem zu sichern. Um zum <br />Beispiel ein Sicherheits-Logout nach
          einer festgelegten Zeit durchzuführen.
          <br />
          <br />
          <br />2. Zweck und Rechtsgrundlage der Datenverarbeitung: <br />Die Datenverarbeitung
          erfolgt zu den Zwecken, Ihnen die Nutzung der Funktionen Ihres
          <i>
            hoots one
            <br />Systems, ggf. </i
          >einschließlich der Produkte <i>"hoots App"</i>, <i>"hoots cloud" sowie "hoots GPS"</i>,
          zu <br />ermöglichen und damit der Erfüllung unserer jeweiligen vertraglichen Pflichten
          Ihnen gegenüber <br />(Art. 6 Abs. 1 lit. b) DSGVO).
          <br />
          <br />Über die Erbringung unserer Leistungen hinaus verwenden wir die unter Ziffer III. 1.
          genannten <br />allgemeinen und technischen Fahrzeug,- Geräte-, Betriebs- und
          Zustandsdaten auch zum Zweck <br />und unserem berechtigten Interesse der
          Qualitätssicherung und statistischen Auswertung unserer <br />Produkte und Leistungen,
          z.b. um hieraus fahrzeugtypen- und modellspezifische Statistiken und <br />Analysen zu
          erstellen (Art. 6 Abs. 1 lit. f) DSGVO). Ggf. erhobene Positions- und Bewegungsdaten
          <br />werden hierfür NICHT verwendet. Die Verarbeitung zur Qualitätssicherung bzw.
          Statistik- und <br />Analysezwecken erfolgt zum Schutz Ihrer Privatsphäre ausschließlich
          anonymisiert, so dass aus <br />den enthaltenen Daten keine Rückschlüsse auf Sie als
          Person gezogen werden können. Die <br />anonymisierten Statistik- und Analysedaten können
          von uns ggf. an Dritte unter Einhaltung der <br />geltenden datenschutzrechtlichen
          Bestimmungen weitergebenen werden.
          <br />
          <br />Die Verarbeitung der in den Log-Files gespeicherten Informationen dient dem Zweck
          und unserem <br />berechtigten Interesse an der korrekten Darstellung auf Ihrem Endgerät
          und damit der <br />Gewährleistung eines reibungslosen Verbindungsaufbaus und der
          komfortablen Nutzung unserer <br />Produkte. Zudem dienen die Informationen unserem
          berechtigten Interesse einer anonymisierten <br />Auswertung zu statistischen Zwecken und
          damit zur Verbesserung der hoots-App sowie des
          <i>
            hoots
            <br />cloud </i
          >-Portals und der dahinterstehenden Technik sowie zum Zwecke der Aufdeckung und
          <br />Unterbindung von missbräuchlichen Zugriffen (Art. 6 Abs. 1 lit. f DSGVO).
          <br />
          <br />Wir verwenden die zu den vorstehenden Zwecken erhobenen Daten grundsätzlich NICHT um
          <br />Rückschlüsse auf Ihre Identität zu ziehen es sei denn, dies ist zum Zweck der
          Aufdeckung und <br />Unterbindung von missbräuchlichen Zugriffen oder zu Ihrer
          Inanspruchnahmen unserer Produkte <br />erforderlich.
          <br />
          <br />
          <br />3. Wie lange werden die Daten gespeichert? <br />Soweit wir persönliche Daten,
          allgemeine und technische Fahrzeug,- Geräte-, Sensor- Betriebs- <br />und Zustandsdaten
          sowie ggf. erhobene Positions- und Bewegungsdaten verarbeiten bleiben diese
          <br />gespeichert, soweit und solange dies zur Erbringung unserer vertraglichen
          Verpflichtungen Ihnen <br />gegenüber erforderlich ist und werden danach gelöscht.
          <br />
          <br />
          <br />Soweit wir allgemeine und technische Fahrzeug,- Geräte-, Betriebs- und Zustandsdaten
          auch zur <br />Qualitätssicherung und Statistik- und Analysezwecken verarbeiten und in
          anonymisierter Form ggf. <br />an Dritte weitergeben bleiben diese darüber hinaus in
          anonymisierter Form dauerhaft gespeichert.
          <br />
          <br />Die in den Log-Files gespeicherten Informationen werden von uns und unserem
          Auftragsverarbeiter <br />zur Ermittlung von Störungen und aus Sicherheitsgründen
          regelmäßig für 14 Tage gespeichert und <br />danach gelöscht. Daten deren weitere
          Aufbewahrung zu Beweiszwecken erforderlich ist, sind von <br />der Löschung ausgenommen
          bis zur endgültigen Klärung des jeweiligen Vorfalls.
          <br />
          <br />Werden Daten zu mehreren Zwecken verarbeitet so werden die Daten automatisch
          gelöscht oder in <br />einer auf Sie nicht direkt rückführbaren Form gespeichert, sobald
          der letzte angegebene Zweck <br />erfüllt wurde.
          <br />
          <br />
          <br />4. Empfänger der Daten ? <br />Empfänger der Daten ist die Verantwortliche Stelle
          sowie - im Zusammenhang mit der Nutzung der <br />Produkte <i>"hoots-cloud"</i> und "
          <i>hoots GPS" -</i>die von uns für den Betrieb des hoots-Cloudservers <br />beauftragten
          Auftragsverarbeiter sowie ggf. Telematikpartner. Diese wurden von uns sorgfältig
          <br />ausgewählt, sind im Rahmen einer Auftragsverarbeitung gemäß Art. 28 DSGVO strikt zur
          <br />Einhaltung der (datenschutz-)rechtlichen Bestimmungen sowie an unsere sonstigen
          Weisungen <br />gebunden und werden von uns regelmäßig kontrolliert. Einzelheiten zu den
          beauftragten <br />Auftragsverarbeitern, deren Pflichten und die von diesen zum Schutz
          Ihrer Daten ergriffenen <br />technischen- und organisatorischen Maßnahmen, stellen wir
          Ihnen auf Anfrage gern zur Verfügung.
          <br />
          <br />Die zur Qualitätssicherung bzw. Statistik- und Analysezwecken verarbeiteten,
          anonymisierten <br />Betriebs- und Zustandsdaten können von uns ggf. an Dritte unter
          Einhaltung der geltenden <br />datenschutzrechtlichen Bestimmungen weitergebenen werden.
          <br />
          <br />Im Übrigen geben wir Daten nur nach Maßgabe der gesetzlichen Vorschriften oder eines
          <br />gerichtlichen Titels an Behörden und Dritte heraus. Auskünfte an Behörden können
          aufgrund einer <br />gesetzlichen Vorschrift zur Gefahrenabwehr oder zur Strafverfolgung
          erteilt werden.
          <br />
          <br />
          <br />5. Ergänzende Angaben: <br />Umfang und Zweck der jeweiligen Datenverarbeitung sowie
          Speicherdauer und die Empfänger Ihrer <br />Daten werden nachfolgend zu den einzelnen
          Produkten " <i>hoots one box</i>", <i>"hoots App"</i>,
          <i>
            "hoots cloud"
            <br />sowie "hoots GPS" </i
          >näher beschrieben:
          <br />
          <br />
          <b>a.</b>
          <b>hoots one box</b>
          <br />Mit den in der <i>hoots one box</i> integrierten Sensoren werden die Betriebs- und
          Zustandsdaten Ihres <br />Fahrzeugs (sowie ggf. Positions- und Bewegungsdaten) erfasst.
          Die Betriebs- und Zustandsdaten <br />(sowie ggf. Positions- und Bewegungsdaten) bleiben
          in der <i>hoots one box</i> lokal für 24 Fahrstunden <br />gespeichert und werden dann
          automatisch mit neuen Daten überschrieben.
          <br />
          <br />Soweit Sie die <i>hoots App</i> (Ziffer III. 5. b.) nutzen, werden die Betriebs- und
          Zustandsdaten Ihres <br />Fahrzeugs (sowie ggf. Positions- und Bewegungsdaten) sowie
          Gerätedaten über eine verschlüsselte <br />Bluetooth-Verbindung an Ihr Endgerät übertragen
          und können dort von Ihnen abgerufen werden. <br />Wenn Sie uns mit dem Produkt
          <i>hoots-cloud</i> mit der Speicherung von Daten beauftragt haben, <br />werden die
          Betriebs- und Zustandsdaten Ihres Fahrzeugs (sowie ggf. Positions- und
          <br />Bewegungsdaten) sowie Gerätedaten per verschlüsselter GSM-Verbindung (SSL/TLS) an
          unsere <br />hoots-Cloudserver sowie ggf. Telematikpartner übertragen, gespeichert und zu
          den unter Ziffer III. <br />2. genannten Zwecken verarbeitet. Nähere Angaben finden Sie
          unter Ziffer III. 5. c..
          <br />
          <br />In allen anderen Fällen werden von der <i>hoots one box</i> keine Daten an uns oder
          Dritte übermittelt.
          <br />
          <br />
          <b>b.</b>
          <b>hoots App</b>
          <br />Mit der <i>hoots-App</i> können Sie sich die von der <i>hoots one box</i> erfassten
          Betriebs- und <br />Zustandsdaten Ihres Fahrzeuges (sowie ggf. Positions- und
          Bewegungsdaten) in Echtzeit auf Ihrem <br />Mobilgerät anzeigen lassen. Die Übermittlung
          der Daten erfolgt von Ihrer <i>hoots one box</i> per <br />verschlüsselter
          Bluetooth-Verbindung direkt auf ihr aktiviertes Mobilgerät. Eine Datenübermittlung
          <br />an uns oder Dritte findet dabei nicht statt.
          <br />
          <br />Wenn Sie unser Produkt <i>hoots-GPS</i> nutzen und auf Ihrem Endgerät bzw. in Ihrer
          hoots-App mit <br />dem Button "GPS" die Kartenanwendung aktivieren werden über eine
          interaktive Schnittstelle <br />Kartendaten des Anbieters "Google Maps" (Google LLC, 1600
          Amphitheatre Parkway, Mountain <br />View, CA 94043, USA) auf Ihr Mobilgerät geladen
          welche es Ihnen und uns ermöglicht, die vom <br />GPS-Modul Ihrer
          <i>hoots one box</i> erfassten aktuellen Standort- und Positionsdaten Ihres Fahrzeugs
          <br />in Ihrer hoots-App benutzerfreundlich in Echtzeit visuell darzustellen. Hierbei
          werden von Ihrem <br />Mobilgerät die zur Darstellung erforderlichen Positionsdaten Ihrer
          hoots one box sowie die IP- <br />Adresse Ihres mit der <i>hoots App</i> verwendeten
          Mobilgeräts an Google übermittelt. Die <br />Datenübertragung von und zu Ihrem Mobilgerät
          ist nach anerkannten Sicherheitsstandards <br />(SSL/TLS-) verschlüsselt und erfolgt über
          Ihren jeweiligen Mobilfunkanbieter. Zum Schutz Ihrer <br />Privatsphäre findet die
          Übermittlung der aktuellen Standort- und Positionsdaten Ihres Fahrzeugs <br />über unsere
          eigene Anbieterkennung (hoots API-Key) statt, so dass Google LLC diese Daten Ihnen
          <br />grundsätzlich nicht direkt zuordnen kann. Wenn Sie über ihr Endgerät gleichzeitig
          mit anderen <br />Dienste der Google LLC interagieren oder während der Benutzung mit Ihrem
          Benutzerkonto bei <br />Diensten der Google LLC eingeloggt sind ist es Google LLC ggf.
          möglich, die in diesem <br />Zusammenhang separat von Ihnen verarbeiteten Daten (z.B.
          technische Daten Ihres Mobilgeräts, <br />Informationen zum Mobilfunknetz, eine Ihrem
          Mobilgerät zugewiesene Telefonnummer sowie – <br />abhängig von den
          Datenschutzeinstellungen auf Ihrem Mobilgerät – ggf. zusätzliche Standortdaten <br />und
          Daten über die bei der Interaktion mit den Diensten von Google aufgerufenen Inhalte) mit
          den <br />der hoots-API zugewiesenen Daten zu verknüpfen und Ihrem persönlichen Profil
          zuzuordnen. <br />Weitere Informationen zu Zweck und Umfang der Datenerhebung und
          Verarbeitung durch Google <br />sowie Informationen zu Ihren diesbezüglichen Rechten und
          Einstellungsmöglichkeiten zum Schutze <br />Ihrer Privatsphäre erhalten Sie in den
          Datenschutzerklärungen des Anbieters
          <br />
          <a href="https://policies.google.com/privacy?hl=de"
            >(https://policies.google.com/privacy?hl=de)</a
          >. Google ist unter dem Privacy-Shield-Abkommen <br />zertifiziert und bietet hierdurch
          eine Garantie, das europäische Datenschutzrecht einzuhalten. Die <br />Rechtsgrundlage der
          Verarbeitung ist Art 6 Abs. 1 lit. f DSGVO.
          <br />
          <br />Soweit Sie uns im Zusammenhang mit dem Produkt <i>hoots-cloud</i> mit der
          Speicherung von Daten <br />beauftragt haben, können Sie über die <i>hoots-App</i> zudem
          auf die auf unseren hoots-Cloudservern <br />zu Ihrem persönlichen Benutzeraccount
          gespeicherten Daten zugreifen. Wenn Sie über die
          <i>
            hoots
            <br />App
          </i>
          auf ihre <i>hoots-cloud</i> zugreifen, werden neben den betreffenden Formulardaten (d.h.
          der Inhalt <br />Ihrer Übermittlung bzw. Abfrage) durch Ihr Endgerät zusätzlich die unter
          Ziffer III. 1 aufgeführten <br />Protokolldaten an den Server gesendet und dort zeitlich
          begrenzt in einer Datei (Logfile) <br />gespeichert. (Siehe Punkt III.1.Cookies) Die
          Datenübertragung von und zu Ihrem Mobilgerät ist <br />nach anerkannten
          Sicherheitsstandards (SSL/TLS-) verschlüsselt und erfolgt über Ihren jeweiligen
          <br />Mobilfunkanbieter.
          <br />
          <br />
          <b>c.</b>
          <b>hoots cloud</b>
          <br />Mit der <i>hoots cloud</i> haben Sie die Möglichkeit, die von Ihrer
          <i>hoots one box</i> erfassten Betriebs- und <br />Zustandsdaten (sowie ggf. Positions-
          und Bewegungsdaten) Ihres Fahrzeugs dauerhaft zu <br />speichern, abzurufen und
          auszuwerten. Hierzu werden die betreffenden Daten von Ihrer
          <i>
            hoots one
            <br />box
          </i>
          über eine nach anerkannten Sicherheitsstandards verschlüsselte Verbindung (SSL/TLS) an
          <br />unsere hoots-Cloudserver übermittelt, gespeichert und zu den unter Ziffer III. 2.
          genannten <br />Zwecken verarbeitet. Zusätzlich wird die individuelle Geräte-ID und
          Seriennummer Ihrer
          <i>
            hoots one
            <br />box
          </i>
          sowie die IMEI- und SIM-Nummer des in der hoots box integrierten GSM-Moduls übermittelt.
          <br />Die Datenübermittlung erfolgt mittels des in der <i>hoots one box</i> integrierten
          GSM (Mobilfunk-) <br />Moduls und sonstigen technischen Einrichtungen unseres
          Telematikpartners. Eine darüber <br />hinausgehende Übertragung von Daten, mit denen ein
          direkter Bezug zu Ihrer Person hergestellt <br />werden kann, erfolgt nicht.
          <br />
          <br />Zur Nutzung der <i>hoots cloud</i> ist die Einrichtung eines persönlichen
          Benutzer-Accounts <br />(Registrierung) und die Anmeldung mit Benutzername und Passwort
          (Login-Daten) erforderlich. Um <br />das Benutzerkonto mit dem Fahrzeug zu verknüpfen, für
          das die Leistungen von
          <i>hoots cloud</i>
          <br />genutzt werden sollen ist es darüber hinaus ist erforderlich, dass Sie uns
          allgemeine und <br />technische Fahrzeugdaten zu Ihrem Fahrzeug bekannt geben. Wenn Sie
          (z.B. über die hoots App <br />oder durch Einloggen auf dem hoots-cloud Portal) auf ihre
          hoots-cloud zugreifen, werden neben <br />den betreffenden Formulardaten (d.h. der Inhalt
          Ihrer Übermittlung bzw. Abfrage) durch Ihr <br />Endgerät zusätzlich die unter Ziffer III.
          1 aufgeführten Protokol daten an den Server gesendet und <br />dort zeitlich begrenzt in
          einer Datei (Logfile) gespeichert.
          <br />
          <br />Wenn Sie sich die im Zusammenhang mit dem Produkt <i>hoots-GPS</i> in Ihrer
          <i>hoots cloud</i>
          <br />gespeicherten (historischen) Positions- und Bewegungsdaten) Ihres Fahrzeugs anzeigen
          lassen, <br />werden über eine interaktive Schnittstelle Kartendaten des Anbieters "Google
          Maps" (Google LLC, <br />1600 Amphitheatre Parkway, Mountain View, CA 94043, USA) geladen
          was es Ihnen und uns <br />ermöglicht, die historischen Standort- und Positionsdaten Ihres
          Fahrzeugs benutzerfreundlich <br />visuell darzustellen. Hierbei werden die zur
          Darstellung erforderlichen historischen Standort- und <br />Positionsdaten von unserem
          hoots-Server an Google übermittelt. Zum Schutz Ihrer Privatsphäre <br />findet die
          Übermittlung der historischen Standort- und Positionsdaten ausschließlich in
          <br />anonymisierter Form statt, so dass Google LLC grundsätzlich keine Verbindung
          zwischen diesen <br />Daten und Ihrer Person ziehen kann. Die Rechtsgrundlage der
          Verarbeitung ist Art 6 Abs. 1 lit. f <br />DSGVO.
          <br />
          <br />Eine Datenerhebung und Verarbeitung Ihrer im Zusammenhang mit
          <i>hoots-cloud</i> verarbeiteten <br />Daten erfolgt grundsätzlich nur so lange in einer
          auf Sie oder Ihr Fahrzeug zurück verfolgbaren <br />Form, wie dies zur Erbringung der
          angeforderten Dienstleistung erforderlich ist oder soweit wir <br />gesetzlich dazu
          verpflichtet sind, die Daten zu speichern und/oder auszuhändigen.
          <br />
          <br />
          <br />
          <b>d.</b>
          <b>hoots GPS</b>
          <br />Mit dem Produkt <i>hoots GPS</i> haben Sie die Möglichkeit, die Positions- und
          Standortdaten Ihres <br />Fahrzeuges zu erfassen und sich – je nach Umfang Ihres
          Leistungspaktes - über die
          <i>hoots-App</i>
          <br />oder durch Zugriff auf die <i>hoots-Cloud</i> anzeigen zu lassen. Eine Verarbeitung
          von Positions- und <br />Bewegungsdaten durch uns oder Dritte findet dabei nur in dem Fall
          statt, wenn Sie uns im <br />Zusammenhang mit dem Produkt <i>"hoots cloud"</i>mit der
          <i></i>Speicherung der Daten beauftragt haben. <br />Andernfalls werden wir die von Ihrer
          <i>hoots one box</i> erfassten Positions- und Bewegungsdaten ohne <br />Ihre gesonderte
          Einwilligung weder speichern noch sonst verarbeiten. Näheres dazu finden Sie <br />unter
          Ziffer III. 5. a., b. und c. dieser Datenschutzerklärung.
          <br />
          <br />Zusätzlich bietet Ihnen <i>hoots-GPS</i> die Möglichkeit, sich mittels der von Ihnen
          aktivierten <br />Alarmfunktion bei jeder Bewegung oder Standortveränderung Ihres
          Fahrzeugs per SMS, E-Mail <br />oder Push-Mitteilung der <i>hoots-App</i> benachrichtigen
          zu lassen. Damit wir Sie informieren können, <br />wenn sich der Standort Ihres ungewollt
          Fahrzeug verändert oder Ihr Fahrzeug bewegt wird (z.B. im <br />Falle eines Diebstahls
          oder Öffnungsversuches) werden die zu Ihrem Fahrzeug verarbeiteten <br />Positions- und
          Standortdaten mit Daten Ihres mobilen Endgeräts bzw. des Endgeräts mit der in der
          <br />
          <i>hoots-cloud</i> hinterlegten Alarmnummer oder Ihrer E-Mailadresse verknüpft und zu
          diesem Zweck <br />von uns verarbeitet. Im Falle einer Alarmmeldung erfolgt die
          Datenübermittlung mittels des in der
          <br />
          <i>hoots one box</i> integrierten GSM (Mobilfunk-) Moduls und sonstigen technischen
          Einrichtungen <br />unseres Telematikpartners über eine nach anerkannten
          Sicherheitsstandards (SSL/TLS-) <br />verschlüsselte Verbindung. Eine Verknüpfung zwischen
          den Daten zur Ortung des Fahrzeugs und <br />den Daten zur Ortung Ihres mobilen Endgeräts
          erfolgt nur dann und nur in dem Umfang, wie es zur <br />Erbringung der angeforderten
          Dienstleistung (z.B. Diebstahlwarnung) notwendig ist.
          <br />
          <br />
          <br />
          <b>IV. Datenübermittlung ins Ausland</b>
          <br />Wir Speichern und Verarbeiten Ihre Daten ausschließlich in Rechenzentren, die sich
          innerhalb der Europäischen Union befinden. Soweit unsere Dienstleister ihren Sitz in einem
          Staat außerhalb des Europäischen Wirtschaftsraums (EWR) haben sind diese entweder unter
          dem sog. "Privacy-Shield- Abkommen" zertifiziert oder wurden über sog.
          EU-Standardvertragsklauseln verpflichtet und bieten hierdurch eine rechtswirksame Garantie
          gemäß Artikel 45 DSGVO bzw. Artikel 46 DSGVO, das europäische Datenschutzrecht
          einzuhalten.
          <br />
          <br />
          <br />
          <b>V.</b>
          <b>
            Besteht für Sie eine Verpflichtung, Ihre Daten anzugeben oder ist dies für einen
            <br />Vertragsabschluss erforderlich und was passiert, wenn Sie sich dagegen
            <br />entscheiden?
          </b>
          <br />
          <br />Selbstverständlich besteht keine Verpflichtung uns personenbezogene oder sonstige
          Daten <br />bereitzustellen, mit uns Kontakt aufzunehmen, einen Vertrag abzuschließen oder
          die Funktionen <br />der <i>hoots one box,</i>von <i>hoots GPS</i>, <i></i>der
          <i>hoots App</i>oder der <i>hoots cloud</i>zu verwenden. Wir können <br />Ihr jeweiliges
          Anliegen jedoch selbstverständlich nur bearbeiten und Ihnen die jeweiligen
          <br />Funktionalitäten nur dann zur Verfügung stellen, wenn Sie uns hierfür ausreichende
          <br />personenbezogene oder sonstige Informationen zur entsprechenden Verarbeitung
          überlassen.
          <br />
          <br />Ebenso wenig besteht eine Pflicht, uns die bei der Nutzung der <i>hoots App</i> oder
          dem Zugriff auf die
          <br />
          <i>hoots cloud</i>erfassten Protokoll- und Logfile-Daten zur Verfügung zu stellen. Eine
          <br />Nichtbereitstellung hätte jedoch zur Folge, dass Sie die <i>hoots App</i>oder die
          <i>hoots cloud</i>
          <br />möglicherweise nicht oder nur eingeschränkt nutzen können. Sie können der
          Verarbeitung Ihrer <br />personenbezogenen Daten jedoch mittels einer Nachricht an uns
          jederzeit widersprechen (z.B. per <br />E-Mail an: info@hoots.de). In diesem Fall werden
          wir Ihre in den Log-Files gespeicherten <br />personenbezogenen Daten löschen.
          <br />
          <br />
          <b>
            VI. Angaben zur automatisierten Entscheidungsfindung (Profiling), Datenschutz für Kinder
          </b>
          <br />
          <br />Wir verzichten auf eine automatisierte Entscheidungsfindung oder ein Profiling.
          <br />
          <br />Unser Angebot richtet sich grundsätzlich an Erwachsene. Personen unter 18 Jahren
          sollten ohne <br />Zustimmung der Eltern oder Erziehungsberechtigten keine
          personenbezogenen Daten an uns <br />übermitteln.
          <br />
          <b>
            <br />
            <br />VII. Ihre Rechte
            <br /> </b
          >Ihre nachfolgenden Rechte können Sie gegenüber der Verantwortlichen Stelle jederzeit
          <br />unentgeltlich geltend machen. Wenn Sie von Ihren nachfolgenden Rechten uns gegenüber
          <br />Gebrauch machen wollen, können Sie sich an uns telefonisch, per E-Mail oder Brief
          unter unseren <br />zu Beginn dieser Datenschutzerklärung aufgeführten Kontaktdaten
          wenden.
          <br />
          <br />1. Auskunftsrecht: <br />Sie haben das Recht, von uns eine Bestätigung darüber zu
          verlangen, ob wir Sie betreffende personenbezogene Daten verarbeitet haben und das Recht
          auf Auskunft über diese personenbezogenen Daten und weitere Informationen gemäß Art. 15
          DSGVO. Unter bestimmten Voraussetzungen kann Ihr Auskunftsrecht gemäß der gesetzlichen
          Vorgaben, insbesondere nach § 34 BDSG eingeschränkt sein.
          <br />
          <br />2. Recht auf Berichtigung: <br />Sie haben das Recht, von uns die Berichtigung Sie
          betreffender unrichtiger bzw. unvollständiger personenbezogener Daten gemäß Art. 16 DSGVO
          zu verlangen.
          <br />
          <br />3. Recht auf Löschung: <br />Sie haben das Recht bei Vorliegen der in Art. 17 DSGVO
          genannten Voraussetzungen, die Löschung Ihrer Daten zu verlangen. Danach können Sie
          beispielsweise die Löschung Ihrer Daten verlangen, oweit diese für die Zwecke, für die sie
          erhoben wurden, nicht mehr notwendig sind. Außerdem können Sie Löschung verlangen, wenn
          wir Ihre Daten auf der Grundlage Ihrer Einwilligung verarbeiten und Sie diese Einwilligung
          widerrufen.
          <br />
          <br />4. Recht auf Einschränkung der Verarbeitung: <br />Sie haben das Recht die
          Einschränkung der Verarbeitung der Sie betreffenden Daten zu verlangen, wenn die
          Voraussetzungen des Art. 18 DSGVO vorliegen. Dies ist beispielsweise der Fall, wenn Sie
          die Richtigkeit Ihrer Daten bestreiten. Für die Dauer der Überprüfung der Richtigkeit der
          Daten können Sie dann die Einschränkung der Verarbeitung verlangen.
          <br />
          <br />5. Recht auf Datenübertragbarkeit: <br />Sofern die Datenverarbeitung auf der
          Grundlage einer Einwilligung oder einer Vertragserfüllung beruht und diese zudem unter
          Einsatz einer automatisierten Verarbeitung erfolgt, haben Sie das Recht gemäß Art. 20
          DSGVO, Ihre Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu
          erhalten und diese an einen Anderen zu übermitteln.
          <br />
          <br />6. Widerspruchsrecht nach Art. 21 DSGVO: <br />Sofern Ihre personenbezogenen Daten
          auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. S. 1 lit. f) DSGVO verarbeitet
          werden haben Sie gemäß Art. 21 DSGVO das Recht, Widerspruch gegen die Verarbeitung Ihrer
          personenbezogenen Daten einzulegen, wenn dafür Gründe vorliegen, die sich aus Ihrer
          besonderen Situation ergeben. Soweit sich Ihr Widerspruch gegen die Verarbeitung Ihrer
          Daten zu Zwecken der Direktwerbung richtet, besteht das Widerrufsrecht unbeschränkt. Das
          gilt auch für Profiling, soweit es mit der Direktwerbung zusammenhängt. In diesem Fall
          werden wir die Daten ohne Ihre vorherige Angabe zu einer besonderen Situation nicht mehr
          für diesen Zweck verarbeiten.
          <br />
          <br />7. Widerrufsrecht nach Art. 7 Abs. 3 DSGVO: <br />Sofern die Datenverarbeitung auf
          einer von Ihnen erteilten Einwilligung beruht, haben Sie das Recht, die Datenverarbeitung
          im Rahmen einer Einwilligung mit Wirkung für die Zukunft jederzeit kostenlos widerrufen.
          Durch den Widerruf wird die Rechtmäßigkeit unserer Datenverarbeitung, die aufgrund Ihrer
          Einwilligung bis zum Eingang des Widerrufs erfolgt ist, nicht berührt. Nach Eingang des
          Widerrufs wird die Datenverarbeitung, die ausschließlich auf Ihrer Einwilligung beruhte,
          eingestellt.
          <br />
          <br />8. Beschwerderecht: <br />Sie haben gemäß Art. 77 DSGVO das Recht, sich bei einer
          zuständigen Aufsichtsbehörde (z. B. beim Landesbeauftragten für den Datenschutz) über
          unsere Verarbeitung Ihrer Daten zu beschweren. Eine Liste der Aufsichtsbehörden mit
          Anschrift finden Sie unter:
          <br />
          <a
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            >https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.</a
          >
          <br />
          <br />
          <br />
          <b>VIII. Fragen</b>
          <br />Für Fragen zu unserer Datenschutzerklärung und zu sämtlichen Angelegenheiten der
          Verarbeitung Ihrer personenbezogenen Daten, insbesondere im Zusammenhang mit den von uns
          eingesetzten Auftragsverarbeitern und Dienstleistern, können Sie uns jederzeit unter den
          zu Beginn der Datenschutzerklärung (Ziffer I.) angegebenen Daten kontaktieren.
          <br />
          <br />
          <br />
          <b>IX. Stand und Aktualisierung dieser Datenschutzerklärung</b>
          <br />Diese Datenschutzerklärung hat den Stand vom 01. Mai 2019. Wir behalten uns vor, die
          Datenschutzerklärung zu gegebener Zeit zu aktualisieren, um den Datenschutz zu verbessern
          und/oder an geänderte Behördenpraxis oder Rechtsprechung anzupassen.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="close" text>Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["showDialog"],
  data() {
    return {};
  },

  methods: {
    close() {
      // emit close-event to parent component
      this.$emit("close");
    },
  },
};
</script>
