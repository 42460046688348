<template>
  <v-container fluid grid-list-sm>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <active-vehicle-bar
      class="space-around"
      :active-vehicle="activeVehicle"
      :show-last-updated-at="false"
      @open-vehicle-select="openVehiclePicker"
    />

    <session-picker
      v-if="sessionPickDialog"
      :showDialog="sessionPickDialog"
      @select-session="setActiveSession"
      @close="closeSessionPicker"
    ></session-picker>
    <vehicle-picker
      :showDialog="vehiclePickDialog"
      :active-vehicle-i-d="activeVehicleID"
      @select-vehicle="selectVehicle"
      @close="closeVehiclePicker"
    ></vehicle-picker>

    <OverviewCards
      class="space-around"
      :month-session-count="monthSessionCount"
      :num-vehicles-in-fleet="numVehicles"
      :last-live-date="lastLiveDate"
      :total-mileage-in-km="mileageTotal"
      :average-trip-distance-in-km="avgMileage"
      :average-duration-per-trip-in-min="avgOperatingMinutes"
      :loading="loading"
      @openVehiclePicker="openVehiclePicker"
      @openSessionPicker="openSessionPicker"
    />

    <v-toolbar class="space-around">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-switch
            v-on="on"
            v-model="showUnfilteredSessions"
            color="primary"
            hide-details
            :label="$t('ui.dashboard.filter')"
            @change="onFilterSession"
          ></v-switch>
        </template>
        <span>{{ $t("ui.dashboard.tooltip.filter") }}</span>
      </v-tooltip>
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-switch
            v-on="on"
            prepend-icon="mdi-chart-tree"
            v-model="showChartsAsGrid"
            color="primary"
            hide-details
            @change="onShowAllCharts"
            :label="$t('ui.dashboard.sessionSingleDisplay')"
          ></v-switch>
        </template>
        <span>{{ $t("ui.dashboard.tooltip.filter") }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-row align="start" justify="start" class="space-around">
      <v-col cols="12" sm="12" :md="showChartsAsGrid ? 12 : 6" :order="showChartsAsGrid ? 2 : 1">
        <dashboard-map
          :active-session="activeSession"
          :active-trip="activeTrip"
          :active-vehicle-last-location="activeVehicleLastLive"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        :md="showChartsAsGrid ? 12 : 6"
        :order="showChartsAsGrid ? 1 : 2"
        style="background-color: #111"
      >
        <dashboard-charts />
      </v-col>
    </v-row>

    <v-snackbar
      v-model="error"
      :timeout="6000"
      :bottom="true"
      :multi-line="true"
      color="red darken-2"
      >{{ errortext }}</v-snackbar
    >
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";

import { mapActions, mapGetters } from "vuex";

import type { Session, SessionAddressData } from "@/@types/data/session.types";
import type { Vehicle } from "@/@types/data/vehicle.types";
import type { LeafletMap as LeafletMapTypes } from "@/@types/ui/map/leafletmap.types";
import type { UnixTimeStamp } from "@/@types/generic/datetime.types";

import SessionPicker from "@/components/SessionPicker.vue";
import VehiclePicker from "@/components/VehiclePicker.vue";

import OverviewCards from "@/views/OverviewCards.vue";

import { DeviceLastKnownLocation } from "@/@types/data/device.types";
import { ReverseGeo } from "@/@types/data/geo.types";
import DashboardMap from "@/components/dashboard/map/DashboardMap.vue";
import DashboardCharts from "@/components/dashboard/charts/DashboardCharts.vue";

import { dashboardEventBus, DashboardEventID } from "@/events/dashboard.channel";
import ActiveVehicleBar from "@/components/shared/vehicle/ActiveVehicleBar.vue";

export default Vue.extend({
  components: {
    ActiveVehicleBar,
    DashboardMap,
    DashboardCharts,
    OverviewCards,
    SessionPicker,
    VehiclePicker,
  },
  data() {
    return {
      sessionPickDialog: false,
      vehiclePickDialog: false,
      error: false,
      errortext: "",
      snackbar: true,
      index: 0,
      showUnfilteredSessions: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("vehicle", ["getVehicleByID", "getAllVehicles"]),
    ...mapGetters("device", [
      "avgMileage",
      "avgOperatingMinutes",
      "calendarSessionEvents",
      "operatingHours",
      "mileageTotal",
      "monthSessionCount",

      "getActiveVehicleID",
      "getActiveSessionID",

      "getSessionByID",

      "getActiveGeoPoints",
      "getActiveGeoRoute",
      "getSessionAddressDataBySessionID",
      "getLastDeviceLocationByDeviceID",
      "getLastDeviceAddressDataByDeviceID",
    ]),
    ...mapGetters("ui/dashboard", ["getShowAllCharts"]),

    centerOnLastLiveEnabled(): boolean {
      if (this.activeVehicle !== null) {
        const lastLocation = this.getLastDeviceLocationByDeviceID(this.activeVehicle.device_id);
        return lastLocation !== null;
      } else {
        return false;
      }
    },

    numVehicles(): number {
      return this.getAllVehicles.length;
    },

    activeSession(): Session | undefined {
      return this.getSessionByID(this.getActiveSessionID) || undefined;
    },

    activeVehicleID(): Vehicle["id"] | null {
      return this.getActiveVehicleID || null;
    },

    activeVehicle(): Vehicle | null {
      return this.getVehicleByID(this.getActiveVehicleID);
    },

    lastLiveDate(): Date | null {
      const deviceID = this.activeVehicle?.device_id;
      if (deviceID !== undefined) {
        const lastLocation = this.getLastDeviceLocationByDeviceID(deviceID);
        return lastLocation !== null ? new Date(lastLocation.updated_at) : null;
      }
      return null;
    },

    titleVehicleUI(): string {
      return this.activeVehicle !== null
        ? this.activeVehicle.make + " " + this.activeVehicle.model
        : "";
    },

    activeSessionAddresses(): SessionAddressData | null {
      const activeSessionID = this.getActiveSessionID || null;
      return activeSessionID !== null
        ? this.getSessionAddressDataBySessionID(activeSessionID)
        : null;
    },

    activeSessionTimestamps(): { start?: UnixTimeStamp; end?: UnixTimeStamp } {
      const start = this.activeSession?.started_at;
      const end = this.activeSession?.finished_at;
      return { start, end };
    },

    activeTrip(): LeafletMapTypes.Trip | undefined {
      const route = this.getActiveGeoRoute;
      const points = this.getActiveGeoPoints;
      const addresses = this.activeSessionAddresses;
      const timeStamps = this.activeSessionTimestamps;

      const start: LeafletMapTypes.Location = {
        time: timeStamps.start,
        address: addresses?.startAddress,
      };

      const end: LeafletMapTypes.Location = {
        time: timeStamps.end,
        address: addresses?.endAddress,
      };

      return { start, end, points, route };
    },

    activeDeviceLastLocation(): DeviceLastKnownLocation | undefined {
      const activeDeviceID = this.activeSession?.device_id;
      return activeDeviceID && this.getLastDeviceLocationByDeviceID(activeDeviceID);
    },

    activeDeviceLastAddress(): ReverseGeo.Item | null {
      const activeDeviceID = this.activeSession?.device_id || null;
      return activeDeviceID !== null
        ? this.getLastDeviceAddressDataByDeviceID(activeDeviceID)
        : null;
    },

    activeVehicleLastLive(): LeafletMapTypes.LastLocation | undefined {
      const time = this.activeDeviceLastLocation?.updated_at;
      const coordinate = this.activeDeviceLastLocation
        ? {
            lng: this.activeDeviceLastLocation.longitude,
            lat: this.activeDeviceLastLocation.latitude,
          }
        : undefined;
      const address = this.activeDeviceLastAddress || undefined;
      const vehicle = this.activeVehicle || undefined;

      return { time, coordinate, vehicle, address };
    },
    showChartsAsGrid: {
      get(): boolean {
        return this.getShowAllCharts;
      },
      set(): void {
        this.toggleShowAllCharts();
      },
    },
  },
  methods: {
    openVehiclePicker(): void {
      this.vehiclePickDialog = true;
    },

    closeVehiclePicker(): void {
      this.vehiclePickDialog = false;
    },

    openSessionPicker(): void {
      this.sessionPickDialog = true;
    },

    closeSessionPicker(): void {
      this.sessionPickDialog = false;
    },

    async onFilterSession(): Promise<void> {
      this.dispatchLoading(true);
      const sessionMinimumDistance = this.showUnfilteredSessions ? 0 : 0.8;
      await this.$store.dispatch("device/loadSessionList", sessionMinimumDistance);
      this.dispatchLoading(false);
    },

    async setActiveSession(sessionID: Session["id"]): Promise<void> {
      this.closeSessionPicker();
      this.dispatchLoading(true);
      await this.$store.dispatch("device/setActiveSession", sessionID);
      this.dispatchLoading(false);
    },

    async selectVehicle(vehicleID: Vehicle["id"]) {
      this.closeVehiclePicker();
      this.dispatchLoading(true);
      await this.$store.dispatch("device/setActiveVehicle", vehicleID);
      this.dispatchLoading(false);
    },

    dispatchLoading(loading: boolean) {
      dashboardEventBus.dispatch(DashboardEventID.LOADING_STATE_CHANGED, {
        loading,
      });
    },

    handleLoadingEvent(event: { loading: boolean }) {
      this.loading = event.loading;
    },

    async initializeData() {
      this.dispatchLoading(true);
      try {
        await Promise.all([
          this.$store.dispatch("messages/get"),
          this.$store.dispatch("device/initialize"),
          this.$store.dispatch("vehicle/fetchAll"),
        ]);
      } catch (err) {
        console.log(err);
      } finally {
        this.dispatchLoading(false);
      }
    },

    onShowAllCharts() {
      dashboardEventBus.dispatch(DashboardEventID.LAYOUT_CHANGED, {});
    },

    subscribeToEvents() {
      dashboardEventBus.subscribe(DashboardEventID.LOADING_STATE_CHANGED, this.handleLoadingEvent);
    },

    unsubscribeFromEvents() {
      dashboardEventBus.unsubscribe(
        DashboardEventID.LOADING_STATE_CHANGED,
        this.handleLoadingEvent
      );
    },
    ...mapActions("ui/dashboard", ["toggleShowAllCharts"]),
  },

  mounted() {
    this.subscribeToEvents();
    this.initializeData();
  },

  beforeDestroy() {
    this.unsubscribeFromEvents();
  },
});
</script>

<style lang="scss" scoped>
.space-around {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
