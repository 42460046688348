import Vue from "vue";
import Vuex from "vuex";

import app from "./modules/app";
import ui from "./modules/ui";
import device from "./modules/device";
import messages from "./modules/messages";
import sensor from "./modules/sensor";
import vehicle from "./modules/vehicle";

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    clearData({ dispatch }) {
      dispatch("app/clearData");
      dispatch("messages/clearData");
      dispatch("device/clearData");
      dispatch("sensor/clearData");
      dispatch("vehicle/clearData");
    },
  },
  modules: {
    app,
    device,
    messages,
    sensor,
    vehicle,
    ui,
  },
});
