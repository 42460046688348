import { ReverseGeo } from "@/@types/data/geo.types";
import axios from "axios";

type Coordinates = {
  lat: number;
  lng: number;
};

const reverseGeoEndpoint =
  "https://revgeocode.search.hereapi.com/v1/revgeocode";

export class ReverseGeoLookupProvider {
  readonly #apiKey;
  #fetch = axios.create({});

  constructor(apiKey: string) {
    this.#apiKey = apiKey;
  }

  async lookupAdressForCoordinate(
    coordinates: Coordinates
  ): Promise<ReverseGeo.Item[] | null> {
    const result = await this.#fetch.get(
      `${reverseGeoEndpoint}?at=${coordinates.lat},${coordinates.lng}&apikey=${
        this.#apiKey
      }`
    );
    if (result.status === 200) {
      return result.data.items as ReverseGeo.Item[];
    }
    return null;
  }
}
