import { Coordinates } from "@/@types/data/geo.types";

function numericDistanceBetweenValues(value1: number, value2: number): number {
  return Math.abs(value1 - value2);
}

function areRoughlyEqual(
  coordinate1: Coordinates,
  coordinate2: Coordinates
): boolean {
  const latDifference = numericDistanceBetweenValues(
    coordinate1.lat,
    coordinate2.lat
  );
  const lngDifference = numericDistanceBetweenValues(
    coordinate1.lng,
    coordinate2.lng
  );
  return latDifference < 0.01 && lngDifference < 0.01;
}

export { areRoughlyEqual };
