const state = {
  showAllCharts: false,
};

const getters = {
  getShowAllCharts: (state) => state.showAllCharts,
};

const actions = {
  toggleShowAllCharts({ state }) {
    state.showAllCharts = !state.showAllCharts;
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
