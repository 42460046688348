<template>
  <v-card flat>
    <v-card-text>
      <v-form v-model="validLogin">
        <v-text-field
          v-model="email"
          :rules="[required, emailValid]"
          prepend-icon="email"
          id="email"
          name="login"
          :label="$t('ui.dialog.login.email')"
          type="text"
          data-test="email"
          error-count="3"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :rules="[required, passwordValid]"
          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
          :type="showPassword ? 'text' : 'password'"
          prepend-icon="lock"
          id="password"
          name="password"
          :label="$t('ui.dialog.login.password')"
          data-test="password"
          @click:append="showPassword = !showPassword"
          @keyup.enter="login"
          error-count="5"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="login"
        color="primary"
        type="submit"
        :loading="loading"
        :disabled="!validLogin"
        data-test="login"
        >{{ $t("ui.action.login") }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text x-small @click="forgotPassword">{{ $t("ui.action.forgot") }} </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import {
  EMAIL_VALIDATION_ERROR,
  isEmailValid,
  isPasswordValid,
  PASSWORD_VALIDATION_ERROR,
} from "@/validators/auth.validators";
import { isNotEmpty } from "@/validators/string.validator";
import {
  convertErrorCheckerOutputToStrings,
  type ErrorStringMap,
} from "@/validators/generic.validators";
import i18n from "@/plugins/i18n.plugin";

const emailErrorToStringMap: ErrorStringMap<EMAIL_VALIDATION_ERROR> = {
  [EMAIL_VALIDATION_ERROR.TOO_LONG]: String(i18n.t("ui.rule.length80")),
  [EMAIL_VALIDATION_ERROR.NO_REGEX_MATCH]: String(i18n.t("ui.rule.emailInvalid")),
};

const passwordErrorToStringMap: ErrorStringMap<PASSWORD_VALIDATION_ERROR> = {
  [PASSWORD_VALIDATION_ERROR.TOO_LONG]: String(i18n.t("ui.rule.length80")),
  [PASSWORD_VALIDATION_ERROR.TOO_SHORT]: String(i18n.t("ui.rule.passwordMin")),
  [PASSWORD_VALIDATION_ERROR.NO_REGEX_MATCH]: String(i18n.t("ui.rule.passwordPolicy")),
};

export default Vue.extend({
  name: "LoginWithEmailPassword",
  props: {},
  data: () => {
    return {
      validLogin: false,
      email: "",
      password: "",
      showPassword: false,
      loading: false,
    };
  },
  computed: {
    required() {
      return (string: string) => {
        return isNotEmpty(string) ? true : this.$t("ui.rule.required");
      };
    },

    emailValid() {
      return (email: string) =>
        convertErrorCheckerOutputToStrings(isEmailValid(email), emailErrorToStringMap);
    },

    passwordValid() {
      return (password: string) =>
        convertErrorCheckerOutputToStrings(isPasswordValid(password), passwordErrorToStringMap);
    },
  },
  methods: {
    forgotPassword() {
      this.$emit("forgot-password");
    },

    async navigateToDashboard() {
      try {
        await this.$router.push("/");
      } catch (err) {
        this.$emit("error", (err as Error).message);
      }
    },

    async login() {
      const payload = {
        email: this.email,
        password: this.password,
      };

      try {
        this.loading = true;
        const loginAction = await this.$auth.loginWithEmailAndPassword(payload);

        if (loginAction.wasSuccessful()) await this.navigateToDashboard();
        else this.$emit("error", loginAction.getMessage());
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
