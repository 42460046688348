<template>
  <v-row justify="center">
    <v-dialog :value="showDialog" persistent scrollable max-width="700px">
      <v-card>
        <v-card-title>{{ title }}</v-card-title>
        <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
        <v-container>
          <v-form v-model="limitsValid">
            <v-row justify="center" align="center">
              <v-col
                v-for="limit in limits"
                :key="limit.id"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
              >
                <v-subheader class="white--text"
                  >{{ $t("ui.sensors.measure." + limit.name) }}
                  {{ limit.unit !== "" ? $t("ui.sensors.units." + limit.unit) : "" }}</v-subheader
                >
                <v-card-text>
                  <v-range-slider
                    class="py-4"
                    :min="limit.default.min"
                    :max="limit.default.max"
                    thumb-label="always"
                    thumb-size="32"
                    color="info"
                    append-icon="mdi-autorenew"
                    :step="step(sensor.id)"
                    :value="[limit.user.min, limit.user.max]"
                    :v-model="[limit.user.min, limit.user.max]"
                    :rules="[limit.user.min <= limit.user.max || 'min > max']"
                    @click:append="defaults(limit)"
                    @start="sliderStart(limit)"
                    @end="sliderEnd"
                  ></v-range-slider>
                </v-card-text>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="close" text>{{ $t("ui.action.cancel") }}</v-btn>
          <v-btn color="blue darken-1" :disabled="!limitsValid" @click="save(sensor)" text>{{
            $t("ui.action.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from "vue";
import { isEmpty } from "lodash";

export default Vue.extend({
  name: "EditSensor",
  props: ["showDialog", "sensor"],
  data() {
    return {
      limitsValid: false,
      valid: false,
      tmpLimit: {},
    };
  },

  computed: {
    title() {
      return isEmpty(this.sensor) ? "" : this.$t("ui.dialog.sensors.configureWarningRange");
    },
    subtitle() {
      return isEmpty(this.sensor) ? "" : "Sensor ID " + this.sensor.id;
    },
    sensorType() {
      return isEmpty(this.sensor) ? "" : this.sensor.type.name;
    },
    limits() {
      return isEmpty(this.sensor) ? {} : this.sensor.limits;
    },
  },

  methods: {
    defaults(limit) {
      limit.user.min = limit.default.min;
      limit.user.max = limit.default.max;
    },

    sliderStart(limit) {
      this.tmpLimit = limit;
    },

    sliderEnd(range) {
      if (this.tmpLimit) {
        this.tmpLimit.user.min = range[0];
        this.tmpLimit.user.max = range[1];
      }
    },

    step(id) {
      // "battery sensor"
      if (id >= 0 && id < 262144) return 0.1;
      if (id >= 262144 && id <= 262744) return 500;
      if (id >= 589824 && id <= 590424) return 0.1;
      if (id >= 851968 && id <= 852568) return 5;
      if (id >= 1179648 && id <= 1180248) return 10;
      if (id >= 1310720 && id <= 1311320) return 5;
      if (id >= 1572864 && id <= 1638399) return 0.1; // lambda
      if (id >= 1835008 && id <= 1900543) return 5; // hot
    },

    save(sensor) {
      const sensor_id = sensor.id;

      for (let i = 0; i < sensor.limits.length; i++) {
        const update = {
          limit_id: sensor.limits[i].id,
          min: sensor.limits[i].user.min,
          max: sensor.limits[i].user.max,
        };
        this.$store.dispatch("sensor/update", { sensor_id, update: update }).then(() => {
          this.close();
        });
      }
    },
    close() {
      // emit close-event to parent component
      this.$emit("close");
    },
  },
});
</script>
