import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

import en from "@/locale/en.json";
import de from "@/locale/de.json";

const messages = {
  en: en,
  de: de,
};

const i18n = new VueI18n({
  // standard locale from browser setting (de, en) + iso setting (de-DE, en-US) => (de, en)
  locale:
    (navigator.languages && navigator.languages[0].split("-")[0]) ||
    (navigator.language && navigator.language.split("-")[0]),
  fallbackLocale: "de",
  messages,
});

export default i18n;
