import { createEventBus } from "@/packages/event-bus";

enum ChartEventID {
  RELAYOUT,
  RESET,
  TOUCH,
  HOVER,
  UNHOVER,
  FREEZE,
}

type HoverEvent = { master?: string; xValue: number };
type UnHoverEvent = { master?: string };
type ResetEvent = { master?: string };
type FreezeEvent = { isFrozen: boolean };

type LayoutEvent = { master?: string; bounds: [number, number] | [undefined, undefined] };

type ChartEvents = {
  [ChartEventID.RELAYOUT]: LayoutEvent;
  [ChartEventID.RESET]: ResetEvent;
  [ChartEventID.TOUCH]: never;
  [ChartEventID.HOVER]: HoverEvent;
  [ChartEventID.UNHOVER]: UnHoverEvent;
  [ChartEventID.FREEZE]: FreezeEvent;
};

const chartEventBus = createEventBus<ChartEvents>();

export { ChartEventID, ChartEvents, LayoutEvent, chartEventBus };
