// const debounce = <T extends (...args: unknown[]) => unknown>(callback: T, waitFor: number) => {
//   let timeout = 0 as unknown as NodeJS.Timeout;
//   return (...args: Parameters<T>): ReturnType<T> => {
//     let result: unknown;
//     clearTimeout(timeout);
//     timeout = setTimeout(() => {
//       result = callback(...args);
//     }, waitFor);
//     return result;
//   };
// };

function timeout(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export { timeout };
