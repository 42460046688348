import {
  buildErrorChecker,
  ErrorCheckerMap,
} from "@/validators/generic.validators";

const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const passwordRegExp = /^(?=.*[a-z]*)(?=.*[A-Z])(?=.*[0-9])(?=.{8})/;

const emailTooLong = (email: string): boolean => {
  return email.length > 80;
};

const emailDoesntMatchRegex = (email: string): boolean => {
  return !emailRegExp.test(email);
};

const passwordTooShort = (password: string): boolean => {
  return password.length < 8;
};

const passwordTooLong = (password: string): boolean => {
  return password.length > 80;
};

const passwordDoesntMatchRegex = (password: string): boolean => {
  return !passwordRegExp.test(password);
};

enum EMAIL_VALIDATION_ERROR {
  TOO_LONG = "email-too-long",
  NO_REGEX_MATCH = "email-no-regex-match",
}

enum PASSWORD_VALIDATION_ERROR {
  TOO_SHORT = "password-too-short",
  TOO_LONG = "password-too-long",
  NO_REGEX_MATCH = "password-no-regex-match",
}

const emailValidatorMap: ErrorCheckerMap<string, EMAIL_VALIDATION_ERROR> = {
  [EMAIL_VALIDATION_ERROR.TOO_LONG]: emailTooLong,
  [EMAIL_VALIDATION_ERROR.NO_REGEX_MATCH]: emailDoesntMatchRegex,
};

const passwordValidatorMap: ErrorCheckerMap<string, PASSWORD_VALIDATION_ERROR> =
  {
    [PASSWORD_VALIDATION_ERROR.TOO_LONG]: passwordTooLong,
    [PASSWORD_VALIDATION_ERROR.TOO_SHORT]: passwordTooShort,
    [PASSWORD_VALIDATION_ERROR.NO_REGEX_MATCH]: passwordDoesntMatchRegex,
  };

const isPasswordValid = buildErrorChecker(passwordValidatorMap);
const isEmailValid = buildErrorChecker(emailValidatorMap);

export {
  EMAIL_VALIDATION_ERROR,
  PASSWORD_VALIDATION_ERROR,
  isEmailValid,
  isPasswordValid,
};
