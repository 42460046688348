<template>
  <v-app>
    <Toolbar v-if="toolbarVisible" />
    <Drawer v-if="drawerVisible" />
    <AutoLogout v-if="token" />
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-main>
    <Footer />
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";

import Toolbar from "@/components/core/CoreToolbar.vue";
import Drawer from "@/components/core/CoreDrawer.vue";
import Footer from "@/components/core/CoreFooter.vue";
import AutoLogout from "@/components/core/AutoLogout.vue";

export default Vue.extend({
  name: "App",
  components: {
    Toolbar,
    Drawer,
    Footer,
    AutoLogout,
  },

  computed: {
    toolbarVisible(): boolean {
      return this.$route.meta?.toolbarVisible;
    },
    drawerVisible(): boolean {
      return this.$route.meta?.sidebarVisible;
    },
    token(): string | null {
      return this.$auth.getSessionToken();
    },
  },
});
</script>
