<template>
  <v-container fluid grid-list-md>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <DeleteAccount
      v-bind:showDialog="showDeleteAccountDialog"
      @close="deleteAccountClose"
      @error="showError"
    ></DeleteAccount>

    <ChangePassword
      v-bind:showDialog="showChangePasswordDialog"
      @close="changePasswordClose"
      @error="showError"
    ></ChangePassword>

    <v-layout column align-center justify-center>
      <v-toolbar flat dark color="rgba(0, 0, 0, 0.0)">
        <v-toolbar-title>{{ $t("ui.me.title") }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <UserProfileHeader />

                <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                  <v-text-field
                    v-model="email"
                    :label="$t('ui.me.dialog.edit.email')"
                    placeholder="max@mustermann.de"
                    prepend-icon="email"
                    :rules="[required, emailValid]"
                    required
                    autocomplete="off new-email"
                    text
                    maxlength="80"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            @click="updateEmail"
            :disabled="!valid || !emailChanged()"
            :key="submitFormKey"
            >{{ $t("ui.action.update") }}</v-btn
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" :disabled="isDemoAccount">
                <v-icon @click="showChangePasswordDialog = true">security</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("ui.me.tooltip.password") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" :disabled="isDemoAccount">
                <v-icon @click="showDeleteAccountDialog = true">delete</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("ui.me.tooltip.delete") }}</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-layout>

    <v-snackbar
      v-model="error"
      :timeout="6000"
      :bottom="true"
      :multi-line="true"
      color="red darken-2"
      >{{ errortext }}</v-snackbar
    >
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";

import DeleteAccount from "@/components/DeleteAccount.vue";
import ChangePassword from "@/components/ChangePassword.vue";
import UserProfileHeader from "@/components/user/UserProfileHeader.vue";

import i18n from "@/plugins/i18n.plugin";

import {
  convertErrorCheckerOutputToStrings,
  ErrorStringMap,
} from "@/validators/generic.validators";
import { EMAIL_VALIDATION_ERROR, isEmailValid } from "@/validators/auth.validators";

const emailErrorToStringMap: ErrorStringMap<EMAIL_VALIDATION_ERROR> = {
  [EMAIL_VALIDATION_ERROR.TOO_LONG]: String(i18n.t("ui.rule.length80")),
  [EMAIL_VALIDATION_ERROR.NO_REGEX_MATCH]: String(i18n.t("ui.rule.emailInvalid")),
};

export default Vue.extend({
  components: {
    UserProfileHeader,
    DeleteAccount,
    ChangePassword,
  },

  data: () => ({
    // NEW
    loading: false,
    valid: false,
    showDeleteAccountDialog: false,
    showChangePasswordDialog: false,

    email: "",
    lastLogin: "",

    submitFormKey: 0,

    // OLD
    dialog: false,
    error: false,
    errortext: "",
    changed: false,
  }),

  computed: {
    phone(): string {
      return this.$user.getPhone() || "";
    },

    required() {
      return (value: string) => !!value || this.$t("ui.rule.required");
    },

    emailValid() {
      return (email: string) =>
        convertErrorCheckerOutputToStrings(isEmailValid(email), emailErrorToStringMap);
    },

    dateFormat(): string {
      return i18n.locale === "de" ? "dd.MM.yyyy HH:mm:ss" : "MM/dd/yyyy HH:mm:ss";
    },

    isDemoAccount(): boolean {
      return window.location.hostname === "demo.hoots.online";
    },
  },

  methods: {
    deleteAccountClose() {
      this.showDeleteAccountDialog = false;
    },

    changePasswordClose() {
      this.showChangePasswordDialog = false;
    },

    async updateEmail() {
      if (window.location.hostname !== "demo.hoots.online") {
        await this.$user.updateEmail(this.email);
      }
      this.setUser();
      this.forceUpdate();
    },

    emailChanged(): boolean {
      return this.email !== this.$user.getEmail();
    },

    setUser(): void {
      this.email = this.$user.getEmail() || "";
    },

    showError(errortext: string) {
      this.errortext = errortext;
      this.error = true;
    },

    forceUpdate() {
      this.submitFormKey++;
    },
  },
  async mounted() {
    this.loading = true;
    await this.$user.init();
    this.setUser();
    this.loading = false;
  },
});
</script>

<style></style>
