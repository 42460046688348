<template>
  <v-container fluid grid-list-md>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>

    <share-vehicle
      v-bind:showDialog="vehicleShareDialog"
      v-bind:title="shareTitle"
      v-bind:vehicleItem="sharedItem"
      v-bind:vehicleItemIndex="sharedItemIndex"
      @close="closeShareVehicle"
    />

    <edit-vehicle
      v-if="editVehicleOpen"
      :vehicle-id="editVehicleID"
      @error="showError"
      @close="closeEditVehicle"
    />

    <create-vehicle v-if="createVehicleOpen" @close="closeCreateVehicle" @error="showError" />

    <sensor-picker
      v-bind:showDialog="sensorPickDialog"
      v-bind:vehicle="pickedVehicle"
      @close="sensorPickDialog = false"
    ></sensor-picker>

    <v-toolbar flat dark color="rgba(0, 0, 0, 0.0)">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab small color="primary" v-on="on" @click="openCreateVehicle">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("ui.vehicle.tooltip.add") }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-container fluid>
      <v-row>
        <v-col v-for="vehicle in vehicles" :key="vehicle.id" sm="12" lg="6">
          <v-card flat color="rgba(255, 255, 255, 0.25)">
            <v-img :src="vehicle.image" aspect-ratio="1.7778" class="white--text align-start">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
              <v-sheet tile color="rgba(0, 0,0, 0.5)">
                <v-card-title>{{ vehicle.make }} {{ vehicle.model }}</v-card-title>
                <v-card-subtitle white--text v-if="vehicle.name !== ''"
                  >"{{ vehicle.name }}"</v-card-subtitle
                >
              </v-sheet>
            </v-img>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn @click="shareVehicle(vehicle)" v-on="on" color="white" text>
                    <v-badge
                      color="primary"
                      :value="vehicle.share_me_count"
                      :content="vehicle.share_me_count"
                    >
                      <v-icon>mdi-share-variant</v-icon>
                    </v-badge>
                  </v-btn>
                </template>
                <span>{{ $t("ui.vehicle.tooltip.share") }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn @click="openEditVehicle(vehicle)" v-on="on" color="white" text>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("ui.vehicle.tooltip.edit") }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :disabled="vehicleSensorSelectionDisabled(vehicle)"
                    @click="pickSensors(vehicle)"
                    v-on="on"
                    color="white"
                    text
                  >
                    <v-badge
                      color="primary"
                      :value="vehicle.sensor_map.length"
                      :content="vehicle.sensor_map.length"
                    >
                      <v-icon>mdi-tune</v-icon>
                    </v-badge>
                  </v-btn>
                </template>
                <span>{{ $t("ui.vehicle.tooltip.sensors") }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn @click="deleteVehicle(vehicle)" v-on="on" color="error" text>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("ui.vehicle.tooltip.delete") }}</span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar
      v-model="error"
      :timeout="6000"
      :bottom="true"
      :multi-line="true"
      color="red darken-2"
      >{{ errortext }}</v-snackbar
    >
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapState } from "vuex";

import parseISO from "date-fns/parseISO";

import SensorPicker from "@/components/SensorPicker";
import VehicleSharing from "@/components/VehicleSharing";
import { fromUnixTime } from "date-fns";

import EditVehicle from "@/components/dashboard/vehicle/dialogue/EditVehicle.vue";
import CreateVehicle from "@/components/dashboard/vehicle/dialogue/CreateVehicle.vue";

export default {
  components: {
    EditVehicle,
    CreateVehicle,
    "sensor-picker": SensorPicker,
    "share-vehicle": VehicleSharing,
  },
  data: () => ({
    // NEW
    loading: false,
    editVehicleID: null,

    editVehicleOpen: false,
    createVehicleOpen: false,

    // OLD
    sensorPickDialog: false,
    vehicleShareDialog: false,
    expand: true,

    error: false,
    errortext: "",

    sharedItemIndex: -1,
    sharedItem: {
      name: "",
      make: "",
      model: "",
      stroke: 4,
      cylinders: 4,
      combustion: "Benzin",
      construction_year: null,
      capacity: null,
      power: null,
      device_id: "",
      image: "",
    },

    pickedVehicle: {},
  }),
  computed: {
    ...mapState("vehicle", ["vehicles"]),

    formTitle() {
      return this.editedIndex === -1 ? this.$t("ui.vehicle.new") : this.$t("ui.vehicle.edit");
    },
    title() {
      return this.vehicles.length > 1
        ? this.$t("ui.vehicle.myvehicles") + " (" + this.vehicles.length + ")"
        : this.$t("ui.vehicle.myvehicle") + " (" + this.vehicles.length + ")";
    },

    shareTitle() {
      return this.sharedItemIndex === -1
        ? ""
        : this.$t("ui.vehicle.share") + ' "' + this.vehicles[this.sharedItemIndex].name + '"';
    },

    vehicleSensorSelectionDisabled() {
      // TODO: Make vehicle provider (typescript)
      return (vehicle) => vehicle.sensorids.length === 0;
    },
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    active(vehicle) {
      return parseISO(vehicle.deleted_at).getTime() === fromUnixTime(0).getTime();
    },

    async loadData() {
      this.loading = true;
      await Promise.all([
        this.$store.dispatch("device/fetchAllDevices"),
        this.$store.dispatch("vehicle/fetchAll"),
      ]);
      this.loading = false;
    },

    showError(err) {
      this.errortext = err;
      this.error = true;
    },

    openCreateVehicle() {
      this.createVehicleOpen = true;
    },

    closeCreateVehicle() {
      this.loadData();
      this.createVehicleOpen = false;
    },

    openEditVehicle(vehicle) {
      this.editVehicleID = vehicle.id;
      this.editVehicleOpen = true;
    },

    closeEditVehicle() {
      this.loadData();
      this.editVehicleOpen = false;
    },

    pickSensors(vehicle) {
      this.pickedVehicle = vehicle;
      this.sensorPickDialog = true;
    },

    shareVehicle(vehicle) {
      this.sharedItemIndex = this.vehicles.indexOf(vehicle);
      this.sharedItem = Object.assign({}, vehicle);
      this.vehicleShareDialog = true;
    },

    closeShareVehicle() {
      this.vehicleShareDialog = false;
    },

    async deleteVehicle(vehicle) {
      if (window.location.hostname === "demo.hoots.online") {
        this.showError("Demo-Version: Keine Löschung möglich!");
      } else {
        const confirmDelete = confirm(this.$t("ui.error.vehicle.confirmDelete"));
        if (confirmDelete) {
          const deleteRequest = await this.$store.dispatch("vehicle/delete", vehicle.id);
          if (!deleteRequest.wasSuccessful()) this.showError(deleteRequest.getMessage());
        }
      }
    },
  },
};
</script>
